(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.aY.ab === region.bc.ab)
	{
		return 'on line ' + region.aY.ab;
	}
	return 'on lines ' + region.aY.ab + ' through ' + region.bc.ab;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cy,
		impl.dm,
		impl.dj,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bv: func(record.bv),
		dh: record.dh,
		c5: record.c5
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bv;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dh;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.c5) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cy,
		impl.dm,
		impl.dj,
		function(sendToApp, initialModel) {
			var view = impl.dq;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cy,
		impl.dm,
		impl.dj,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.aW && impl.aW(sendToApp)
			var view = impl.dq;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.aD);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.b$) && (_VirtualDom_doc.title = title = doc.b$);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.c_;
	var onUrlRequest = impl.c$;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		aW: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bE === next.bE
							&& curr.bm === next.bm
							&& curr.bA.a === next.bA.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		cy: function(flags)
		{
			return A3(impl.cy, flags, _Browser_getUrl(), key);
		},
		dq: impl.dq,
		dm: impl.dm,
		dj: impl.dj
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cu: 'hidden', ch: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cu: 'mozHidden', ch: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cu: 'msHidden', ch: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cu: 'webkitHidden', ch: 'webkitvisibilitychange' }
		: { cu: 'hidden', ch: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bO: _Browser_getScene(),
		dr: {
			b8: _Browser_window.pageXOffset,
			dv: _Browser_window.pageYOffset,
			dt: _Browser_doc.documentElement.clientWidth,
			ct: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		dt: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ct: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bO: {
				dt: node.scrollWidth,
				ct: node.scrollHeight
			},
			dr: {
				b8: node.scrollLeft,
				dv: node.scrollTop,
				dt: node.clientWidth,
				ct: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bO: _Browser_getScene(),
			dr: {
				b8: x,
				dv: y,
				dt: _Browser_doc.documentElement.clientWidth,
				ct: _Browser_doc.documentElement.clientHeight
			},
			co: {
				b8: x + rect.left,
				dv: y + rect.top,
				dt: rect.width,
				ct: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.cU) { flags += 'm'; }
	if (options.cg) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Msg$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msg$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{j: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bh: fragment, bm: host, aT: path, bA: port_, bE: protocol, bF: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Msg$AdjustTimeZone = function (a) {
	return {$: 32, a: a};
};
var $author$project$Msg$GotViewport = function (a) {
	return {$: 24, a: a};
};
var $author$project$Type$Session$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Page$LoadingPage = {$: 9};
var $author$project$Route$NotFound = {$: 10};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Type$Flags$initSeedStorage = F3(
	function (num, appId, storage) {
		return {al: appId, aq: storage, bQ: num};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Type$Flags$LocalStorage = function (email) {
	return {cp: email};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Type$Flags$localStorageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'email',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Type$Flags$LocalStorage));
var $author$project$Type$Flags$decodeMaybeStorage = function (maybeStorage) {
	if (!maybeStorage.$) {
		var storage = maybeStorage.a;
		var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$Type$Flags$localStorageDecoder, storage);
		if (!_v1.$) {
			var result = _v1.a;
			return $elm$json$Json$Decode$succeed(
				$elm$core$Maybe$Just(result));
		} else {
			return $elm$json$Json$Decode$fail('It failed');
		}
	} else {
		return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
	}
};
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Type$Flags$convertFlagsToSeedStorage = function (flags) {
	return A4(
		$elm$json$Json$Decode$map3,
		$author$project$Type$Flags$initSeedStorage,
		$elm$json$Json$Decode$succeed(flags.bQ),
		$elm$json$Json$Decode$succeed(flags.al),
		$author$project$Type$Flags$decodeMaybeStorage(flags.bW));
};
var $author$project$Type$Flags$Flags = F3(
	function (seed, storage, appId) {
		return {al: appId, bQ: seed, bW: storage};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Type$Flags$flagsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'appId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'storage',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'seed',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Type$Flags$Flags))));
var $author$project$Type$Flags$seedStoreDecoder = A2($elm$json$Json$Decode$andThen, $author$project$Type$Flags$convertFlagsToSeedStorage, $author$project$Type$Flags$flagsDecoder);
var $author$project$Type$Flags$decodeSeedStore = function (data) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Flags$seedStoreDecoder, data);
	if (!_v0.$) {
		var result = _v0.a;
		return result;
	} else {
		return A3($author$project$Type$Flags$initSeedStorage, 0, '', $elm$core$Maybe$Nothing);
	}
};
var $author$project$Type$Screen$Small = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Type$Screen$defaultSize = A2(
	$author$project$Type$Screen$Small,
	_Utils_Tuple2(320, 568),
	0);
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$Route$Announcement = {$: 4};
var $author$project$Msg$AnnouncementMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Type$Page$AnnouncementPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$SetEdit$Details = 0;
var $author$project$Type$Page$Error404Page = {$: 10};
var $author$project$Route$GetStarted = {$: 1};
var $author$project$Msg$GetStartedMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$Page$GetStartedPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Session$Guest = function (a) {
	return {$: 1, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Msg$HomeMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Page$HomePage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Route$ItemEdit = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Msg$ItemEditMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Type$Page$ItemEditPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$SetEdit$Items = 1;
var $author$project$Route$PackEdit = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Msg$PackEditMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Type$Page$PackEditPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$SetEdit$Packs = 2;
var $author$project$Route$Profile = {$: 3};
var $author$project$Msg$ProfileMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Type$Page$ProfilePage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$SetEdit = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Msg$SetEditMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Type$Page$SetEditPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Msg$SetImportMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Type$Page$SetImportPage = function (a) {
	return {$: 8, a: a};
};
var $author$project$Route$SetList = {$: 5};
var $author$project$Msg$SetListMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Type$Page$SetListPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Type$CSet$emptyCSet = {aC: 0, aF: 0, aI: 0, cl: '', aK: 0, bn: '', bo: '', cE: 0, cI: 0, ap: 0, aZ: 0, ag: _List_Nil, b$: 'Untitled', dp: _List_Nil};
var $author$project$Type$Item$Content = F2(
	function (maybeBackImageUrl, cornerRadius) {
		return {G: cornerRadius, aO: maybeBackImageUrl};
	});
var $author$project$Type$Item$emptyItem = {
	aC: 0,
	N: A2($author$project$Type$Item$Content, $elm$core$Maybe$Nothing, 0),
	aF: 0,
	aI: 0,
	aK: 0,
	bn: '',
	cG: 0,
	ap: 0,
	I: 1,
	dd: '',
	aZ: 0,
	ag: _List_Nil,
	ah: 1,
	b$: 'Untitled',
	b5: _List_Nil
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Type$Session$params = function (session) {
	switch (session.$) {
		case 3:
			var parameters = session.a;
			return parameters;
		case 2:
			var parameters = session.a;
			return parameters;
		case 1:
			var parameters = session.a;
			return parameters;
		default:
			var parameters = session.a;
			return parameters;
	}
};
var $author$project$Type$Session$screenSize = function (session) {
	return $author$project$Type$Session$params(session).bP;
};
var $author$project$Page$Announcement$init = F2(
	function (session, announcements) {
		return _Utils_Tuple2(
			{
				cd: announcements,
				z: $elm$core$Maybe$Nothing,
				A: $elm$core$Maybe$Nothing,
				c4: false,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				b$: 'Announcements'
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$GetStarted$EnterEmail = 0;
var $author$project$Settings$appName = 'tĭbles CMS';
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {cx: index, cN: match, cZ: number, di: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{cg: true, cU: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $author$project$Type$Session$maybeLocalStorage = function (session) {
	switch (session.$) {
		case 3:
			var parameters = session.a;
			return parameters.aq;
		case 2:
			var parameters = session.a;
			return parameters.aq;
		case 1:
			var parameters = session.a;
			return parameters.aq;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Session$localStorageEmail = function (session) {
	var _v0 = $author$project$Type$Session$maybeLocalStorage(session);
	if (!_v0.$) {
		var localStorage = _v0.a;
		return localStorage.cp;
	} else {
		return '';
	}
};
var $elm$core$Basics$not = _Basics_not;
var $author$project$Page$GetStarted$init = function (session) {
	var title = 'Get Started | ' + $author$project$Settings$appName;
	var screenSize = $author$project$Type$Session$screenSize(session);
	var email = $author$project$Type$Session$localStorageEmail(session);
	return _Utils_Tuple2(
		{
			cb: _List_Nil,
			cp: email,
			aM: $elm$core$Maybe$Nothing,
			C: '',
			bP: screenSize,
			bR: !$rtfeldman$elm_validate$Validate$isValidEmail(email),
			db: session,
			av: 0,
			b$: title
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Type$Session$appId = function (session) {
	return $author$project$Type$Session$params(session).al;
};
var $author$project$Page$Home$init = function (session) {
	return _Utils_Tuple2(
		{
			al: $author$project$Type$Session$appId(session),
			aM: $elm$core$Maybe$Nothing,
			c4: false,
			bP: $author$project$Type$Session$screenSize(session),
			db: session,
			b$: 'Home'
		},
		$elm$core$Platform$Cmd$none);
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Page$ItemEdit$init = F4(
	function (session, set, variantDict, item) {
		return _Utils_Tuple2(
			{
				G: $elm$core$String$fromFloat(item.N.G),
				aH: '',
				aM: $elm$core$Maybe$Nothing,
				bn: item.bn,
				ao: item,
				v: $elm$core$Maybe$Nothing,
				c4: false,
				I: $elm$core$String$fromFloat(item.I),
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				bT: set,
				dd: set.bn,
				Y: '',
				b$: item.b$,
				$7: variantDict
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PackEdit$BasicInfo = 0;
var $author$project$Type$Pack$emptyPack = {aE: '', a8: 1, ck: 0, cl: '', bn: '', cH: _List_Nil, by: '', de: _List_Nil, aZ: 0, b$: 'Untitled'};
var $author$project$Page$PackEdit$init = F4(
	function (session, maybeSet, maybePack, _v0) {
		var setId = _v0.a;
		var prodId = _v0.b;
		var newSet = A2(
			$elm$core$Maybe$withDefault,
			_Utils_update(
				$author$project$Type$CSet$emptyCSet,
				{bn: setId}),
			maybeSet);
		var appId = $author$project$Type$Session$appId(session);
		return _Utils_Tuple2(
			{
				aH: '',
				bn: prodId,
				v: $elm$core$Maybe$Nothing,
				ae: $author$project$Type$Pack$emptyPack,
				c4: false,
				w: 0,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				bT: newSet,
				dd: setId,
				Y: ''
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Profile$init = function (session) {
	var maybeProfile = function () {
		if (session.$ === 3) {
			var user = session.b;
			return user.cP;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return _Utils_Tuple2(
		{
			cP: maybeProfile,
			c4: false,
			bP: $author$project$Type$Session$screenSize(session),
			db: session,
			b$: 'Profile'
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$SetEdit$GotTimeNow = function (a) {
	return {$: 20, a: a};
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Type$Session$timeZone = function (session) {
	return $author$project$Type$Session$params(session).bZ;
};
var $author$project$Page$SetEdit$init = F3(
	function (session, set, items) {
		return _Utils_Tuple2(
			{
				aH: '',
				aM: $elm$core$Maybe$Nothing,
				bn: set.bn,
				P: items,
				z: $elm$core$Maybe$Nothing,
				v: $elm$core$Maybe$Nothing,
				cY: 0,
				at: _List_Nil,
				c4: false,
				w: 0,
				db: session,
				bT: set,
				Y: '',
				bZ: $author$project$Type$Session$timeZone(session),
				b$: set.b$,
				ay: false,
				az: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Page$SetEdit$GotTimeNow, $elm$time$Time$now)
					])));
	});
var $author$project$Page$SetImport$Ready = {$: 0};
var $author$project$Page$SetImport$init = function (session) {
	return _Utils_Tuple2(
		{
			aH: '',
			c4: false,
			J: _List_Nil,
			bP: $author$project$Type$Session$screenSize(session),
			db: session,
			av: $author$project$Page$SetImport$Ready,
			bZ: $author$project$Type$Session$timeZone(session),
			b$: 'Import Set'
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$SetList$init = F3(
	function (session, variantDict, setDict) {
		return _Utils_Tuple2(
			{
				aH: '',
				c4: false,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				dc: setDict,
				bZ: $author$project$Type$Session$timeZone(session),
				b$: 'Sets',
				$7: variantDict
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Type$Session$navKey = function (session) {
	return $author$project$Type$Session$params(session).cW;
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$routeToString = function (page) {
	switch (page.$) {
		case 0:
			return '/';
		case 1:
			return '/get-started';
		case 2:
			return '/signout';
		case 3:
			return '/profile';
		case 4:
			return '/announcements';
		case 5:
			return '/sets';
		case 9:
			return '/set/import';
		case 6:
			var setId = page.a;
			var maybeFrag = page.b;
			if (!maybeFrag.$) {
				var frag = maybeFrag.a;
				return '/set/' + (setId + ('#' + frag));
			} else {
				return '/set/' + setId;
			}
		case 7:
			var setId = page.a;
			var itemId = page.b;
			return '/set/' + (setId + ('/item/' + itemId));
		case 8:
			var setId = page.a;
			var packId = page.b;
			return '/set/' + (setId + ('/pack/' + packId));
		default:
			return '/404';
	}
};
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Firebase$signOut = _Platform_outgoingPort('signOut', $elm$json$Json$Encode$string);
var $author$project$Firebase$signout = $elm$core$Platform$Cmd$batch(
	_List_fromArray(
		[
			$author$project$Firebase$signOut('')
		]));
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Type$Session$Partial = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$Session$SignedIn = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Type$Session$updateParams = F2(
	function (session, parameters) {
		switch (session.$) {
			case 3:
				var user = session.b;
				return A2($author$project$Type$Session$SignedIn, parameters, user);
			case 2:
				var user = session.b;
				return A2($author$project$Type$Session$Partial, parameters, user);
			case 1:
				return $author$project$Type$Session$Guest(parameters);
			default:
				return $author$project$Type$Session$Loading(parameters);
		}
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Update$pageForRoute = F2(
	function (model, maybeRoute) {
		var params = $author$project$Type$Session$params(model.db);
		var updateGenericPage = F4(
			function (init, route, page, msg) {
				var newParams = _Utils_update(
					params,
					{c9: route});
				var newSession = A2($author$project$Type$Session$updateParams, model.db, newParams);
				var _v20 = init(newSession);
				var subModel = _v20.a;
				var subCmd = _v20.b;
				var newModel = _Utils_update(
					model,
					{
						c3: page(subModel),
						db: newSession
					});
				return _Utils_Tuple2(
					newModel,
					A2($elm$core$Platform$Cmd$map, msg, subCmd));
			});
		var updateSession = function (route) {
			return A2(
				$author$project$Type$Session$updateParams,
				model.db,
				_Utils_update(
					params,
					{c9: route}));
		};
		_v0$10:
		while (true) {
			if (!maybeRoute.$) {
				switch (maybeRoute.a.$) {
					case 0:
						var _v1 = maybeRoute.a;
						return A4(updateGenericPage, $author$project$Page$Home$init, $author$project$Route$Home, $author$project$Type$Page$HomePage, $author$project$Msg$HomeMsg);
					case 3:
						var _v2 = maybeRoute.a;
						return A4(updateGenericPage, $author$project$Page$Profile$init, $author$project$Route$Profile, $author$project$Type$Page$ProfilePage, $author$project$Msg$ProfileMsg);
					case 4:
						var _v3 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$Announcement);
						var _v4 = A2($author$project$Page$Announcement$init, newSession, model.cd);
						var subModel = _v4.a;
						var subCmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$AnnouncementPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$AnnouncementMsg, subCmd));
					case 9:
						var _v5 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$SetList);
						var _v6 = $author$project$Page$SetImport$init(newSession);
						var subModel = _v6.a;
						var subCmd = _v6.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$SetImportPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetImportMsg, subCmd));
					case 5:
						var _v7 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$SetList);
						var _v8 = A3($author$project$Page$SetList$init, newSession, model.$7, model.dc);
						var subModel = _v8.a;
						var subCmd = _v8.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$SetListPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetListMsg, subCmd));
					case 6:
						var _v9 = maybeRoute.a;
						var id = _v9.a;
						var maybeFrag = _v9.b;
						var set = A2(
							$elm$core$Maybe$withDefault,
							_Utils_update(
								$author$project$Type$CSet$emptyCSet,
								{bn: id}),
							A2($elm$core$Dict$get, id, model.dc));
						var newSession = updateSession(
							A2($author$project$Route$SetEdit, id, maybeFrag));
						var items = A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.cG;
							},
							A2(
								$elm$core$List$filter,
								function (it) {
									return _Utils_eq(it.dd, id);
								},
								$elm$core$Dict$values(model.cF)));
						var _v10 = A3($author$project$Page$SetEdit$init, newSession, set, items);
						var subModel = _v10.a;
						var subCmd = _v10.b;
						var tabSubModel = function () {
							_v11$2:
							while (true) {
								if (!maybeFrag.$) {
									switch (maybeFrag.a) {
										case 'items':
											return _Utils_update(
												subModel,
												{w: 1});
										case 'packs':
											return _Utils_update(
												subModel,
												{w: 2});
										default:
											break _v11$2;
									}
								} else {
									break _v11$2;
								}
							}
							return _Utils_update(
								subModel,
								{w: 0});
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$SetEditPage(tabSubModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetEditMsg, subCmd));
					case 7:
						var _v12 = maybeRoute.a;
						var setId = _v12.a;
						var itemId = _v12.b;
						var set = A2(
							$elm$core$Maybe$withDefault,
							_Utils_update(
								$author$project$Type$CSet$emptyCSet,
								{bn: setId}),
							A2($elm$core$Dict$get, setId, model.dc));
						var newSession = updateSession(
							A2($author$project$Route$ItemEdit, setId, itemId));
						var item = A2(
							$elm$core$Maybe$withDefault,
							_Utils_update(
								$author$project$Type$Item$emptyItem,
								{bn: itemId, dd: setId}),
							A2($elm$core$Dict$get, itemId, model.cF));
						var _v13 = A4($author$project$Page$ItemEdit$init, newSession, set, model.$7, item);
						var subModel = _v13.a;
						var subCmd = _v13.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$ItemEditPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$ItemEditMsg, subCmd));
					case 8:
						var _v14 = maybeRoute.a;
						var setId = _v14.a;
						var packId = _v14.b;
						var newSession = updateSession(
							A2($author$project$Route$PackEdit, setId, packId));
						var maybeSet = A2($elm$core$Dict$get, setId, model.dc);
						var maybePack = A2($elm$core$Dict$get, packId, model.c2);
						var _v15 = A4(
							$author$project$Page$PackEdit$init,
							newSession,
							maybeSet,
							maybePack,
							_Utils_Tuple2(setId, packId));
						var subModel = _v15.a;
						var subCmd = _v15.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$PackEditPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$PackEditMsg, subCmd));
					case 1:
						var _v16 = maybeRoute.a;
						var newSession = updateSession($author$project$Route$GetStarted);
						var _v17 = $author$project$Page$GetStarted$init(newSession);
						var subModel = _v17.a;
						var subCmd = _v17.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c3: $author$project$Type$Page$GetStartedPage(subModel)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Msg$GetStartedMsg, subCmd));
					case 2:
						var _v18 = maybeRoute.a;
						var newSession = $author$project$Type$Session$Guest(
							$author$project$Type$Session$params(model.db));
						var emptyModel = _Utils_update(
							model,
							{cb: _List_Nil, aq: $elm$core$Maybe$Nothing, db: newSession});
						var _v19 = $author$project$Page$Home$init(newSession);
						var subModel = _v19.a;
						return _Utils_Tuple2(
							_Utils_update(
								emptyModel,
								{
									c3: $author$project$Type$Page$HomePage(subModel)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Firebase$signout,
										A2(
										$author$project$Route$replaceUrl,
										$author$project$Type$Session$navKey(newSession),
										$author$project$Route$Home)
									])));
					default:
						break _v0$10;
				}
			} else {
				break _v0$10;
			}
		}
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c3: $author$project$Type$Page$Error404Page}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$initPageForRoute = F2(
	function (model, maybeRoute) {
		return A2($author$project$Update$pageForRoute, model, maybeRoute);
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {O: frag, R: params, L: unvisited, D: value, T: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.L;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.D);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.D);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.aT),
					$elm$url$Url$Parser$prepareQuery(url.bF),
					url.bh,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$SetImport = {$: 9};
var $author$project$Route$SignOut = {$: 2};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$fragment = function (toFrag) {
	return function (_v0) {
		var visited = _v0.T;
		var unvisited = _v0.L;
		var params = _v0.R;
		var frag = _v0.O;
		var value = _v0.D;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					toFrag(frag)))
			]);
	};
};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.T;
		var unvisited = _v0.L;
		var params = _v0.R;
		var frag = _v0.O;
		var value = _v0.D;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.T;
			var unvisited = _v1.L;
			var params = _v1.R;
			var frag = _v1.O;
			var value = _v1.D;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.T;
		var unvisited = _v0.L;
		var params = _v0.R;
		var frag = _v0.O;
		var value = _v0.D;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.T;
			var unvisited = _v0.L;
			var params = _v0.R;
			var frag = _v0.O;
			var value = _v0.D;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Home,
			$elm$url$Url$Parser$s('home')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$GetStarted,
			$elm$url$Url$Parser$s('get-started')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Announcement,
			$elm$url$Url$Parser$s('announcements')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SetList,
			$elm$url$Url$Parser$s('sets')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SetImport,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('set'),
				$elm$url$Url$Parser$s('import'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ItemEdit,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('set'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('item'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PackEdit,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('set'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('pack'),
						$elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SetEdit,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('set'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$fragment($elm$core$Basics$identity)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$SignOut,
			$elm$url$Url$Parser$s('signout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Profile,
			$elm$url$Url$Parser$s('profile'))
		]));
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Init$init = F3(
	function (flags, url, navKey) {
		var seedStore = $author$project$Type$Flags$decodeSeedStore(flags);
		var params = {
			al: seedStore.al,
			a9: $elm$random$Random$initialSeed(seedStore.bQ),
			aq: seedStore.aq,
			cW: navKey,
			c9: $author$project$Route$NotFound,
			bP: $author$project$Type$Screen$defaultSize,
			bZ: $elm$time$Time$utc,
			b3: url
		};
		var session = $author$project$Type$Session$Loading(params);
		var maybeRoute = A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
		var model = {
			cb: _List_Nil,
			cd: _List_Nil,
			al: seedStore.al,
			a9: $elm$random$Random$initialSeed(seedStore.bQ),
			cF: $elm$core$Dict$empty,
			cO: $elm$core$Maybe$Nothing,
			aq: seedStore.aq,
			cP: $elm$core$Maybe$Nothing,
			cQ: maybeRoute,
			cR: $elm$core$Maybe$Nothing,
			cY: 0,
			c2: $elm$core$Dict$empty,
			c3: $author$project$Type$Page$LoadingPage,
			c4: false,
			bP: $author$project$Type$Screen$defaultSize,
			db: session,
			dc: $elm$core$Dict$empty,
			bZ: $elm$time$Time$utc,
			b3: url,
			$7: $elm$core$Dict$empty
		};
		var _v0 = A2($author$project$Update$initPageForRoute, model, maybeRoute);
		var newModel = _v0.a;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Msg$AdjustTimeZone, $elm$time$Time$here),
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport)
					])));
	});
var $author$project$Msg$AccountChanged = function (a) {
	return {$: 25, a: a};
};
var $author$project$Msg$AuthStateChanged = function (a) {
	return {$: 13, a: a};
};
var $author$project$Msg$DrawersChanged = function (a) {
	return {$: 17, a: a};
};
var $author$project$Msg$GotAlert = function (a) {
	return {$: 14, a: a};
};
var $author$project$Msg$GotAnnouncement = function (a) {
	return {$: 30, a: a};
};
var $author$project$Msg$GotChatMessage = function (a) {
	return {$: 26, a: a};
};
var $author$project$Msg$GotCreatedPack = function (a) {
	return {$: 39, a: a};
};
var $author$project$Msg$GotImage = function (a) {
	return {$: 34, a: a};
};
var $author$project$Msg$GotItem = function (a) {
	return {$: 36, a: a};
};
var $author$project$Msg$GotPack = function (a) {
	return {$: 19, a: a};
};
var $author$project$Msg$GotRoom = function (a) {
	return {$: 21, a: a};
};
var $author$project$Msg$GotSetRemoved = function (a) {
	return {$: 41, a: a};
};
var $author$project$Msg$GotSummary = function (a) {
	return {$: 27, a: a};
};
var $author$project$Msg$GotVariant = function (a) {
	return {$: 38, a: a};
};
var $author$project$Msg$LocalCacheChanged = function (a) {
	return {$: 28, a: a};
};
var $author$project$Msg$NewPrints = function (a) {
	return {$: 29, a: a};
};
var $author$project$Msg$ProfileChanged = function (a) {
	return {$: 15, a: a};
};
var $author$project$Msg$RemoveAnnouncement = function (a) {
	return {$: 31, a: a};
};
var $author$project$Msg$RemoveDrawer = function (a) {
	return {$: 20, a: a};
};
var $author$project$Msg$RemoveItem = function (a) {
	return {$: 37, a: a};
};
var $author$project$Msg$SetChanged = function (a) {
	return {$: 18, a: a};
};
var $author$project$Msg$Tick = function (a) {
	return {$: 42, a: a};
};
var $author$project$Msg$WallChanged = function (a) {
	return {$: 16, a: a};
};
var $author$project$Msg$WindowResized = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Msg$WindowScrolled = function (a) {
	return {$: 23, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {bD: processes, bY: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.bD;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.bY);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Firebase$onAccountChanged = _Platform_incomingPort('onAccountChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onAnnouncementRemoved = _Platform_incomingPort('onAnnouncementRemoved', $elm$json$Json$Decode$string);
var $author$project$Firebase$onAnnouncementUpdated = _Platform_incomingPort('onAnnouncementUpdated', $elm$json$Json$Decode$value);
var $author$project$Firebase$onAuthStateChanged = _Platform_incomingPort('onAuthStateChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onDrawersChanged = _Platform_incomingPort('onDrawersChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotAlert = _Platform_incomingPort('onGotAlert', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotChatMessage = _Platform_incomingPort('onGotChatMessage', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotImage = _Platform_incomingPort('onGotImage', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotPack = _Platform_incomingPort('onGotPack', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotRoom = _Platform_incomingPort('onGotRoom', $elm$json$Json$Decode$value);
var $author$project$Firebase$onGotSummary = _Platform_incomingPort('onGotSummary', $elm$json$Json$Decode$value);
var $author$project$Firebase$onItemRemoved = _Platform_incomingPort('onItemRemoved', $elm$json$Json$Decode$string);
var $author$project$Firebase$onItemUpdated = _Platform_incomingPort('onItemUpdated', $elm$json$Json$Decode$value);
var $author$project$LocalService$onLocalCacheChanged = _Platform_incomingPort('onLocalCacheChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onNewPrints = _Platform_incomingPort('onNewPrints', $elm$json$Json$Decode$value);
var $author$project$Firebase$onPackCreated = _Platform_incomingPort('onPackCreated', $elm$json$Json$Decode$string);
var $author$project$Firebase$onProfileChanged = _Platform_incomingPort('onProfileChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onRemoveDrawer = _Platform_incomingPort('onRemoveDrawer', $elm$json$Json$Decode$value);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {bz: pids, bX: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {bd: event, bs: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.bz,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.bs;
		var event = _v0.bd;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.bX);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$LocalService$onScroll = _Platform_incomingPort('onScroll', $elm$json$Json$Decode$int);
var $author$project$Firebase$onSetChanged = _Platform_incomingPort('onSetChanged', $elm$json$Json$Decode$value);
var $author$project$Firebase$onSetRemoved = _Platform_incomingPort('onSetRemoved', $elm$json$Json$Decode$string);
var $author$project$Firebase$onVariantUpdated = _Platform_incomingPort('onVariantUpdated', $elm$json$Json$Decode$value);
var $author$project$Firebase$onWallChanged = _Platform_incomingPort('onWallChanged', $elm$json$Json$Decode$value);
var $author$project$Page$Announcement$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$GetStarted$GotSignInMethods = function (a) {
	return {$: 7, a: a};
};
var $author$project$Firebase$gotSignInMethods = _Platform_incomingPort('gotSignInMethods', $elm$json$Json$Decode$value);
var $author$project$Page$GetStarted$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Firebase$gotSignInMethods($author$project$Page$GetStarted$GotSignInMethods)
			]));
};
var $author$project$Page$Home$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$ItemEdit$ImageLibraryMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ItemEdit$VariantEditMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Modal$ImageLibrary$GotFileUploadProgress = function (a) {
	return {$: 8, a: a};
};
var $author$project$Firebase$onFileUpload = _Platform_incomingPort('onFileUpload', $elm$json$Json$Decode$value);
var $author$project$Modal$ImageLibrary$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Firebase$onFileUpload($author$project$Modal$ImageLibrary$GotFileUploadProgress)
			]));
};
var $author$project$Modal$VariantEdit$ImageLibraryMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Modal$VariantEdit$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.v;
				if (!_v0.$) {
					var _v1 = _v0.a;
					var subModel = _v1.b;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Modal$VariantEdit$ImageLibraryMsg,
						$author$project$Modal$ImageLibrary$subscriptions(subModel));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Page$ItemEdit$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.v;
				if (!_v0.$) {
					if (!_v0.a.$) {
						var _v1 = _v0.a;
						var subModel = _v1.b;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Page$ItemEdit$ImageLibraryMsg,
							$author$project$Modal$ImageLibrary$subscriptions(subModel));
					} else {
						var subModel = _v0.a.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Page$ItemEdit$VariantEditMsg,
							$author$project$Modal$VariantEdit$subscriptions(subModel));
					}
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Page$PackEdit$ImageLibraryMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PackEdit$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.v;
				if ((!_v0.$) && (!_v0.a.$)) {
					var subModel = _v0.a.a;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Page$PackEdit$ImageLibraryMsg,
						$author$project$Modal$ImageLibrary$subscriptions(subModel));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Page$SetEdit$ImageLibraryMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$SetEdit$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.v;
				if ((!_v0.$) && (!_v0.a.$)) {
					var _v1 = _v0.a;
					var imageKind = _v1.a;
					var subModel = _v1.b;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Page$SetEdit$ImageLibraryMsg,
						$author$project$Modal$ImageLibrary$subscriptions(subModel));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Firebase$onAuthStateChanged($author$project$Msg$AuthStateChanged),
				$author$project$Firebase$onGotAlert($author$project$Msg$GotAlert),
				$author$project$Firebase$onProfileChanged($author$project$Msg$ProfileChanged),
				$author$project$Firebase$onWallChanged($author$project$Msg$WallChanged),
				$author$project$Firebase$onDrawersChanged($author$project$Msg$DrawersChanged),
				$author$project$Firebase$onSetChanged($author$project$Msg$SetChanged),
				$author$project$Firebase$onRemoveDrawer($author$project$Msg$RemoveDrawer),
				$author$project$Firebase$onGotRoom($author$project$Msg$GotRoom),
				$author$project$Firebase$onAccountChanged($author$project$Msg$AccountChanged),
				$author$project$Firebase$onGotPack($author$project$Msg$GotPack),
				$author$project$Firebase$onGotChatMessage($author$project$Msg$GotChatMessage),
				$author$project$Firebase$onGotSummary($author$project$Msg$GotSummary),
				$author$project$Firebase$onNewPrints($author$project$Msg$NewPrints),
				$author$project$Firebase$onAnnouncementUpdated($author$project$Msg$GotAnnouncement),
				$author$project$Firebase$onAnnouncementRemoved($author$project$Msg$RemoveAnnouncement),
				$author$project$Firebase$onGotImage($author$project$Msg$GotImage),
				$author$project$Firebase$onItemUpdated($author$project$Msg$GotItem),
				$author$project$Firebase$onItemRemoved($author$project$Msg$RemoveItem),
				$author$project$Firebase$onVariantUpdated($author$project$Msg$GotVariant),
				$author$project$Firebase$onPackCreated($author$project$Msg$GotCreatedPack),
				$author$project$Firebase$onSetRemoved($author$project$Msg$GotSetRemoved),
				$author$project$LocalService$onScroll($author$project$Msg$WindowScrolled),
				$author$project$LocalService$onLocalCacheChanged($author$project$Msg$LocalCacheChanged),
				$elm$browser$Browser$Events$onResize($author$project$Msg$WindowResized),
				A2($elm$time$Time$every, 1000, $author$project$Msg$Tick),
				function () {
				var _v0 = model.c3;
				switch (_v0.$) {
					case 1:
						var getstarted = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$GetStartedMsg,
							$author$project$Page$GetStarted$subscriptions(getstarted));
					case 0:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$HomeMsg,
							$author$project$Page$Home$subscriptions(subModel));
					case 3:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$AnnouncementMsg,
							$author$project$Page$Announcement$subscriptions(subModel));
					case 5:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$SetEditMsg,
							$author$project$Page$SetEdit$subscriptions(subModel));
					case 6:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$ItemEditMsg,
							$author$project$Page$ItemEdit$subscriptions(subModel));
					case 7:
						var subModel = _v0.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Msg$PackEditMsg,
							$author$project$Page$PackEdit$subscriptions(subModel));
					default:
						return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Ox$Theme$alertTimeOut = 3000;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Type$User$Account = F5(
	function (badgeCount, credits, dateCreated, language, locale) {
		return {aB: badgeCount, cj: credits, ck: dateCreated, cL: language, cM: locale};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Type$User$accountDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'locale',
	$elm$json$Json$Decode$string,
	'en_US',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'language',
		$elm$json$Json$Decode$string,
		'en-US',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'dateCreated',
			$elm$json$Json$Decode$int,
			0,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'credits',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'badgeCount',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$Type$User$Account))))));
var $author$project$Type$Session$updateAccount = F2(
	function (session, account) {
		if (session.$ === 3) {
			var par = session.a;
			var user = session.b;
			return A2(
				$author$project$Type$Session$SignedIn,
				par,
				_Utils_update(
					user,
					{
						cO: $elm$core$Maybe$Just(account)
					}));
		} else {
			return session;
		}
	});
var $author$project$Update$updateSessionInPage = F3(
	function (model, page, session) {
		var updateSessionIn = F2(
			function (newPage, newSubModel) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c3: newPage(newSubModel)
						}),
					$elm$core$Platform$Cmd$none);
			});
		switch (page.$) {
			case 3:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$AnnouncementPage,
					_Utils_update(
						subModel,
						{db: session}));
			case 1:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$GetStartedPage,
					_Utils_update(
						subModel,
						{db: session}));
			case 0:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$HomePage,
					_Utils_update(
						subModel,
						{db: session}));
			case 6:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$ItemEditPage,
					_Utils_update(
						subModel,
						{db: session}));
			case 7:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$PackEditPage,
					_Utils_update(
						subModel,
						{db: session}));
			case 2:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$ProfilePage,
					_Utils_update(
						subModel,
						{db: session}));
			case 5:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$SetEditPage,
					_Utils_update(
						subModel,
						{db: session}));
			case 4:
				var subModel = page.a;
				return A2(
					updateSessionIn,
					$author$project$Type$Page$SetListPage,
					_Utils_update(
						subModel,
						{db: session}));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateAccountChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$accountDecoder, data);
		if (!_v0.$) {
			var account = _v0.a;
			var newSession = A2($author$project$Type$Session$updateAccount, model.db, account);
			var newModel = _Utils_update(
				model,
				{
					cO: $elm$core$Maybe$Just(account),
					db: newSession
				});
			var updateAccountInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			return A3($author$project$Update$updateSessionInPage, newModel, model.c3, newSession);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateAdjustTimeZone = F2(
	function (model, timeZone) {
		var params = $author$project$Type$Session$params(model.db);
		var newParams = _Utils_update(
			params,
			{bZ: timeZone});
		var newSession = A2($author$project$Type$Session$updateParams, model.db, newParams);
		var newModel = _Utils_update(
			model,
			{db: newSession, bZ: timeZone});
		return A3($author$project$Update$updateSessionInPage, newModel, model.c3, newSession);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $author$project$Update$updateAlertView = F2(
	function (model, subMsg) {
		var index = subMsg;
		var newAlerts = A2($elm_community$list_extra$List$Extra$removeAt, index, model.cb);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cb: newAlerts}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Announcement$ModalMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Modal$Announcement$GotTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Modal$Announcement$NoOp = {$: 10};
var $author$project$Modal$Announcement$None = 0;
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Type$Color$Colors = F3(
	function (background, text, tint) {
		return {a3: background, aw: text, b_: tint};
	});
var $author$project$Type$Color$defaultBackgroundColor = 'EEE1DA';
var $author$project$Type$Color$defaultTextColor = '000000';
var $author$project$Type$Color$defaultTintColor = '5C9CE6';
var $author$project$Type$Color$defaultColors = A3($author$project$Type$Color$Colors, $author$project$Type$Color$defaultBackgroundColor, $author$project$Type$Color$defaultTextColor, $author$project$Type$Color$defaultTintColor);
var $author$project$Type$Announcement$emptyAnnouncement = {M: $author$project$Type$Color$defaultColors, aI: 0, bn: '0', aw: '', b$: 'Untitled'};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Modal$Announcement$init = F2(
	function (session, maybeAnnouncement) {
		var timeZone = $author$project$Type$Session$timeZone(session);
		var screenSize = $author$project$Type$Session$screenSize(session);
		if (!maybeAnnouncement.$) {
			var announcement = maybeAnnouncement.a;
			return _Utils_Tuple2(
				{
					i: announcement,
					an: false,
					z: _Utils_Tuple2('', $elm$core$Maybe$Nothing),
					bP: screenSize,
					db: session,
					aZ: 0,
					bZ: timeZone,
					az: false
				},
				A2(
					$elm$core$Task$attempt,
					function (_v1) {
						return $author$project$Modal$Announcement$NoOp;
					},
					$elm$browser$Browser$Dom$focus('titleField')));
		} else {
			return _Utils_Tuple2(
				{
					i: $author$project$Type$Announcement$emptyAnnouncement,
					an: true,
					z: _Utils_Tuple2('', $elm$core$Maybe$Nothing),
					bP: screenSize,
					db: session,
					aZ: 0,
					bZ: timeZone,
					az: false
				},
				A2($elm$core$Task$perform, $author$project$Modal$Announcement$GotTime, $elm$time$Time$now));
		}
	});
var $author$project$Ox$DatePicker$Day = 1;
var $author$project$Ox$DatePicker$init = F2(
	function (session, posix) {
		var timeZone = $author$project$Type$Session$timeZone(session);
		var screenSize = $author$project$Type$Session$screenSize(session);
		var model = {aM: $elm$core$Maybe$Nothing, w: 1, b: posix, bP: screenSize, db: session, bZ: timeZone};
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Firebase$removeAnnouncement = _Platform_outgoingPort('removeAnnouncement', $elm$json$Json$Encode$string);
var $author$project$LocalService$setOverflow = _Platform_outgoingPort('setOverflow', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Type$Color$colorsEncoder = function (colors) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'background',
				$elm$json$Json$Encode$string(colors.a3)),
				_Utils_Tuple2(
				'text',
				$elm$json$Json$Encode$string(colors.aw)),
				_Utils_Tuple2(
				'tint',
				$elm$json$Json$Encode$string(colors.b_))
			]));
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Type$Announcement$announcementEncoder = function (announcement) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(announcement.b$)),
				_Utils_Tuple2(
				'text',
				$elm$json$Json$Encode$string(announcement.aw)),
				_Utils_Tuple2(
				'colors',
				$author$project$Type$Color$colorsEncoder(announcement.M)),
				_Utils_Tuple2(
				'datePublished',
				$elm$json$Json$Encode$int(announcement.aI)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(announcement.bn))
			]));
};
var $author$project$Page$Announcement$DatePickerMsg = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $waratuman$time_extra$Time$Extra$isleapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $waratuman$time_extra$Time$Extra$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 1:
				return $waratuman$time_extra$Time$Extra$isleapYear(y) ? 29 : 28;
			case 3:
				return 30;
			case 5:
				return 30;
			case 8:
				return 30;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $waratuman$time_extra$Time$Extra$epoch = $elm$time$Time$millisToPosix(0);
var $waratuman$time_extra$Time$Extra$msPerDay = 86400000;
var $waratuman$time_extra$Time$Extra$msPerHour = 3600000;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.aY, posixMinutes) < 0) {
					return posixMinutes + era.c;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		bb: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		bw: month,
		b9: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bb;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bw;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).b9;
	});
var $waratuman$time_extra$Time$Extra$setDay = F3(
	function (z, d, t) {
		var t_ = $elm$time$Time$posixToMillis(t);
		var origHour = A2($elm$time$Time$toHour, z, t);
		var dim = A2(
			$waratuman$time_extra$Time$Extra$daysInMonth,
			A2($elm$time$Time$toYear, z, t),
			A2($elm$time$Time$toMonth, z, t));
		var cd = A2($elm$time$Time$toDay, z, t);
		var diff_ = ((_Utils_cmp(d, dim) < 1) ? (d - cd) : (dim - cd)) * $waratuman$time_extra$Time$Extra$msPerDay;
		var newHour = A2(
			$elm$time$Time$toHour,
			z,
			$elm$time$Time$millisToPosix(t_ + diff_));
		var diff = _Utils_eq(newHour, origHour) ? diff_ : ((_Utils_cmp(newHour, origHour) > 0) ? (diff_ + $waratuman$time_extra$Time$Extra$msPerHour) : (diff_ - $waratuman$time_extra$Time$Extra$msPerHour));
		return $elm$time$Time$millisToPosix(diff + t_);
	});
var $waratuman$time_extra$Time$Extra$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $waratuman$time_extra$Time$Extra$monthToInt = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $waratuman$time_extra$Time$Extra$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $waratuman$time_extra$Time$Extra$msPerYear = 31536000000;
var $waratuman$time_extra$Time$Extra$setMonth = F3(
	function (z, month, t) {
		var y = A2($elm$time$Time$toYear, z, t);
		var yms = ($waratuman$time_extra$Time$Extra$msPerYear * (y - 1970)) + ($waratuman$time_extra$Time$Extra$msPerDay * ($waratuman$time_extra$Time$Extra$leapYearsBefore(y) - $waratuman$time_extra$Time$Extra$leapYearsBefore(1970)));
		var intMonth = $waratuman$time_extra$Time$Extra$monthToInt(month);
		var mms = A3(
			$elm$core$List$foldl,
			F2(
				function (m_, days) {
					return (_Utils_cmp(
						intMonth,
						$waratuman$time_extra$Time$Extra$monthToInt(m_)) > 0) ? (days + A2($waratuman$time_extra$Time$Extra$daysInMonth, y, m_)) : days;
				}),
			0,
			$waratuman$time_extra$Time$Extra$months) * $waratuman$time_extra$Time$Extra$msPerDay;
		var dim = A2($waratuman$time_extra$Time$Extra$daysInMonth, y, month);
		var d_ = A2($elm$time$Time$toDay, z, t);
		var d = (_Utils_cmp(d_, dim) > 0) ? dim : d_;
		var dms = (d - 1) * $waratuman$time_extra$Time$Extra$msPerDay;
		return $elm$time$Time$millisToPosix((yms + mms) + dms);
	});
var $waratuman$time_extra$Time$Extra$setYear = F3(
	function (z, year, t) {
		var yms = ($waratuman$time_extra$Time$Extra$msPerYear * (year - 1970)) + ($waratuman$time_extra$Time$Extra$msPerDay * ($waratuman$time_extra$Time$Extra$leapYearsBefore(year) - $waratuman$time_extra$Time$Extra$leapYearsBefore(1970)));
		var y_ = A2($elm$time$Time$toYear, z, t);
		var yms_ = ($waratuman$time_extra$Time$Extra$msPerYear * (y_ - 1970)) + ($waratuman$time_extra$Time$Extra$msPerDay * ($waratuman$time_extra$Time$Extra$leapYearsBefore(y_) - $waratuman$time_extra$Time$Extra$leapYearsBefore(1970)));
		return $elm$time$Time$millisToPosix(
			($elm$time$Time$posixToMillis(t) - yms_) + yms);
	});
var $waratuman$time_extra$Time$Extra$fromDateTuple = F2(
	function (z, _v0) {
		var y = _v0.a;
		var m = _v0.b;
		var d = _v0.c;
		return A3(
			$waratuman$time_extra$Time$Extra$setDay,
			z,
			d,
			A3(
				$waratuman$time_extra$Time$Extra$setMonth,
				z,
				m,
				A3($waratuman$time_extra$Time$Extra$setYear, z, y, $waratuman$time_extra$Time$Extra$epoch)));
	});
var $waratuman$time_extra$Time$Extra$setHour = F3(
	function (z, h, t) {
		var t_ = $elm$time$Time$posixToMillis(t);
		var h_ = A2($elm$core$Basics$modBy, 24, h);
		var diff = (h_ - A2($elm$time$Time$toHour, z, t)) * 3600000;
		return $elm$time$Time$millisToPosix(t_ + diff);
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $waratuman$time_extra$Time$Extra$setMinute = F3(
	function (z, m, t) {
		var t_ = $elm$time$Time$posixToMillis(t);
		var m_ = A2($elm$core$Basics$modBy, 60, m);
		var diff = (m_ - A2($elm$time$Time$toMinute, z, t)) * 60000;
		return $elm$time$Time$millisToPosix(t_ + diff);
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $waratuman$time_extra$Time$Extra$setSecond = F3(
	function (z, s, t) {
		var t_ = $elm$time$Time$posixToMillis(t);
		var s_ = A2($elm$core$Basics$modBy, 60, s);
		var diff = (s_ - A2($elm$time$Time$toSecond, z, t)) * 1000;
		return $elm$time$Time$millisToPosix(t_ + diff);
	});
var $author$project$Ox$DatePicker$setDateTime = F3(
	function (_v0, _v1, zone) {
		var year = _v0.a;
		var month = _v0.b;
		var day = _v0.c;
		var hour = _v1.a;
		var minute = _v1.b;
		var second = _v1.c;
		var lastDayInMonth = A2($waratuman$time_extra$Time$Extra$daysInMonth, year, month);
		var newDay = (_Utils_cmp(day, lastDayInMonth) > 0) ? lastDayInMonth : day;
		return A3(
			$waratuman$time_extra$Time$Extra$setSecond,
			zone,
			second,
			A3(
				$waratuman$time_extra$Time$Extra$setMinute,
				zone,
				minute,
				A3(
					$waratuman$time_extra$Time$Extra$setHour,
					zone,
					hour,
					A2(
						$waratuman$time_extra$Time$Extra$fromDateTuple,
						zone,
						_Utils_Tuple3(year, month, newDay)))));
	});
var $waratuman$time_extra$Time$Extra$toDateTuple = F2(
	function (z, d) {
		return _Utils_Tuple3(
			A2($elm$time$Time$toYear, z, d),
			A2($elm$time$Time$toMonth, z, d),
			A2($elm$time$Time$toDay, z, d));
	});
var $waratuman$time_extra$Time$Extra$toTimeTuple = F2(
	function (z, d) {
		return _Utils_Tuple3(
			A2($elm$time$Time$toHour, z, d),
			A2($elm$time$Time$toMinute, z, d),
			A2($elm$time$Time$toSecond, z, d));
	});
var $author$project$Ox$DatePicker$update = F2(
	function (msg, model) {
		var _v0 = A2($waratuman$time_extra$Time$Extra$toTimeTuple, model.bZ, model.b);
		var hour = _v0.a;
		var minute = _v0.b;
		var second = _v0.c;
		var _v1 = A2($waratuman$time_extra$Time$Extra$toDateTuple, model.bZ, model.b);
		var year = _v1.a;
		var month = _v1.b;
		var day = _v1.c;
		switch (msg.$) {
			case 9:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var field = msg.a;
				return _Utils_eq(
					model.aM,
					$elm$core$Maybe$Just(field)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var newMonth = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							w: 1,
							b: A3(
								$author$project$Ox$DatePicker$setDateTime,
								_Utils_Tuple3(year, newMonth, day),
								_Utils_Tuple3(hour, minute, second),
								model.bZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var newDay = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3($waratuman$time_extra$Time$Extra$setDay, model.bZ, newDay, model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var newYear = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							w: 1,
							b: A3(
								$author$project$Ox$DatePicker$setDateTime,
								_Utils_Tuple3(newYear, month, day),
								_Utils_Tuple3(hour, minute, second),
								model.bZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var h = msg.a;
				var newHour = A2(
					$elm$core$Maybe$withDefault,
					hour,
					$elm$core$String$toInt(h));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Ox$DatePicker$setDateTime,
								_Utils_Tuple3(year, month, day),
								_Utils_Tuple3(newHour, minute, second),
								model.bZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var m = msg.a;
				var newMinute = A2(
					$elm$core$Maybe$withDefault,
					minute,
					$elm$core$String$toInt(m));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Ox$DatePicker$setDateTime,
								_Utils_Tuple3(year, month, day),
								_Utils_Tuple3(hour, newMinute, second),
								model.bZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var s = msg.a;
				var newSecond = A2(
					$elm$core$Maybe$withDefault,
					second,
					$elm$core$String$toInt(s));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Ox$DatePicker$setDateTime,
								_Utils_Tuple3(year, month, day),
								_Utils_Tuple3(hour, minute, newSecond),
								model.bZ)
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var panel = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: panel}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Announcement$passDatePickerMsg = F2(
	function (childMsg, model) {
		var _v0 = model.z;
		if (!_v0.$) {
			var _v1 = _v0.a;
			var announcement = _v1.a;
			var datePickerModel = _v1.b;
			var _v2 = A2($author$project$Ox$DatePicker$update, childMsg, datePickerModel);
			var subModel = _v2.a;
			var pageCmd = _v2.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						z: $elm$core$Maybe$Just(
							_Utils_Tuple2(announcement, subModel))
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Announcement$DatePickerMsg, pageCmd));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Firebase$updateAnnouncement = _Platform_outgoingPort('updateAnnouncement', $elm$core$Basics$identity);
var $author$project$Page$Announcement$updateDatePickerMsg = F2(
	function (childMsg, model) {
		var closeDatePicker = function (cmd) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{z: $elm$core$Maybe$Nothing}),
				cmd);
		};
		switch (childMsg.$) {
			case 7:
				var posix = childMsg.a;
				var _v1 = model.z;
				if (!_v1.$) {
					var _v2 = _v1.a;
					var announcement = _v2.a;
					var newDatePublished = $elm$time$Time$posixToMillis(posix);
					var newAnnouncement = _Utils_update(
						announcement,
						{aI: newDatePublished});
					return closeDatePicker(
						$author$project$Firebase$updateAnnouncement(
							$author$project$Type$Announcement$announcementEncoder(newAnnouncement)));
				} else {
					return closeDatePicker($elm$core$Platform$Cmd$none);
				}
			case 8:
				return closeDatePicker($elm$core$Platform$Cmd$none);
			default:
				return A2($author$project$Page$Announcement$passDatePickerMsg, childMsg, model);
		}
	});
var $author$project$Firebase$createAnnouncement = _Platform_outgoingPort('createAnnouncement', $elm$core$Basics$identity);
var $author$project$Modal$Announcement$DatePickerMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Modal$Announcement$passDatePickerMsg = F2(
	function (childMsg, model) {
		var _v0 = model.z;
		if (!_v0.b.$) {
			var id = _v0.a;
			var datePickerModel = _v0.b.a;
			var _v1 = A2($author$project$Ox$DatePicker$update, childMsg, datePickerModel);
			var subModel = _v1.a;
			var pageCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						z: _Utils_Tuple2(
							id,
							$elm$core$Maybe$Just(subModel))
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Modal$Announcement$DatePickerMsg, pageCmd));
		} else {
			var _v2 = _v0.b;
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Modal$Announcement$update = F2(
	function (msg, model) {
		var updateAnnouncement = function (newAnnouncement) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{i: newAnnouncement}),
				$elm$core$Platform$Cmd$none);
		};
		var colors = model.i.M;
		var announcement = model.i;
		switch (msg.$) {
			case 6:
				var field = msg.a;
				var data = msg.b;
				switch (field) {
					case 0:
						return updateAnnouncement(
							_Utils_update(
								announcement,
								{aw: data}));
					case 1:
						return updateAnnouncement(
							_Utils_update(
								announcement,
								{b$: data}));
					case 3:
						var newColors = _Utils_update(
							colors,
							{a3: data});
						return updateAnnouncement(
							_Utils_update(
								announcement,
								{M: newColors}));
					case 2:
						var newColors = _Utils_update(
							colors,
							{aw: data});
						return updateAnnouncement(
							_Utils_update(
								announcement,
								{M: newColors}));
					default:
						var newColors = _Utils_update(
							colors,
							{b_: data});
						return updateAnnouncement(
							_Utils_update(
								announcement,
								{M: newColors}));
				}
			case 7:
				var time = msg.a;
				return updateAnnouncement(
					_Utils_update(
						announcement,
						{
							aI: $elm$time$Time$posixToMillis(time)
						}));
			case 8:
				var childMsg = msg.a;
				switch (childMsg.$) {
					case 7:
						var posix = childMsg.a;
						var newDatePublished = $elm$time$Time$posixToMillis(posix);
						var newAnnouncement = _Utils_update(
							announcement,
							{aI: newDatePublished});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									i: newAnnouncement,
									z: _Utils_Tuple2('', $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none);
					case 8:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									z: _Utils_Tuple2('', $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none);
					default:
						return A2($author$project$Modal$Announcement$passDatePickerMsg, childMsg, model);
				}
			case 9:
				var id = msg.a;
				var timestamp = msg.b;
				var posixTime = $elm$time$Time$millisToPosix(timestamp);
				var _v3 = A2($author$project$Ox$DatePicker$init, model.db, posixTime);
				var subModel = _v3.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: _Utils_Tuple2(
								id,
								$elm$core$Maybe$Just(subModel))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Announcement$passThruModalMsg = F2(
	function (subMsg, model) {
		var _v0 = model.A;
		if (!_v0.$) {
			var editModel = _v0.a;
			var _v1 = A2($author$project$Modal$Announcement$update, subMsg, editModel);
			var newModel = _v1.a;
			var cmdMsg = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						A: $elm$core$Maybe$Just(newModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Announcement$ModalMsg, cmdMsg));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Announcement$updateModalMsg = F2(
	function (subMsg, model) {
		switch (subMsg.$) {
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{A: $elm$core$Maybe$Nothing}),
					$author$project$LocalService$setOverflow('auto'));
			case 0:
				var announcement = subMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{A: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('auto'),
								$author$project$Firebase$createAnnouncement(
								$author$project$Type$Announcement$announcementEncoder(announcement))
							])));
			case 1:
				var announcement = subMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{A: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('auto'),
								$author$project$Firebase$updateAnnouncement(
								$author$project$Type$Announcement$announcementEncoder(announcement))
							])));
			default:
				return A2($author$project$Page$Announcement$passThruModalMsg, subMsg, model);
		}
	});
var $author$project$Page$Announcement$PageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var bool = msg.a;
				return _Utils_Tuple2(bool, $elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(!model, $elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Announcement$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.c4);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c4: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Announcement$PageMsg, pageCmd));
	});
var $author$project$Page$Announcement$update = F2(
	function (msg, model) {
		var setModel = F2(
			function (subMsg, subModal) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							A: $elm$core$Maybe$Just(subModal)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Announcement$ModalMsg, subMsg),
								$author$project$LocalService$setOverflow('hidden')
							])));
			});
		switch (msg.$) {
			case 1:
				var announcement = msg.a;
				var _v1 = A2(
					$author$project$Modal$Announcement$init,
					model.db,
					$elm$core$Maybe$Just(announcement));
				var modalModel = _v1.a;
				var subMsg = _v1.b;
				return A2(setModel, subMsg, modalModel);
			case 2:
				var _v2 = A2($author$project$Modal$Announcement$init, model.db, $elm$core$Maybe$Nothing);
				var modalModel = _v2.a;
				var subMsg = _v2.b;
				return A2(setModel, subMsg, modalModel);
			case 3:
				var docId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$removeAnnouncement(docId));
			case 4:
				var announcement = msg.a;
				var posixTime = $elm$time$Time$millisToPosix(announcement.aI);
				var _v3 = A2($author$project$Ox$DatePicker$init, model.db, posixTime);
				var subModel = _v3.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just(
								_Utils_Tuple2(announcement, subModel))
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var childMsg = msg.a;
				return A2($author$project$Page$Announcement$updatePageMsg, childMsg, model);
			case 6:
				var childMsg = msg.a;
				return A2($author$project$Page$Announcement$updateModalMsg, childMsg, model);
			case 7:
				var childMsg = msg.a;
				return A2($author$project$Page$Announcement$updateDatePickerMsg, childMsg, model);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateAnnouncementMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Announcement$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$AnnouncementPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$AnnouncementMsg, subCmd));
	});
var $author$project$Type$Alert$Alert = F4(
	function (kind, message, inputField, dateCreated) {
		return {ck: dateCreated, bq: inputField, cK: kind, bv: message};
	});
var $author$project$Firebase$initAppData = _Platform_outgoingPort('initAppData', $elm$json$Json$Encode$string);
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$LocalService$storeCache = _Platform_outgoingPort(
	'storeCache',
	function ($) {
		return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
	});
var $author$project$Type$Flags$saveLocalStore = function (localStorage) {
	var data = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(localStorage.cp))
			]));
	return $author$project$LocalService$storeCache(
		$elm$core$Maybe$Just(data));
};
var $author$project$Type$User$User = F7(
	function (uid, email, isAdmin, isEditor, isModerator, maybeProfile, maybeAccount) {
		return {cp: email, cA: isAdmin, cB: isEditor, cD: isModerator, cO: maybeAccount, cP: maybeProfile, dl: uid};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Type$User$Profile = F5(
	function (id, username, badgeCount, img, blurb) {
		return {aB: badgeCount, cf: blurb, bn: id, cv: img, dn: username};
	});
var $author$project$Type$User$profileDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'blurb',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'img',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'badgeCount',
			$elm$json$Json$Decode$int,
			0,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'username',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$User$Profile))))));
var $author$project$Type$User$userDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'maybeAccount',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Type$User$accountDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'maybeProfile',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Type$User$profileDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'isModerator',
			$elm$json$Json$Decode$bool,
			false,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'isEditor',
				$elm$json$Json$Decode$bool,
				false,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'isAdmin',
					$elm$json$Json$Decode$bool,
					false,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'email',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'uid',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Type$User$User))))))));
var $author$project$Update$updateAuthStateChanged = F2(
	function (model, data) {
		var params = $author$project$Type$Session$params(model.db);
		var appId = $author$project$Type$Session$appId(model.db);
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$userDecoder, data);
		if (!_v0.$) {
			var user = _v0.a;
			var localStorage = $author$project$Type$Flags$LocalStorage(user.cp);
			var _v1 = (user.cA || user.cB) ? _Utils_Tuple2(
				A2($author$project$Type$Session$SignedIn, params, user),
				_List_Nil) : _Utils_Tuple2(
				$author$project$Type$Session$Guest(params),
				_List_fromArray(
					[
						A4($author$project$Type$Alert$Alert, 'error', 'You must sign in with an Editor or Admin account.', 'stayUp', 0)
					]));
			var newSession = _v1.a;
			var newAlerts = _v1.b;
			var cmdBatchList = _List_fromArray(
				[
					$author$project$Type$Flags$saveLocalStore(localStorage),
					function () {
					var _v2 = model.c3;
					if (_v2.$ === 1) {
						return A2(
							$author$project$Route$replaceUrl,
							$author$project$Type$Session$navKey(newSession),
							$author$project$Route$Home);
					} else {
						return A2(
							$author$project$Route$replaceUrl,
							$author$project$Type$Session$navKey(newSession),
							A2($elm$core$Maybe$withDefault, $author$project$Route$Home, model.cQ));
					}
				}()
				]);
			var newCmdBatchList = (user.cA || user.cB) ? A2(
				$elm$core$List$cons,
				$author$project$Firebase$initAppData(appId),
				cmdBatchList) : _List_fromArray(
				[
					A2(
					$author$project$Route$replaceUrl,
					$author$project$Type$Session$navKey(newSession),
					$author$project$Route$Home)
				]);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cb: _Utils_ap(model.cb, newAlerts),
						db: newSession
					}),
				$elm$core$Platform$Cmd$batch(newCmdBatchList));
		} else {
			var newSession = $author$project$Type$Session$Guest(params);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{db: newSession}),
				A2(
					$author$project$Route$replaceUrl,
					$author$project$Type$Session$navKey(newSession),
					$author$project$Route$Home));
		}
	});
var $author$project$Page$GetStarted$Email = 0;
var $author$project$Page$GetStarted$NoOp = {$: 0};
var $author$project$Page$GetStarted$Password = 1;
var $author$project$Page$GetStarted$SignIn = 1;
var $author$project$Page$GetStarted$SignUp = 2;
var $author$project$Firebase$fetchSignInMethodsForEmail = _Platform_outgoingPort('fetchSignInMethodsForEmail', $elm$json$Json$Encode$string);
var $author$project$Firebase$sendPasswordResetEmail = _Platform_outgoingPort('sendPasswordResetEmail', $elm$json$Json$Encode$string);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Page$GetStarted$signInMethodsDecoder = A2(
	$elm$json$Json$Decode$field,
	'methods',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Firebase$signInWithEmailAndPassword = _Platform_outgoingPort('signInWithEmailAndPassword', $elm$core$Basics$identity);
var $author$project$Firebase$signinEncoder = F2(
	function (email, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$Firebase$signin = F2(
	function (email, password) {
		return $author$project$Firebase$signInWithEmailAndPassword(
			A2($author$project$Firebase$signinEncoder, email, password));
	});
var $author$project$Firebase$createUserWithEmailAndPassword = _Platform_outgoingPort('createUserWithEmailAndPassword', $elm$core$Basics$identity);
var $author$project$Firebase$signup = F2(
	function (email, password) {
		return $author$project$Firebase$createUserWithEmailAndPassword(
			A2($author$project$Firebase$signinEncoder, email, password));
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Page$GetStarted$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				if (!msg.a) {
					var _v1 = msg.a;
					var email = msg.b;
					var newModel = _Utils_update(
						model,
						{cp: email});
					var isEmail = $rtfeldman$elm_validate$Validate$isValidEmail(email);
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var _v2 = msg.a;
					var password = msg.b;
					var newModel = _Utils_update(
						model,
						{C: password});
					var disabled = $elm$core$String$length(password) < 6;
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var field = msg.a;
				return _Utils_eq(
					model.aM,
					$elm$core$Maybe$Just(field)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$fetchSignInMethodsForEmail(model.cp));
			case 7:
				var val = msg.a;
				var methods = A2(
					$elm$core$Result$withDefault,
					_List_Nil,
					A2($elm$json$Json$Decode$decodeValue, $author$project$Page$GetStarted$signInMethodsDecoder, val));
				var _v3 = (!$elm$core$List$length(methods)) ? _Utils_Tuple3(2, 0, 'emailField') : _Utils_Tuple3(1, 1, 'passwordField');
				var stage = _v3.a;
				var formField = _v3.b;
				var fieldName = _v3.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(formField),
							av: stage
						}),
					A2(
						$elm$core$Task$attempt,
						function (_v4) {
							return $author$project$Page$GetStarted$NoOp;
						},
						$elm$browser$Browser$Dom$focus(fieldName)));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cb: _List_Nil, C: ''}),
					A2($author$project$Firebase$signin, model.cp, model.C));
			case 6:
				return _Utils_Tuple2(
					model,
					A2($author$project$Firebase$signup, model.cp, model.C));
			case 8:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$sendPasswordResetEmail(model.cp));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateGetStarted = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$GetStarted$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$GetStartedPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$GetStartedMsg, subCmd));
	});
var $author$project$Page$GetStarted$CheckEmail = {$: 1};
var $author$project$Type$Flags$updateEmail = F2(
	function (maybeLocalStorage, email) {
		if (!maybeLocalStorage.$) {
			var localStorage = maybeLocalStorage.a;
			return _Utils_update(
				localStorage,
				{cp: email});
		} else {
			return $author$project$Type$Flags$LocalStorage(email);
		}
	});
var $author$project$Update$updateGetStartedForCheckEmail = F2(
	function (model, subModel) {
		var session = model.db;
		var newSession = $author$project$Type$Session$Guest(
			$author$project$Type$Session$params(model.db));
		var _v0 = A2($author$project$Page$GetStarted$update, $author$project$Page$GetStarted$CheckEmail, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		var localStore = A2($author$project$Type$Flags$updateEmail, model.aq, newSubModel.cp);
		var newSubModel2 = _Utils_update(
			newSubModel,
			{db: newSession});
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$GetStartedPage(newSubModel2),
					db: newSession
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, $author$project$Msg$GetStartedMsg, subCmd),
						$author$project$Type$Flags$saveLocalStore(localStore)
					])));
	});
var $author$project$Type$Alert$alertDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'dateCreated',
	$elm$json$Json$Decode$int,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'inputField',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'message',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'kind',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Type$Alert$Alert)))));
var $author$project$Update$updateGotAlert = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Alert$alertDecoder, data);
		if (!_v0.$) {
			var alert = _v0.a;
			if (alert.bq === '') {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cb: A2($elm$core$List$cons, alert, model.cb)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				var _v1 = model.c3;
				if (_v1.$ === 1) {
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{
							cb: A2($elm$core$List$cons, alert, subModel.cb)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c3: $author$project$Type$Page$GetStartedPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cb: A2($elm$core$List$cons, alert, model.cb)
							}),
						$elm$core$Platform$Cmd$none);
				}
			}
		} else {
			var error = _v0.a;
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$Announcement$Announcement = F5(
	function (id, title, text, datePublished, colors) {
		return {M: colors, aI: datePublished, bn: id, aw: text, b$: title};
	});
var $author$project$Type$Color$colorsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tint',
	$elm$json$Json$Decode$string,
	$author$project$Type$Color$defaultTintColor,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'text',
		$elm$json$Json$Decode$string,
		$author$project$Type$Color$defaultTextColor,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'background',
			$elm$json$Json$Decode$string,
			$author$project$Type$Color$defaultBackgroundColor,
			$elm$json$Json$Decode$succeed($author$project$Type$Color$Colors))));
var $author$project$Type$Announcement$announcementDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'colors',
	$author$project$Type$Color$colorsDecoder,
	$author$project$Type$Color$defaultColors,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'datePublished',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'text',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'title',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$Announcement$Announcement))))));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Update$updateErrorAlert = F2(
	function (error, model) {
		var msg = $elm$html$Html$text(
			$elm$json$Json$Decode$errorToString(error));
		var alert = A4($author$project$Type$Alert$Alert, 'Error', 'Oops', '', model.cY);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					cb: _List_fromArray(
						[alert])
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Update$updateGotAnnouncement = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Announcement$announcementDecoder, data);
		if (!_v0.$) {
			var announcement = _v0.a;
			var newAnnouncements = $elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function (x) {
						return x.aI;
					},
					A2(
						$elm$core$List$cons,
						announcement,
						A2(
							$elm$core$List$filter,
							function (x) {
								return !_Utils_eq(x.bn, announcement.bn);
							},
							model.cd))));
			var newModel = _Utils_update(
				model,
				{cd: newAnnouncements});
			var _v1 = model.c3;
			if (_v1.$ === 3) {
				var subModel = _v1.a;
				var newSubModel = _Utils_update(
					subModel,
					{cd: newAnnouncements});
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{
							c3: $author$project$Type$Page$AnnouncementPage(newSubModel)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Page$ItemEdit$ImageLibrary = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Modal$ImageLibrary$Previewing = {$: 3};
var $author$project$Modal$ImageLibrary$gotImage = F2(
	function (model, image) {
		var newImages = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.ck;
				},
				A2($elm$core$List$cons, image, model.am)));
		var _v0 = image.cX ? _Utils_Tuple2(
			$author$project$Modal$ImageLibrary$Previewing,
			$elm$core$Maybe$Just(image.bo)) : _Utils_Tuple2(model.aZ, model.Q);
		var newStatus = _v0.a;
		var maybePreview = _v0.b;
		return _Utils_update(
			model,
			{am: newImages, Q: maybePreview, aZ: newStatus});
	});
var $author$project$Page$ItemEdit$gotImage = F2(
	function (model, image) {
		var _v0 = model.v;
		if ((!_v0.$) && (!_v0.a.$)) {
			var _v1 = _v0.a;
			var imageKind = _v1.a;
			var subModel = _v1.b;
			var newSubModel = A2($author$project$Modal$ImageLibrary$gotImage, subModel, image);
			return _Utils_update(
				model,
				{
					v: $elm$core$Maybe$Just(
						A2($author$project$Page$ItemEdit$ImageLibrary, imageKind, newSubModel))
				});
		} else {
			return model;
		}
	});
var $author$project$Page$PackEdit$ImageLibrary = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PackEdit$gotImage = F2(
	function (model, image) {
		var _v0 = model.v;
		if (!_v0.$) {
			if (!_v0.a.$) {
				var subModel = _v0.a.a;
				var newSubModel = A2($author$project$Modal$ImageLibrary$gotImage, subModel, image);
				return _Utils_update(
					model,
					{
						v: $elm$core$Maybe$Just(
							$author$project$Page$PackEdit$ImageLibrary(newSubModel))
					});
			} else {
				var _v1 = _v0.a;
				return model;
			}
		} else {
			return model;
		}
	});
var $author$project$Page$SetEdit$ImageLibrary = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$SetEdit$gotImage = F2(
	function (model, image) {
		var _v0 = model.v;
		if (!_v0.$) {
			if (!_v0.a.$) {
				var _v1 = _v0.a;
				var imageKind = _v1.a;
				var subModel = _v1.b;
				var newSubModel = A2($author$project$Modal$ImageLibrary$gotImage, subModel, image);
				return _Utils_update(
					model,
					{
						v: $elm$core$Maybe$Just(
							A2($author$project$Page$SetEdit$ImageLibrary, imageKind, newSubModel))
					});
			} else {
				var subModel = _v0.a.a;
				return model;
			}
		} else {
			return model;
		}
	});
var $author$project$Type$Image$Image = F5(
	function (fileName, imageUrl, tags, dateCreated, _new) {
		return {ck: dateCreated, aa: fileName, bo: imageUrl, cX: _new, ag: tags};
	});
var $author$project$Type$Image$imageDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'new',
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'dateCreated',
		$elm$json$Json$Decode$int,
		0,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'imageUrl',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'fileName',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$Image$Image))))));
var $author$project$Update$updateGotImage = F2(
	function (model, data) {
		var sendImageToPage = F2(
			function (subpage, call) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c3: subpage(call)
						}),
					$elm$core$Platform$Cmd$none);
			});
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Image$imageDecoder, data);
		if (!_v0.$) {
			var image = _v0.a;
			var _v1 = model.c3;
			switch (_v1.$) {
				case 5:
					var subModel = _v1.a;
					return A2(
						sendImageToPage,
						$author$project$Type$Page$SetEditPage,
						A2($author$project$Page$SetEdit$gotImage, subModel, image));
				case 6:
					var subModel = _v1.a;
					return A2(
						sendImageToPage,
						$author$project$Type$Page$ItemEditPage,
						A2($author$project$Page$ItemEdit$gotImage, subModel, image));
				case 7:
					var subModel = _v1.a;
					return A2(
						sendImageToPage,
						$author$project$Type$Page$PackEditPage,
						A2($author$project$Page$PackEdit$gotImage, subModel, image));
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Type$Item$Item = function (id) {
	return function (setId) {
		return function (batchSize) {
			return function (content) {
				return function (countMax) {
					return function (datePublished) {
						return function (editionType) {
							return function (itemNumber) {
								return function (maxBatches) {
									return function (ratio) {
										return function (status) {
											return function (tags) {
												return function (template) {
													return function (title) {
														return function (variants) {
															return {aC: batchSize, N: content, aF: countMax, aI: datePublished, aK: editionType, bn: id, cG: itemNumber, ap: maxBatches, I: ratio, dd: setId, aZ: status, ag: tags, ah: template, b$: title, b5: variants};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Type$Item$contentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'cornerRadius',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'backImageUrl',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Type$Item$Content)));
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Type$Item$setIdFromString = function (str) {
	var _v0 = A2(
		$elm_community$list_extra$List$Extra$getAt,
		1,
		A2($elm$core$String$split, '/', str));
	if (!_v0.$) {
		if (_v0.a === '') {
			return $elm$json$Json$Decode$fail('The setId is blank.');
		} else {
			var setId = _v0.a;
			return $elm$json$Json$Decode$succeed(setId);
		}
	} else {
		return $elm$json$Json$Decode$fail('The item does not have a setId ');
	}
};
var $author$project$Type$Variant$Variant = function (id) {
	return function (batchSize) {
		return function (content) {
			return function (countMax) {
				return function (editionType) {
					return function (imageUrl) {
						return function (itemTemplate) {
							return function (itemTitle) {
								return function (maxBatches) {
									return function (rarityFactor) {
										return function (ratio) {
											return function (status) {
												return function (title) {
													return function (path) {
														return function (variantTemplateRef) {
															return {aC: batchSize, N: content, aF: countMax, aK: editionType, bn: id, bo: imageUrl, cI: itemTemplate, cJ: itemTitle, ap: maxBatches, aT: path, aV: rarityFactor, I: ratio, aZ: status, b$: title, b4: variantTemplateRef};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Variant$Content = F2(
	function (maybeBackgroundImageUrl, backgroundColor) {
		return {aA: backgroundColor, bu: maybeBackgroundImageUrl};
	});
var $author$project$Type$Variant$contentDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'backgroundColor',
	$elm$json$Json$Decode$string,
	'#ffffff',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'backgroundImageUrl',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Type$Variant$Content)));
var $author$project$Type$DocRef$DocRef = F2(
	function (id, path) {
		return {bn: id, aT: path};
	});
var $author$project$Type$DocRef$docRefDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'path',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Type$DocRef$DocRef)));
var $author$project$Type$Variant$variantDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variantTemplateRef',
	$author$project$Type$DocRef$docRefDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'path',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'title',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'status',
				$elm$json$Json$Decode$int,
				0,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'ratio',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'rarityFactor',
						$elm$json$Json$Decode$float,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'maxBatches',
							$elm$json$Json$Decode$int,
							0,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'itemTitle',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'itemTemplate',
									$elm$json$Json$Decode$int,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'imageUrl',
										$elm$json$Json$Decode$string,
										'',
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'editionType',
											$elm$json$Json$Decode$int,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'countMax',
												$elm$json$Json$Decode$int,
												0,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'content',
													$author$project$Type$Variant$contentDecoder,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'batchSize',
														$elm$json$Json$Decode$int,
														0,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'id',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Type$Variant$Variant))))))))))))))));
var $author$project$Type$Item$itemDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'variants',
	$elm$json$Json$Decode$list($author$project$Type$Variant$variantDecoder),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'template',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'tags',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'status',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'ratio',
						$elm$json$Json$Decode$float,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'maxBatches',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'itemNumber',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'editionType',
									$elm$json$Json$Decode$int,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'datePublished',
										$elm$json$Json$Decode$int,
										0,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'countMax',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'content',
												$author$project$Type$Item$contentDecoder,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'batchSize',
													$elm$json$Json$Decode$int,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'path',
														A2($elm$json$Json$Decode$andThen, $author$project$Type$Item$setIdFromString, $elm$json$Json$Decode$string),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'id',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Type$Item$Item))))))))))))))));
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$Update$updateGotItem = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Item$itemDecoder, data);
		if (!_v0.$) {
			var item = _v0.a;
			var newItemDict = A3($elm$core$Dict$insert, item.bn, item, model.cF);
			var newModel = _Utils_update(
				model,
				{cF: newItemDict});
			var _v1 = model.c3;
			switch (_v1.$) {
				case 5:
					var subModel = _v1.a;
					var newItems = _Utils_eq(item.dd, subModel.bn) ? A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.cG;
						},
						A2(
							$elm_community$list_extra$List$Extra$uniqueBy,
							function ($) {
								return $.bn;
							},
							A2($elm$core$List$cons, item, subModel.P))) : subModel.P;
					var newSubModel = _Utils_update(
						subModel,
						{P: newItems});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$SetEditPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var subModel = _v1.a;
					var newSubModel = _Utils_eq(item.bn, subModel.bn) ? _Utils_update(
						subModel,
						{
							G: $elm$core$String$fromFloat(item.N.G),
							ao: item,
							I: $elm$core$String$fromFloat(item.I),
							b$: item.b$
						}) : subModel;
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$ItemEditPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Type$Pack$isInSet = F2(
	function (setId, pack) {
		return A2(
			$elm$core$List$any,
			function (sr) {
				return _Utils_eq(sr.bn, setId);
			},
			pack.de);
	});
var $author$project$Type$Pack$Pack = function (id) {
	return function (bundleId) {
		return function (count) {
			return function (dateCreated) {
				return function (description) {
					return function (itemRefs) {
						return function (oddsDescription) {
							return function (setRefs) {
								return function (status) {
									return function (title) {
										return {aE: bundleId, a8: count, ck: dateCreated, cl: description, bn: id, cH: itemRefs, by: oddsDescription, de: setRefs, aZ: status, b$: title};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Pack$packDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'setRefs',
			$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'oddsDescription',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'itemRefs',
					$elm$json$Json$Decode$list($author$project$Type$DocRef$docRefDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'dateCreated',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'count',
								$elm$json$Json$Decode$int,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'bundleId',
									$elm$json$Json$Decode$string,
									'',
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Type$Pack$Pack)))))))))));
var $author$project$Update$updateGotPack = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Pack$packDecoder, data);
		if (!_v0.$) {
			var pack = _v0.a;
			var newPackDict = A3($elm$core$Dict$insert, pack.bn, pack, model.c2);
			var newModel = _Utils_update(
				model,
				{c2: newPackDict});
			var _v1 = model.c3;
			switch (_v1.$) {
				case 5:
					var subModel = _v1.a;
					var setPacks = A2(
						$elm$core$List$filter,
						$author$project$Type$Pack$isInSet(subModel.bn),
						$elm$core$Dict$values(newPackDict));
					var newSubModel = _Utils_update(
						subModel,
						{at: setPacks});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$SetEditPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{ae: pack});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$PackEditPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Update$updateGotSetRemoved = F2(
	function (model, id) {
		var newSetDict = A2($elm$core$Dict$remove, id, model.dc);
		var newModel = _Utils_update(
			model,
			{dc: newSetDict});
		var _v0 = model.c3;
		if (_v0.$ === 4) {
			var subModel = _v0.a;
			var newSubModel = _Utils_update(
				subModel,
				{dc: newSetDict});
			return _Utils_Tuple2(
				_Utils_update(
					newModel,
					{
						c3: $author$project$Type$Page$SetListPage(newSubModel)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateGotVariant = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Variant$variantDecoder, data);
		if (!_v0.$) {
			var variant = _v0.a;
			var newVariantDict = A3($elm$core$Dict$insert, variant.bn, variant, model.$7);
			var newModel = _Utils_update(
				model,
				{$7: newVariantDict});
			var _v1 = model.c3;
			switch (_v1.$) {
				case 4:
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{$7: newVariantDict});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$SetListPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{$7: newVariantDict});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$ItemEditPage(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			return A2($author$project$Update$updateErrorAlert, error, model);
		}
	});
var $author$project$Type$Screen$ExtraLarge = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Type$Screen$Large = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$Screen$Medium = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Type$Screen$toSize = F2(
	function (_v0, scrollTop) {
		var width = _v0.a;
		var height = _v0.b;
		return (width > 991) ? A2(
			$author$project$Type$Screen$ExtraLarge,
			_Utils_Tuple2(width, height),
			scrollTop) : ((width > 767) ? A2(
			$author$project$Type$Screen$Large,
			_Utils_Tuple2(width, height),
			scrollTop) : ((width > 479) ? A2(
			$author$project$Type$Screen$Medium,
			_Utils_Tuple2(width, height),
			scrollTop) : A2(
			$author$project$Type$Screen$Small,
			_Utils_Tuple2(width, height),
			scrollTop)));
	});
var $author$project$Update$updateGotViewport = F2(
	function (model, viewport) {
		var screenSize = A2(
			$author$project$Type$Screen$toSize,
			_Utils_Tuple2(viewport.bO.dt, viewport.bO.ct),
			viewport.dr.dv);
		var params = $author$project$Type$Session$params(model.db);
		var newParams = _Utils_update(
			params,
			{bP: screenSize});
		var newSession = A2($author$project$Type$Session$updateParams, model.db, newParams);
		var newModel = _Utils_update(
			model,
			{
				cR: $elm$core$Maybe$Just(viewport),
				bP: screenSize,
				db: newSession
			});
		return A3($author$project$Update$updateSessionInPage, newModel, model.c3, newSession);
	});
var $author$project$Firebase$repopProfilesInAlgolia = _Platform_outgoingPort(
	'repopProfilesInAlgolia',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Page$Home$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.c4);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c4: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Home$PageMsg, pageCmd));
	});
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var field = msg.a;
				var str = msg.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var field = msg.a;
				return _Utils_eq(
					model.aM,
					$elm$core$Maybe$Just(field)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var childMsg = msg.a;
				return A2($author$project$Page$Home$updatePageMsg, childMsg, model);
			case 5:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$repopProfilesInAlgolia(0));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updatePassThruHomeMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Home$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$HomePage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$HomeMsg, subCmd));
	});
var $author$project$Update$updateHomeMsg = F3(
	function (model, subMsg, subModel) {
		return A3($author$project$Update$updatePassThruHomeMsg, model, subMsg, subModel);
	});
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $danyx23$elm_uuid$Uuid$toString = function (_v0) {
	var internalString = _v0;
	return internalString;
};
var $author$project$Page$ItemEdit$NoOp = {$: 0};
var $author$project$Page$ItemEdit$VariantEdit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ItemEdit$imageKindToString = function (kind) {
	return 'Back Image';
};
var $author$project$Modal$ImageLibrary$Default = {$: 0};
var $author$project$Modal$ImageLibrary$init = F4(
	function (session, pathList, title, imageKind) {
		return _Utils_Tuple2(
			{
				aH: '',
				aa: '',
				aN: imageKind,
				am: _List_Nil,
				Q: $elm$core$Maybe$Nothing,
				aU: pathList,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				aZ: $author$project$Modal$ImageLibrary$Default,
				b$: title
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Modal$VariantEdit$Editing = 0;
var $author$project$Modal$VariantEdit$init = F4(
	function (session, set, item, variant) {
		return _Utils_Tuple2(
			{
				aH: '',
				aM: $elm$core$Maybe$Nothing,
				ao: item,
				v: $elm$core$Maybe$Nothing,
				af: true,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				bT: set,
				aZ: 0,
				b$: variant.b$,
				az: false,
				t: variant
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Type$Item$encodeBackImageUrl = function (maybeBackImageUrl) {
	if (!maybeBackImageUrl.$) {
		var imageUrl = maybeBackImageUrl.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'backImageUrl',
				$elm$json$Json$Encode$string(imageUrl))
			]);
	} else {
		return _List_Nil;
	}
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Type$Item$encodeCornerRadius = function (number) {
	return (!number) ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'cornerRadius',
			$elm$json$Json$Encode$float(number))
		]);
};
var $author$project$Type$Item$contentEncoder = function (content) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			$author$project$Type$Item$encodeBackImageUrl(content.aO),
			_Utils_ap(
				$author$project$Type$Item$encodeCornerRadius(content.G),
				_List_Nil)));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Type$Variant$encodeBackgroundColor = function (value) {
	return (value === '') ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'backgroundColor',
			$elm$json$Json$Encode$string(value))
		]);
};
var $author$project$Type$Variant$encodeBackgroundImageUrl = function (maybeBackgroundImageUrl) {
	if (!maybeBackgroundImageUrl.$) {
		var imageUrl = maybeBackgroundImageUrl.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'backgroundImageUrl',
				$elm$json$Json$Encode$string(imageUrl))
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Type$Variant$contentEncoder = function (content) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			$author$project$Type$Variant$encodeBackgroundImageUrl(content.bu),
			_Utils_ap(
				$author$project$Type$Variant$encodeBackgroundColor(content.aA),
				_List_Nil)));
};
var $author$project$Type$DocRef$docRefEncoder = function (docRef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(docRef.bn)),
				_Utils_Tuple2(
				'path',
				$elm$json$Json$Encode$string(docRef.aT))
			]));
};
var $author$project$Type$Variant$variantEncoder = function (_var) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(_var.bn)),
				_Utils_Tuple2(
				'batchSize',
				$elm$json$Json$Encode$int(_var.aC)),
				_Utils_Tuple2(
				'content',
				$author$project$Type$Variant$contentEncoder(_var.N)),
				_Utils_Tuple2(
				'countMax',
				$elm$json$Json$Encode$int(_var.aF)),
				_Utils_Tuple2(
				'editionType',
				$elm$json$Json$Encode$int(_var.aK)),
				_Utils_Tuple2(
				'imageUrl',
				$elm$json$Json$Encode$string(_var.bo)),
				_Utils_Tuple2(
				'itemTemplate',
				$elm$json$Json$Encode$int(_var.cI)),
				_Utils_Tuple2(
				'itemTitle',
				$elm$json$Json$Encode$string(_var.cJ)),
				_Utils_Tuple2(
				'maxBatches',
				$elm$json$Json$Encode$int(_var.ap)),
				_Utils_Tuple2(
				'rarityFactor',
				$elm$json$Json$Encode$float(_var.aV)),
				_Utils_Tuple2(
				'ratio',
				$elm$json$Json$Encode$float(_var.I)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(_var.aZ)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(_var.b$)),
				_Utils_Tuple2(
				'variantTemplateRef',
				$author$project$Type$DocRef$docRefEncoder(_var.b4))
			]));
};
var $author$project$Type$Item$itemEncoder = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(item.bn)),
				_Utils_Tuple2(
				'setId',
				$elm$json$Json$Encode$string(item.dd)),
				_Utils_Tuple2(
				'content',
				$author$project$Type$Item$contentEncoder(item.N)),
				_Utils_Tuple2(
				'ratio',
				$elm$json$Json$Encode$float(item.I)),
				_Utils_Tuple2(
				'tags',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, item.ag)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(item.b$)),
				_Utils_Tuple2(
				'variants',
				A2($elm$json$Json$Encode$list, $author$project$Type$Variant$variantEncoder, item.b5))
			]));
};
var $elm$core$String$toFloat = _String_toFloat;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Modal$ImageLibrary$GotFile = function (a) {
	return {$: 3, a: a};
};
var $author$project$Modal$ImageLibrary$GotImageData = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Modal$ImageLibrary$Success = function (a) {
	return {$: 4, a: a};
};
var $author$project$Type$Image$UploadImage = F3(
	function (fileName, filePath, dataUrl) {
		return {ba: dataUrl, aa: fileName, bf: filePath};
	});
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$file$File$name = _File_name;
var $elm$file$File$toUrl = _File_toUrl;
var $author$project$Modal$ImageLibrary$Failure = {$: 2};
var $author$project$Modal$ImageLibrary$UploadSuccess = function (a) {
	return {$: 7, a: a};
};
var $author$project$Modal$ImageLibrary$Uploading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$ImageLibrary$updateProgress = F2(
	function (model, uploadProgress) {
		var _v0 = uploadProgress.aZ;
		switch (_v0) {
			case 'success':
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(
							$author$project$Modal$ImageLibrary$UploadSuccess(uploadProgress.bo))));
			case 'uploading':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aZ: $author$project$Modal$ImageLibrary$Uploading(uploadProgress.c6)
						}),
					$elm$core$Platform$Cmd$none);
			case 'error':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aZ: $author$project$Modal$ImageLibrary$Failure}),
					$elm$core$Platform$Cmd$none);
			case 'previewing':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aZ: $author$project$Modal$ImageLibrary$Previewing}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aZ: $author$project$Modal$ImageLibrary$Default}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Firebase$uploadImage = _Platform_outgoingPort('uploadImage', $elm$core$Basics$identity);
var $author$project$Type$Image$uploadImageEncoder = function (image) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'filePath',
				$elm$json$Json$Encode$string(image.bf)),
				_Utils_Tuple2(
				'dataUrl',
				$elm$json$Json$Encode$string(image.ba)),
				_Utils_Tuple2(
				'fileName',
				$elm$json$Json$Encode$string(image.aa))
			]));
};
var $author$project$Type$Image$UploadProgress = F3(
	function (status, imageUrl, progress) {
		return {bo: imageUrl, c6: progress, aZ: status};
	});
var $author$project$Type$Image$uploadProgressDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'progress',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'imageUrl',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Type$Image$UploadProgress))));
var $author$project$Modal$ImageLibrary$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 8:
				var data = msg.a;
				var _v1 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Image$uploadProgressDecoder, data);
				if (!_v1.$) {
					var imageProgress = _v1.a;
					return A2($author$project$Modal$ImageLibrary$updateProgress, model, imageProgress);
				} else {
					var error = _v1.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['image/png', 'image/jpeg', 'image/gif']),
						$author$project$Modal$ImageLibrary$GotFile));
			case 3:
				var file = msg.a;
				var fileUrl = $elm$file$File$toUrl(file);
				var fileName = $elm$file$File$name(file);
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Modal$ImageLibrary$GotImageData(fileName),
						fileUrl));
			case 4:
				var fileName = msg.a;
				var data = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aa: fileName,
							Q: $elm$core$Maybe$Just(data)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var previewStr = msg.a;
				var newFileName = A2(
					$elm$core$String$join,
					'_',
					_List_fromArray(
						[model.aH, model.aa]));
				var path = A2(
					$elm$core$String$join,
					'/',
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								['content']),
								model.aU,
								_List_fromArray(
								[newFileName])
							])));
				var imageToUpload = A3($author$project$Type$Image$UploadImage, model.aa, path, previewStr);
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$uploadImage(
						$author$project$Type$Image$uploadImageEncoder(imageToUpload)));
			case 7:
				var imageUrl = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Q: $elm$core$Maybe$Just(imageUrl),
							aZ: $author$project$Modal$ImageLibrary$Success(imageUrl)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$ItemEdit$updateImageLibraryMsg = F4(
	function (model, imageKind, subModel, subMsg) {
		switch (subMsg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					$author$project$LocalService$setOverflow('auto'));
			case 7:
				var imageUrl = subMsg.a;
				var item = model.ao;
				var content = item.N;
				var newContent = _Utils_update(
					content,
					{
						aO: $elm$core$Maybe$Just(imageUrl)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ao: _Utils_update(
								item,
								{N: newContent}),
							v: $elm$core$Maybe$Nothing
						}),
					$author$project$LocalService$setOverflow('auto'));
			case 5:
				var _v2 = A2(
					$author$project$Modal$ImageLibrary$update,
					subMsg,
					_Utils_update(
						subModel,
						{aH: model.aH}));
				var newSubModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$ItemEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$ImageLibraryMsg, subCmd));
			default:
				var _v3 = A2($author$project$Modal$ImageLibrary$update, subMsg, subModel);
				var newSubModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$ItemEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$ImageLibraryMsg, subCmd));
		}
	});
var $author$project$Firebase$updateItem = _Platform_outgoingPort('updateItem', $elm$core$Basics$identity);
var $author$project$Modal$VariantEdit$ImageLibrary = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Modal$VariantEdit$imageKindToString = function (kind) {
	if (!kind) {
		return 'Variant';
	} else {
		return 'Background';
	}
};
var $author$project$Modal$VariantEdit$updateImageLibraryMsg = F4(
	function (model, imageKind, subModel, subMsg) {
		switch (subMsg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var imageUrl = subMsg.a;
				var variant = model.t;
				if (!imageKind) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								v: $elm$core$Maybe$Nothing,
								t: _Utils_update(
									variant,
									{bo: imageUrl})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var content = variant.N;
					var newContent = _Utils_update(
						content,
						{
							bu: $elm$core$Maybe$Just(imageUrl)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								v: $elm$core$Maybe$Nothing,
								t: _Utils_update(
									variant,
									{N: newContent})
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var _v2 = A2(
					$author$project$Modal$ImageLibrary$update,
					subMsg,
					_Utils_update(
						subModel,
						{aH: model.aH}));
				var newSubModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Modal$VariantEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Modal$VariantEdit$ImageLibraryMsg, subCmd));
			default:
				var _v3 = A2($author$project$Modal$ImageLibrary$update, subMsg, subModel);
				var newSubModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Modal$VariantEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Modal$VariantEdit$ImageLibraryMsg, subCmd));
		}
	});
var $author$project$Modal$VariantEdit$update = F2(
	function (msg, model) {
		var variant = model.t;
		var updatePack = function (newVariant) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{t: newVariant}),
				$elm$core$Platform$Cmd$none);
		};
		switch (msg.$) {
			case 8:
				var which = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: which}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var field = msg.a;
				var data = msg.b;
				switch (field) {
					case 0:
						return updatePack(
							_Utils_update(
								variant,
								{b$: data}));
					case 1:
						return updatePack(
							_Utils_update(
								variant,
								{
									aF: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(data))
								}));
					case 2:
						return updatePack(
							_Utils_update(
								variant,
								{
									aV: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toFloat(data))
								}));
					case 3:
						return updatePack(
							_Utils_update(
								variant,
								{
									aC: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(data))
								}));
					default:
						var content = model.t.N;
						return updatePack(
							_Utils_update(
								variant,
								{
									N: _Utils_update(
										content,
										{aA: data})
								}));
				}
			case 5:
				var imageKind = msg.a;
				var pathList = _List_fromArray(
					['sets', model.ao.dd]);
				var imageKindStr = $author$project$Modal$VariantEdit$imageKindToString(imageKind);
				var _v2 = A4($author$project$Modal$ImageLibrary$init, model.db, pathList, model.ao.b$, imageKindStr);
				var modalModel = _v2.a;
				var subCmd = _v2.b;
				var newModalModel = _Utils_update(
					modalModel,
					{aH: model.aH});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Modal$VariantEdit$ImageLibrary, imageKind, newModalModel))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var subMsg = msg.a;
				var _v3 = model.v;
				if (!_v3.$) {
					var _v4 = _v3.a;
					var imageKind = _v4.a;
					var subModel = _v4.b;
					return A4($author$project$Modal$VariantEdit$updateImageLibraryMsg, model, imageKind, subModel, subMsg);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Firebase$updateVariantInSet = _Platform_outgoingPort('updateVariantInSet', $elm$core$Basics$identity);
var $author$project$Type$Variant$toItemId = function (variant) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2(
				$elm$core$List$drop,
				3,
				A2($elm$core$String$split, '/', variant.aT))));
};
var $author$project$Type$Variant$toSetId = function (variant) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2(
				$elm$core$List$drop,
				1,
				A2($elm$core$String$split, '/', variant.aT))));
};
var $author$project$Type$Variant$updateVariantEncoder = function (_var) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'batchSize',
				$elm$json$Json$Encode$int(_var.aC)),
				_Utils_Tuple2(
				'content',
				$author$project$Type$Variant$contentEncoder(_var.N)),
				_Utils_Tuple2(
				'countMax',
				$elm$json$Json$Encode$int(_var.aF)),
				_Utils_Tuple2(
				'imageUrl',
				$elm$json$Json$Encode$string(_var.bo)),
				_Utils_Tuple2(
				'rarityFactor',
				$elm$json$Json$Encode$float(_var.aV)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(_var.b$))
			]));
};
var $author$project$Type$CSet$variantSummaryEncoder = function (_var) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'imageUrl',
				$elm$json$Json$Encode$string(_var.bo)),
				_Utils_Tuple2(
				'rarityFactor',
				$elm$json$Json$Encode$float(_var.aV)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(_var.b$)),
				_Utils_Tuple2(
				'variantTemplateRef',
				$elm$json$Json$Encode$string(_var.b4))
			]));
};
var $author$project$Page$ItemEdit$updateVariantInSetEncoder = F2(
	function (model, subModel) {
		var variantPath = subModel.t.aT;
		var updateVariantSummary = function (variantSummary) {
			return _Utils_eq(variantSummary.b$, subModel.b$) ? _Utils_update(
				variantSummary,
				{aV: subModel.t.aV, b$: subModel.t.b$}) : variantSummary;
		};
		var otherVariantPaths = A2(
			$elm$core$List$map,
			function (v) {
				return v.aT;
			},
			A2(
				$elm$core$List$filter,
				function (v) {
					return _Utils_eq(v.b$, subModel.b$);
				},
				A2(
					$elm$core$List$filter,
					function (v) {
						return _Utils_eq(
							$author$project$Type$Variant$toSetId(v),
							model.dd) && (!_Utils_eq(
							$author$project$Type$Variant$toItemId(v),
							model.bn));
					},
					$elm$core$Dict$values(model.$7))));
		var newVariantSummaries = A2($elm$core$List$map, updateVariantSummary, model.bT.dp);
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'setId',
					$elm$json$Json$Encode$string(model.bT.bn)),
					_Utils_Tuple2(
					'variant',
					$author$project$Type$Variant$updateVariantEncoder(subModel.t)),
					_Utils_Tuple2(
					'variantPath',
					$elm$json$Json$Encode$string(variantPath)),
					_Utils_Tuple2(
					'variantSummaries',
					$elm$json$Json$Encode$list($author$project$Type$CSet$variantSummaryEncoder)(newVariantSummaries)),
					_Utils_Tuple2(
					'otherVariantPaths',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$string)(otherVariantPaths))
				]));
	});
var $author$project$Firebase$updateVariantOnly = _Platform_outgoingPort('updateVariantOnly', $elm$core$Basics$identity);
var $author$project$Page$ItemEdit$updateVariantOnlyEncoder = F2(
	function (model, subModel) {
		var variantPath = subModel.t.aT;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'variant',
					$author$project$Type$Variant$updateVariantEncoder(subModel.t)),
					_Utils_Tuple2(
					'variantPath',
					$elm$json$Json$Encode$string(variantPath))
				]));
	});
var $author$project$Page$ItemEdit$updateVariantEditMsg = F3(
	function (model, subModel, subMsg) {
		switch (subMsg.$) {
			case 5:
				var _v1 = A2($author$project$Modal$VariantEdit$update, subMsg, subModel);
				var newSubModel = _v1.a;
				var subCmd = _v1.b;
				var newSubModel2 = _Utils_update(
					newSubModel,
					{aH: model.aH});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$ItemEdit$VariantEdit(newSubModel2))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$VariantEditMsg, subCmd));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					$author$project$LocalService$setOverflow('auto'));
			case 0:
				var cmdMsg = subModel.af ? $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							$author$project$LocalService$setOverflow('auto'),
							$author$project$Firebase$updateVariantInSet(
							A2($author$project$Page$ItemEdit$updateVariantInSetEncoder, model, subModel))
						])) : $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							$author$project$LocalService$setOverflow('auto'),
							$author$project$Firebase$updateVariantOnly(
							A2($author$project$Page$ItemEdit$updateVariantOnlyEncoder, model, subModel))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					cmdMsg);
			default:
				var _v2 = A2($author$project$Modal$VariantEdit$update, subMsg, subModel);
				var newSubModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$ItemEdit$VariantEdit(newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$VariantEditMsg, subCmd));
		}
	});
var $author$project$Page$ItemEdit$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				var imageKind = msg.a;
				var pathList = _List_fromArray(
					['sets', model.dd]);
				var imageKindStr = $author$project$Page$ItemEdit$imageKindToString(imageKind);
				var _v1 = A4($author$project$Modal$ImageLibrary$init, model.db, pathList, model.ao.b$, imageKindStr);
				var modalModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$ItemEdit$ImageLibrary, imageKind, modalModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$ImageLibraryMsg, subCmd)
							])));
			case 12:
				var variant = msg.a;
				var _v2 = A4($author$project$Modal$VariantEdit$init, model.db, model.bT, model.ao, variant);
				var subModel = _v2.a;
				var subCmdMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$ItemEdit$VariantEdit(subModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$ItemEdit$VariantEditMsg, subCmdMsg)
							])));
			case 5:
				var imageKind = msg.a;
				var item = model.ao;
				var content = item.N;
				var newContent = _Utils_update(
					content,
					{aO: $elm$core$Maybe$Nothing});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ao: _Utils_update(
								item,
								{N: newContent})
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var subMsg = msg.a;
				var _v4 = model.v;
				if ((!_v4.$) && (!_v4.a.$)) {
					var _v5 = _v4.a;
					var imageKind = _v5.a;
					var subModel = _v5.b;
					return A4($author$project$Page$ItemEdit$updateImageLibraryMsg, model, imageKind, subModel, subMsg);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var subMsg = msg.a;
				var _v6 = model.v;
				if ((!_v6.$) && (_v6.a.$ === 1)) {
					var subModel = _v6.a.a;
					return A3($author$project$Page$ItemEdit$updateVariantEditMsg, model, subModel, subMsg);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var field = msg.a;
				var val = msg.b;
				var updateItemField = function (newItem) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ao: newItem}),
						$elm$core$Platform$Cmd$none);
				};
				var item = model.ao;
				switch (field) {
					case 0:
						return updateItemField(
							_Utils_update(
								item,
								{b$: val}));
					case 1:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{I: val}),
							$elm$core$Platform$Cmd$none);
					case 2:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{Y: val}),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{G: val}),
							$elm$core$Platform$Cmd$none);
				}
			case 6:
				var item = model.ao;
				var tags = item.ag;
				var newTags = _Utils_ap(
					tags,
					_List_fromArray(
						[
							$elm$core$String$toLower(model.Y)
						]));
				var newItem = _Utils_update(
					item,
					{ag: newTags});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ao: newItem, Y: ''}),
					A2(
						$elm$core$Task$attempt,
						function (_v8) {
							return $author$project$Page$ItemEdit$NoOp;
						},
						$elm$browser$Browser$Dom$focus('tagInput')));
			case 7:
				var title = msg.a;
				var item = model.ao;
				var newTags = A2(
					$elm$core$List$filter,
					function (x) {
						return !_Utils_eq(x, title);
					},
					item.ag);
				var newItem = _Utils_update(
					item,
					{ag: newTags});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ao: newItem}),
					A2(
						$elm$core$Task$attempt,
						function (_v9) {
							return $author$project$Page$ItemEdit$NoOp;
						},
						$elm$browser$Browser$Dom$focus('tagInput')));
			case 8:
				var ratio = A2(
					$elm$core$Maybe$withDefault,
					1,
					$elm$core$String$toFloat(model.I));
				var item = model.ao;
				var cornerRadius = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toFloat(model.G));
				var content = item.N;
				var newContent = _Utils_update(
					content,
					{G: cornerRadius});
				var newItem = _Utils_update(
					item,
					{N: newContent, I: ratio});
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$updateItem(
						$author$project$Type$Item$itemEncoder(newItem)));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updatePassThruItemEditMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$ItemEdit$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$ItemEditPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$ItemEditMsg, subCmd));
	});
var $danyx23$elm_uuid$Uuid$Uuid = $elm$core$Basics$identity;
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $danyx23$elm_uuid$Uuid$Barebones$hexGenerator = A2($elm$random$Random$int, 0, 15);
var $elm$random$Random$listHelp = F4(
	function (revList, n, gen, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(revList, seed);
			} else {
				var _v0 = gen(seed);
				var value = _v0.a;
				var newSeed = _v0.b;
				var $temp$revList = A2($elm$core$List$cons, value, revList),
					$temp$n = n - 1,
					$temp$gen = gen,
					$temp$seed = newSeed;
				revList = $temp$revList;
				n = $temp$n;
				gen = $temp$gen;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var $elm$random$Random$list = F2(
	function (n, _v0) {
		var gen = _v0;
		return function (seed) {
			return A4($elm$random$Random$listHelp, _List_Nil, n, gen, seed);
		};
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Bitwise$or = _Bitwise_or;
var $danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB = function (digit) {
	return (digit & 3) | 8;
};
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{j: nodeList, f: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $danyx23$elm_uuid$Uuid$Barebones$hexDigits = function () {
	var mapChars = F2(
		function (offset, digit) {
			return $elm$core$Char$fromCode(digit + offset);
		});
	return $elm$core$Array$fromList(
		_Utils_ap(
			A2(
				$elm$core$List$map,
				mapChars(48),
				A2($elm$core$List$range, 0, 9)),
			A2(
				$elm$core$List$map,
				mapChars(97),
				A2($elm$core$List$range, 0, 5))));
}();
var $danyx23$elm_uuid$Uuid$Barebones$mapToHex = function (index) {
	var maybeResult = A2($elm$core$Array$get, index, $danyx23$elm_uuid$Uuid$Barebones$hexDigits);
	if (maybeResult.$ === 1) {
		return 'x';
	} else {
		var result = maybeResult.a;
		return result;
	}
};
var $danyx23$elm_uuid$Uuid$Barebones$toUuidString = function (thirtyOneHexDigits) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2($elm$core$List$take, 8, thirtyOneHexDigits))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						4,
						A2($elm$core$List$drop, 8, thirtyOneHexDigits)))),
				'-',
				'4',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 12, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$map,
						$danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB,
						A2(
							$elm$core$List$take,
							1,
							A2($elm$core$List$drop, 15, thirtyOneHexDigits))))),
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 16, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						12,
						A2($elm$core$List$drop, 19, thirtyOneHexDigits))))
			]));
};
var $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator = A2(
	$elm$random$Random$map,
	$danyx23$elm_uuid$Uuid$Barebones$toUuidString,
	A2($elm$random$Random$list, 31, $danyx23$elm_uuid$Uuid$Barebones$hexGenerator));
var $danyx23$elm_uuid$Uuid$uuidGenerator = A2($elm$random$Random$map, $elm$core$Basics$identity, $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator);
var $author$project$Update$updateItemEditMsg = F3(
	function (model, subMsg, subModel) {
		switch (subMsg.$) {
			case 3:
				var subModalMsg = subMsg.a;
				if (subModalMsg.$ === 5) {
					var _v2 = A2($elm$random$Random$step, $danyx23$elm_uuid$Uuid$uuidGenerator, model.a9);
					var newUuid = _v2.a;
					var newSeed = _v2.b;
					var _v3 = A2(
						$author$project$Page$ItemEdit$update,
						subMsg,
						_Utils_update(
							subModel,
							{
								aH: $danyx23$elm_uuid$Uuid$toString(newUuid)
							}));
					var newSubModel = _v3.a;
					var subCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a9: newSeed,
								c3: $author$project$Type$Page$ItemEditPage(newSubModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Msg$ItemEditMsg, subCmd));
				} else {
					return A3($author$project$Update$updatePassThruItemEditMsg, model, subMsg, subModel);
				}
			case 2:
				var subModalMsg = subMsg.a;
				if (subModalMsg.$ === 5) {
					var _v5 = A2($elm$random$Random$step, $danyx23$elm_uuid$Uuid$uuidGenerator, model.a9);
					var newUuid = _v5.a;
					var newSeed = _v5.b;
					var _v6 = A2(
						$author$project$Page$ItemEdit$update,
						subMsg,
						_Utils_update(
							subModel,
							{
								aH: $danyx23$elm_uuid$Uuid$toString(newUuid)
							}));
					var newSubModel = _v6.a;
					var subCmd = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a9: newSeed,
								c3: $author$project$Type$Page$ItemEditPage(newSubModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Msg$ItemEditMsg, subCmd));
				} else {
					return A3($author$project$Update$updatePassThruItemEditMsg, model, subMsg, subModel);
				}
			default:
				return A3($author$project$Update$updatePassThruItemEditMsg, model, subMsg, subModel);
		}
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.bE;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.bh,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.bF,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.bA,
					_Utils_ap(http, url.bm)),
				url.aT)));
};
var $author$project$Update$updateLinkClicked = F2(
	function (urlRequest, model) {
		var navKey = $author$project$Type$Session$navKey(model.db);
		if (!urlRequest.$) {
			var url = urlRequest.a;
			return _Utils_Tuple2(
				model,
				A2(
					$elm$browser$Browser$Navigation$pushUrl,
					navKey,
					$elm$url$Url$toString(url)));
		} else {
			var href = urlRequest.a;
			return _Utils_Tuple2(
				model,
				$elm$browser$Browser$Navigation$load(href));
		}
	});
var $author$project$Type$Pack$packEncoder = function (pack) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(pack.bn)),
				_Utils_Tuple2(
				'bundleId',
				$elm$json$Json$Encode$string(pack.aE)),
				_Utils_Tuple2(
				'count',
				$elm$json$Json$Encode$int(pack.a8)),
				_Utils_Tuple2(
				'dateCreated',
				$elm$json$Json$Encode$int(pack.ck)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(pack.cl)),
				_Utils_Tuple2(
				'itemRefs',
				A2($elm$json$Json$Encode$list, $author$project$Type$DocRef$docRefEncoder, pack.cH)),
				_Utils_Tuple2(
				'oddsDescription',
				$elm$json$Json$Encode$string(pack.by)),
				_Utils_Tuple2(
				'setRefs',
				A2($elm$json$Json$Encode$list, $author$project$Type$DocRef$docRefEncoder, pack.de)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(pack.aZ)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(pack.b$))
			]));
};
var $author$project$Page$PackEdit$updateModalMsg = F3(
	function (model, subMsg, subModel) {
		var _v1 = A2($author$project$Modal$ImageLibrary$update, subMsg, subModel);
		var newSubModel = _v1.a;
		var subCmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: $elm$core$Maybe$Just(
						$author$project$Page$PackEdit$ImageLibrary(newSubModel))
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$PackEdit$ImageLibraryMsg, subCmd));
	});
var $author$project$Firebase$updatePack = _Platform_outgoingPort('updatePack', $elm$core$Basics$identity);
var $author$project$Page$PackEdit$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var pathList = _List_fromArray(
					['packs', model.bn]);
				var _v1 = A4($author$project$Modal$ImageLibrary$init, model.db, pathList, model.ae.b$, 'Pack Image');
				var modalModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$PackEdit$ImageLibrary(modalModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$PackEdit$ImageLibraryMsg, subCmd)
							])));
			case 2:
				var subMsg = msg.a;
				var _v2 = model.v;
				if (!_v2.$) {
					if (!_v2.a.$) {
						var modalModel = _v2.a.a;
						return A3($author$project$Page$PackEdit$updateModalMsg, model, subMsg, modalModel);
					} else {
						var _v3 = _v2.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var panel = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{w: panel}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var field = msg.a;
				var text = msg.b;
				var updateItemField = function (newPack) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ae: newPack}),
						$elm$core$Platform$Cmd$none);
				};
				var pack = model.ae;
				switch (field) {
					case 0:
						return updateItemField(
							_Utils_update(
								pack,
								{b$: text}));
					case 2:
						return updateItemField(
							_Utils_update(
								pack,
								{cl: text}));
					case 3:
						return updateItemField(
							_Utils_update(
								pack,
								{by: text}));
					default:
						return updateItemField(
							_Utils_update(
								pack,
								{
									a8: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(text))
								}));
				}
			case 8:
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$updatePack(
						$author$project$Type$Pack$packEncoder(model.ae)));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updatePassThruPackEditMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$PackEdit$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$PackEditPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$PackEditMsg, subCmd));
	});
var $author$project$Update$updatePackEditMsg = F3(
	function (model, subMsg, subModel) {
		if (subMsg.$ === 2) {
			var subModalMsg = subMsg.a;
			if (subModalMsg.$ === 5) {
				var _v2 = A2($elm$random$Random$step, $danyx23$elm_uuid$Uuid$uuidGenerator, model.a9);
				var newUuid = _v2.a;
				var newSeed = _v2.b;
				var _v3 = A2(
					$author$project$Page$PackEdit$update,
					subMsg,
					_Utils_update(
						subModel,
						{
							aH: $danyx23$elm_uuid$Uuid$toString(newUuid)
						}));
				var newSubModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a9: newSeed,
							c3: $author$project$Type$Page$PackEditPage(newSubModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$PackEditMsg, subCmd));
			} else {
				return A3($author$project$Update$updatePassThruPackEditMsg, model, subMsg, subModel);
			}
		} else {
			return A3($author$project$Update$updatePassThruPackEditMsg, model, subMsg, subModel);
		}
	});
var $author$project$Msg$PageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Update$updatePageMsg = F2(
	function (model, subMsg) {
		var _v0 = function () {
			if (!subMsg.$) {
				var index = subMsg.a;
				var newAlerts = A2($elm_community$list_extra$List$Extra$removeAt, index, model.cb);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cb: newAlerts}),
					$elm$core$Platform$Cmd$none);
			} else {
				var _v2 = A2($author$project$Page$update, subMsg, model.c4);
				var newPageModel = _v2.a;
				var newPageSubMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c4: newPageModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$PageMsg, newPageSubMsg));
			}
		}();
		var newModel = _v0.a;
		var newSubMsg = _v0.b;
		return _Utils_Tuple2(newModel, newSubMsg);
	});
var $author$project$Type$Session$updateProfile = F2(
	function (session, profile) {
		if (session.$ === 3) {
			var par = session.a;
			var user = session.b;
			return A2(
				$author$project$Type$Session$SignedIn,
				par,
				_Utils_update(
					user,
					{
						cP: $elm$core$Maybe$Just(profile)
					}));
		} else {
			return session;
		}
	});
var $author$project$Update$updateProfileChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$User$profileDecoder, data);
		if (!_v0.$) {
			var profile = _v0.a;
			var newSession = A2($author$project$Type$Session$updateProfile, model.db, profile);
			var newModel = _Utils_update(
				model,
				{
					cP: $elm$core$Maybe$Just(profile),
					db: newSession
				});
			var updateProfileInPage = F2(
				function (page, newSubModel) {
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: page(newSubModel)
							}),
						$elm$core$Platform$Cmd$none);
				});
			var _v1 = model.c3;
			switch (_v1.$) {
				case 0:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$HomePage,
						_Utils_update(
							subModel,
							{db: newSession}));
				case 2:
					var subModel = _v1.a;
					return A2(
						updateProfileInPage,
						$author$project$Type$Page$ProfilePage,
						_Utils_update(
							subModel,
							{
								cP: $elm$core$Maybe$Just(profile),
								db: newSession
							}));
				default:
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.cY);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cb: _List_fromArray(
							[alert]),
						cP: $elm$core$Maybe$Nothing
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Profile$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Profile$updatePageMsg = F2(
	function (childMsg, model) {
		var _v0 = A2($author$project$Page$update, childMsg, model.c4);
		var pageModel = _v0.a;
		var pageCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{c4: pageModel}),
			A2($elm$core$Platform$Cmd$map, $author$project$Page$Profile$PageMsg, pageCmd));
	});
var $author$project$Page$Profile$update = F2(
	function (msg, model) {
		if (msg.$ === 1) {
			var subMsg = msg.a;
			return A2($author$project$Page$Profile$updatePageMsg, subMsg, model);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateProfileMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$Profile$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$ProfilePage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$ProfileMsg, subCmd));
	});
var $author$project$Update$updateRemoveAnnouncement = F2(
	function (model, id) {
		var newAnnouncements = A2(
			$elm$core$List$filter,
			function (x) {
				return !_Utils_eq(x.bn, id);
			},
			model.cd);
		var newModel = _Utils_update(
			model,
			{cd: newAnnouncements});
		var _v0 = model.c3;
		if (_v0.$ === 3) {
			var subModel = _v0.a;
			var newSubModel = _Utils_update(
				subModel,
				{cd: newAnnouncements});
			return _Utils_Tuple2(
				_Utils_update(
					newModel,
					{
						c3: $author$project$Type$Page$AnnouncementPage(newSubModel)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateRemoveItem = F2(
	function (model, id) {
		var newItemDict = A2($elm$core$Dict$remove, id, model.cF);
		var newModel = _Utils_update(
			model,
			{cF: newItemDict});
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Type$CSet$CSet = function (id) {
	return function (batchSize) {
		return function (countMax) {
			return function (datePublished) {
				return function (description) {
					return function (editionType) {
						return function (imageUrl) {
							return function (itemCount) {
								return function (itemTemplate) {
									return function (maxBatches) {
										return function (status) {
											return function (tags) {
												return function (title) {
													return function (variantSummaries) {
														return {aC: batchSize, aF: countMax, aI: datePublished, cl: description, aK: editionType, bn: id, bo: imageUrl, cE: itemCount, cI: itemTemplate, ap: maxBatches, aZ: status, ag: tags, b$: title, dp: variantSummaries};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$CSet$VariantSummary = F4(
	function (imageUrl, rarityFactor, title, variantTemplateRef) {
		return {bo: imageUrl, aV: rarityFactor, b$: title, b4: variantTemplateRef};
	});
var $author$project$Type$CSet$variantSummaryDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variantTemplateRef',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'rarityFactor',
			$elm$json$Json$Decode$float,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'imageUrl',
				$elm$json$Json$Decode$string,
				'',
				$elm$json$Json$Decode$succeed($author$project$Type$CSet$VariantSummary)))));
var $author$project$Type$CSet$csetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'variantSummaries',
	$elm$json$Json$Decode$list($author$project$Type$CSet$variantSummaryDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'maxBatches',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'itemTemplate',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'itemCount',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'imageUrl',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'editionType',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'description',
										$elm$json$Json$Decode$string,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'datePublished',
											$elm$json$Json$Decode$int,
											0,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'countMax',
												$elm$json$Json$Decode$int,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'batchSize',
													$elm$json$Json$Decode$int,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'id',
														$elm$json$Json$Decode$string,
														$elm$json$Json$Decode$succeed($author$project$Type$CSet$CSet)))))))))))))));
var $author$project$Page$SetEdit$updateSet = F2(
	function (set, model) {
		return _Utils_update(
			model,
			{bT: set, b$: set.b$});
	});
var $author$project$Update$updateSetChanged = F2(
	function (model, data) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Type$CSet$csetDecoder, data);
		if (!_v0.$) {
			var set = _v0.a;
			var newSetDict = A3($elm$core$Dict$insert, set.bn, set, model.dc);
			var newModel = _Utils_update(
				model,
				{dc: newSetDict});
			var newCmd = $elm$core$Platform$Cmd$none;
			var _v1 = model.c3;
			switch (_v1.$) {
				case 4:
					var subModel = _v1.a;
					var newSubModel = _Utils_update(
						subModel,
						{dc: newSetDict});
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$SetListPage(newSubModel)
							}),
						newCmd);
				case 5:
					var submodel = _v1.a;
					var newSubModel = _Utils_eq(set.bn, submodel.bn) ? A2($author$project$Page$SetEdit$updateSet, set, submodel) : submodel;
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$SetEditPage(newSubModel)
							}),
						newCmd);
				case 6:
					var submodel = _v1.a;
					var newSubModel = _Utils_eq(set.bn, submodel.dd) ? _Utils_update(
						submodel,
						{bT: set}) : submodel;
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$ItemEditPage(newSubModel)
							}),
						newCmd);
				case 7:
					var submodel = _v1.a;
					var newSubModel = _Utils_eq(set.bn, submodel.dd) ? _Utils_update(
						submodel,
						{bT: set}) : submodel;
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{
								c3: $author$project$Type$Page$PackEditPage(newSubModel)
							}),
						newCmd);
				default:
					return _Utils_Tuple2(newModel, newCmd);
			}
		} else {
			var error = _v0.a;
			var msg = $elm$json$Json$Decode$errorToString(error);
			var alert = A4($author$project$Type$Alert$Alert, 'Error', msg, '', model.cY);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cb: _List_fromArray(
							[alert]),
						dc: model.dc
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$SetEdit$NoOp = {$: 0};
var $author$project$Page$SetEdit$Pack = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$SetEdit$PackModalMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Firebase$createPrints = _Platform_outgoingPort('createPrints', $elm$core$Basics$identity);
var $author$project$Page$SetEdit$createPrintEncoder = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'variantPath',
				$elm$json$Json$Encode$string(data.a_)),
				_Utils_Tuple2(
				'appId',
				$elm$json$Json$Encode$string(data.al)),
				_Utils_Tuple2(
				'template',
				$elm$json$Json$Encode$int(data.ah)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(data.b$)),
				_Utils_Tuple2(
				'ratio',
				$elm$json$Json$Encode$float(data.I))
			]));
};
var $author$project$Page$SetEdit$createSetPrintsEncoder = F2(
	function (appId, data) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'variants',
					$elm$json$Json$Encode$list($author$project$Page$SetEdit$createPrintEncoder)(data)),
					_Utils_Tuple2(
					'appId',
					$elm$json$Json$Encode$string(appId))
				]));
	});
var $author$project$Type$CSet$csetDetailsEncoder = F2(
	function (itemIds, set) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(set.bn)),
					_Utils_Tuple2(
					'batchSize',
					$elm$json$Json$Encode$int(set.aC)),
					_Utils_Tuple2(
					'countMax',
					$elm$json$Json$Encode$int(set.aF)),
					_Utils_Tuple2(
					'datePublished',
					$elm$json$Json$Encode$int(set.aI)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(set.cl)),
					_Utils_Tuple2(
					'editionType',
					$elm$json$Json$Encode$int(set.aK)),
					_Utils_Tuple2(
					'imageUrl',
					$elm$json$Json$Encode$string(set.bo)),
					_Utils_Tuple2(
					'itemCount',
					$elm$json$Json$Encode$int(set.cE)),
					_Utils_Tuple2(
					'itemTemplate',
					$elm$json$Json$Encode$int(set.cI)),
					_Utils_Tuple2(
					'maxBatches',
					$elm$json$Json$Encode$int(set.ap)),
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$int(set.aZ)),
					_Utils_Tuple2(
					'tags',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, set.ag)),
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string(set.b$)),
					_Utils_Tuple2(
					'itemIds',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, itemIds)),
					_Utils_Tuple2(
					'variantSummaries',
					$elm$json$Json$Encode$list($author$project$Type$CSet$variantSummaryEncoder)(set.dp))
				]));
	});
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Type$DocRef$fromList = function (pathList) {
	var _v0 = $elm_community$list_extra$List$Extra$last(pathList);
	if (!_v0.$) {
		var id = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$author$project$Type$DocRef$DocRef,
				id,
				A2($elm$core$String$join, '/', pathList)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Modal$Pack$Editing = 0;
var $author$project$Modal$Pack$NoOp = {$: 3};
var $author$project$Modal$Pack$init = F2(
	function (session, pack) {
		return _Utils_Tuple2(
			{
				ae: pack,
				bP: $author$project$Type$Session$screenSize(session),
				db: session,
				aZ: 0,
				b$: '',
				az: false
			},
			A2(
				$elm$core$Task$attempt,
				function (_v0) {
					return $author$project$Modal$Pack$NoOp;
				},
				$elm$browser$Browser$Dom$focus('countField')));
	});
var $author$project$Page$SetEdit$DatePickerMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$SetEdit$passDatePickerMsg = F2(
	function (childMsg, model) {
		var _v0 = model.z;
		if (!_v0.$) {
			var datePickerModel = _v0.a;
			var _v1 = A2($author$project$Ox$DatePicker$update, childMsg, datePickerModel);
			var subModel = _v1.a;
			var pageCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						z: $elm$core$Maybe$Just(subModel)
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$DatePickerMsg, pageCmd));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $author$project$Page$SetEdit$updateImageLibraryMsg = F4(
	function (model, imageKind, subModel, subMsg) {
		var set = model.bT;
		switch (subMsg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					$author$project$LocalService$setOverflow('auto'));
			case 7:
				var imageUrl = subMsg.a;
				if (!imageKind.$) {
					var newSet = _Utils_update(
						set,
						{bo: imageUrl});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{v: $elm$core$Maybe$Nothing, bT: newSet}),
						$author$project$LocalService$setOverflow('auto'));
				} else {
					var title = imageKind.a;
					var index = imageKind.b;
					var variantSums = set.dp;
					var newVariantSums = A3(
						$elm_community$list_extra$List$Extra$updateAt,
						index,
						function (v) {
							return _Utils_update(
								v,
								{bo: imageUrl});
						},
						variantSums);
					var newSet = _Utils_update(
						set,
						{dp: newVariantSums});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{v: $elm$core$Maybe$Nothing, bT: newSet}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var _v2 = A2(
					$author$project$Modal$ImageLibrary$update,
					subMsg,
					_Utils_update(
						subModel,
						{aH: model.aH}));
				var newSubModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$SetEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$ImageLibraryMsg, subCmd));
			default:
				var _v3 = A2($author$project$Modal$ImageLibrary$update, subMsg, subModel);
				var newSubModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$SetEdit$ImageLibrary, imageKind, newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$ImageLibraryMsg, subCmd));
		}
	});
var $author$project$Modal$Pack$Creating = 1;
var $author$project$Firebase$createPack = _Platform_outgoingPort('createPack', $elm$core$Basics$identity);
var $author$project$Modal$Pack$update = F2(
	function (msg, model) {
		var updatePack = function (newPack) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ae: newPack}),
				$elm$core$Platform$Cmd$none);
		};
		var pack = model.ae;
		if (msg.$ === 2) {
			var field = msg.a;
			var data = msg.b;
			var number = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(data));
			return updatePack(
				_Utils_update(
					pack,
					{
						a8: number,
						cl: $elm$core$String$fromInt(number) + ' new randomly selected stickers for you.',
						b$: $elm$core$String$fromInt(number) + '-Pack'
					}));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$SetEdit$updatePackModalMsg = F3(
	function (model, subModel, subMsg) {
		switch (subMsg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing}),
					$author$project$LocalService$setOverflow('auto'));
			case 0:
				var newSubModel = _Utils_update(
					subModel,
					{aZ: 1});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$SetEdit$Pack(newSubModel))
						}),
					$author$project$Firebase$createPack(
						$author$project$Type$Pack$packEncoder(subModel.ae)));
			default:
				var _v1 = A2($author$project$Modal$Pack$update, subMsg, subModel);
				var newSubModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$SetEdit$Pack(newSubModel))
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$PackModalMsg, subCmd));
		}
	});
var $author$project$Firebase$updateSetDetails = _Platform_outgoingPort('updateSetDetails', $elm$core$Basics$identity);
var $author$project$Page$SetEdit$update = F2(
	function (msg, model) {
		var updateSetField = function (newSet) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{bT: newSet}),
				$elm$core$Platform$Cmd$none);
		};
		var set = model.bT;
		switch (msg.$) {
			case 20:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cY: $elm$time$Time$posixToMillis(time)
						}),
					$elm$core$Platform$Cmd$none);
			case 21:
				var appId = $author$project$Type$Session$appId(model.db);
				var toPrintData = function (_v1) {
					var item = _v1.a;
					var variant = _v1.b;
					var variantPath = A2(
						$elm$core$String$join,
						'/',
						_List_fromArray(
							['sets', item.dd, 'items', item.bn, 'variants', variant.bn]));
					return {al: appId, I: item.I, ah: item.ah, b$: item.b$, a_: variantPath};
				};
				var variantPrints = A2(
					$elm$core$List$map,
					toPrintData,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (i) {
								return A2(
									$elm$core$List$map,
									function (v) {
										return _Utils_Tuple2(i, v);
									},
									i.b5);
							},
							model.P)));
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$createPrints(
						A2($author$project$Page$SetEdit$createSetPrintsEncoder, appId, variantPrints)));
			case 13:
				var itemId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Type$Session$navKey(model.db),
						A2($author$project$Route$ItemEdit, model.bn, itemId)));
			case 14:
				var packId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Type$Session$navKey(model.db),
						A2($author$project$Route$PackEdit, model.bn, packId)));
			case 23:
				var field = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(field)
						}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var field = msg.a;
				return _Utils_eq(
					model.aM,
					$elm$core$Maybe$Just(field)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aM: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 22:
				var field = msg.a;
				var str = msg.b;
				switch (field) {
					case 0:
						return updateSetField(
							_Utils_update(
								set,
								{b$: str}));
					case 1:
						return updateSetField(
							_Utils_update(
								set,
								{cl: str}));
					case 4:
						return updateSetField(
							_Utils_update(
								set,
								{
									aF: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(str))
								}));
					case 2:
						return updateSetField(
							_Utils_update(
								set,
								{
									aC: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(str))
								}));
					case 3:
						return updateSetField(
							_Utils_update(
								set,
								{
									ap: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(str))
								}));
					default:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{Y: str}),
							$elm$core$Platform$Cmd$none);
				}
			case 11:
				var imageKind = msg.a;
				var pathList = _List_fromArray(
					['sets', model.bn]);
				var _v3 = function () {
					if (!imageKind.$) {
						return A4($author$project$Modal$ImageLibrary$init, model.db, pathList, model.bT.b$, 'Set Image');
					} else {
						var title = imageKind.a;
						return A4($author$project$Modal$ImageLibrary$init, model.db, pathList, title, 'Variant Summary Image');
					}
				}();
				var modalModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								A2($author$project$Page$SetEdit$ImageLibrary, imageKind, modalModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$ImageLibraryMsg, subCmd)
							])));
			case 17:
				var setRefs = function () {
					var _v6 = $author$project$Type$DocRef$fromList(
						_List_fromArray(
							['sets', model.bn]));
					if (!_v6.$) {
						var ref = _v6.a;
						return _List_fromArray(
							[ref]);
					} else {
						return _List_Nil;
					}
				}();
				var itemsRefs = A2(
					$elm$core$List$filterMap,
					function (y) {
						return y;
					},
					A2(
						$elm$core$List$map,
						function (x) {
							return $author$project$Type$DocRef$fromList(
								_List_fromArray(
									['sets', x.dd, 'items', x.bn]));
						},
						model.P));
				var pack = _Utils_update(
					$author$project$Type$Pack$emptyPack,
					{cH: itemsRefs, de: setRefs});
				var _v5 = A2($author$project$Modal$Pack$init, model.db, pack);
				var modalModel = _v5.a;
				var subCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: $elm$core$Maybe$Just(
								$author$project$Page$SetEdit$Pack(modalModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$LocalService$setOverflow('hidden'),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$SetEdit$PackModalMsg, subCmd)
							])));
			case 3:
				var itemIds = model.ay ? A2(
					$elm$core$List$map,
					function (item) {
						return item.bn;
					},
					model.P) : _List_Nil;
				return _Utils_Tuple2(
					model,
					$author$project$Firebase$updateSetDetails(
						A2($author$project$Type$CSet$csetDetailsEncoder, itemIds, model.bT)));
			case 4:
				var panel = msg.a;
				var route = function () {
					switch (panel) {
						case 1:
							return A2(
								$author$project$Route$SetEdit,
								model.bn,
								$elm$core$Maybe$Just('items'));
						case 2:
							return A2(
								$author$project$Route$SetEdit,
								model.bn,
								$elm$core$Maybe$Just('packs'));
						default:
							return A2($author$project$Route$SetEdit, model.bn, $elm$core$Maybe$Nothing);
					}
				}();
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Route$pushUrl,
						$author$project$Type$Session$navKey(model.db),
						route));
			case 5:
				var num = msg.a;
				return updateSetField(
					_Utils_update(
						set,
						{aK: num}));
			case 6:
				var tags = set.ag;
				var newTags = _Utils_ap(
					tags,
					_List_fromArray(
						[
							$elm$core$String$toLower(model.Y)
						]));
				var newSet = _Utils_update(
					set,
					{ag: newTags});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bT: newSet, Y: ''}),
					A2(
						$elm$core$Task$attempt,
						function (_v8) {
							return $author$project$Page$SetEdit$NoOp;
						},
						$elm$browser$Browser$Dom$focus('tagInput')));
			case 7:
				var title = msg.a;
				var newTags = A2(
					$elm$core$List$filter,
					function (x) {
						return !_Utils_eq(x, title);
					},
					set.ag);
				var newSet = _Utils_update(
					set,
					{ag: newTags});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bT: newSet}),
					A2(
						$elm$core$Task$attempt,
						function (_v9) {
							return $author$project$Page$SetEdit$NoOp;
						},
						$elm$browser$Browser$Dom$focus('tagInput')));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bT: _Utils_update(
								set,
								{bo: ''})
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var subMsg = msg.a;
				var _v10 = _Utils_Tuple2(model.v, subMsg);
				if ((!_v10.a.$) && (!_v10.a.a.$)) {
					var _v11 = _v10.a.a;
					var imageKind = _v11.a;
					var subModel = _v11.b;
					return A4($author$project$Page$SetEdit$updateImageLibraryMsg, model, imageKind, subModel, subMsg);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				var subMsg = msg.a;
				var _v12 = _Utils_Tuple2(model.v, subMsg);
				if ((!_v12.a.$) && (_v12.a.a.$ === 1)) {
					var subModel = _v12.a.a.a;
					return A3($author$project$Page$SetEdit$updatePackModalMsg, model, subModel, subMsg);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 18:
				var childMsg = msg.a;
				switch (childMsg.$) {
					case 7:
						var posix = childMsg.a;
						var newStatus = (!model.bT.aZ) ? 2 : model.bT.aZ;
						var newDate = $elm$time$Time$posixToMillis(posix);
						var newSet = _Utils_update(
							set,
							{aI: newDate, aZ: newStatus});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{z: $elm$core$Maybe$Nothing, cY: newDate, bT: newSet, ay: true}),
							$elm$core$Platform$Cmd$none);
					case 8:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{z: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					default:
						return A2($author$project$Page$SetEdit$passDatePickerMsg, childMsg, model);
				}
			case 19:
				var posixTime = $elm$time$Time$millisToPosix(model.cY);
				var _v14 = A2($author$project$Ox$DatePicker$init, model.db, posixTime);
				var subModel = _v14.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							z: $elm$core$Maybe$Just(subModel)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updatePassThruSetEditMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$SetEdit$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$SetEditPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetEditMsg, subCmd));
	});
var $author$project$Update$updateSetEditMsg = F3(
	function (model, subMsg, subModel) {
		if (subMsg.$ === 12) {
			var subModalMsg = subMsg.a;
			if (subModalMsg.$ === 5) {
				var _v2 = A2($elm$random$Random$step, $danyx23$elm_uuid$Uuid$uuidGenerator, model.a9);
				var newUuid = _v2.a;
				var newSeed = _v2.b;
				var _v3 = A2(
					$author$project$Page$SetEdit$update,
					subMsg,
					_Utils_update(
						subModel,
						{
							aH: $danyx23$elm_uuid$Uuid$toString(newUuid)
						}));
				var newSubModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a9: newSeed,
							c3: $author$project$Type$Page$SetEditPage(newSubModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetEditMsg, subCmd));
			} else {
				return A3($author$project$Update$updatePassThruSetEditMsg, model, subMsg, subModel);
			}
		} else {
			return A3($author$project$Update$updatePassThruSetEditMsg, model, subMsg, subModel);
		}
	});
var $author$project$Page$SetImport$Error = {$: 2};
var $author$project$Page$SetImport$GotCsvData = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$SetImport$GotFile = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$SetImport$Parsed = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Page$SetImport$addSectionNameTo = F2(
	function (line, acc) {
		var strippedLine = A3($elm$core$String$replace, ',', '', line);
		var firstItem = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$String$split, ',', line)));
		var currentSection = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2(
					$elm$core$String$split,
					',',
					A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							$elm$core$List$reverse(acc))))));
		var notEmptyLines = (!$elm$core$String$length(strippedLine)) ? _Utils_ap(
			acc,
			_List_fromArray(
				[line])) : _Utils_ap(
			acc,
			_List_fromArray(
				[
					_Utils_ap(currentSection, line)
				]));
		if (firstItem === '') {
			return notEmptyLines;
		} else {
			return _Utils_ap(
				acc,
				_List_fromArray(
					[line]));
		}
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Firebase$createSetAndItems = _Platform_outgoingPort('createSetAndItems', $elm$core$Basics$identity);
var $ericgj$elm_csv_decode$Csv$Decode$DecodeErrors = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $ericgj$elm_csv_decode$Csv$Decode$decodeRecord = F3(
	function (_v0, headers, record) {
		var decoder = _v0;
		return A2(
			$elm$core$Result$map,
			function ($) {
				return $.D;
			},
			decoder(
				{
					L: A3(
						$elm$core$List$map2,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						headers,
						record),
					D: $elm$core$Basics$identity,
					T: _List_Nil
				}));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $ericgj$elm_csv_decode$Csv$Decode$sequenceResultsAccumErrs = function (list) {
	var accum = F2(
		function (nxt, _v1) {
			var i = _v1.a;
			var result = _v1.b;
			var _v0 = _Utils_Tuple2(nxt, result);
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var b = _v0.a.a;
					var a = _v0.b.a;
					return _Utils_Tuple2(
						i - 1,
						$elm$core$Result$Ok(
							A2($elm$core$List$cons, b, a)));
				} else {
					var b = _v0.a.a;
					var a = _v0.b.a;
					return _Utils_Tuple2(
						i - 1,
						$elm$core$Result$Err(a));
				}
			} else {
				if (!_v0.b.$) {
					var b = _v0.a.a;
					var a = _v0.b.a;
					return _Utils_Tuple2(
						i - 1,
						$elm$core$Result$Err(
							_List_fromArray(
								[
									_Utils_Tuple2(i, b)
								])));
				} else {
					var b = _v0.a.a;
					var a = _v0.b.a;
					return _Utils_Tuple2(
						i - 1,
						$elm$core$Result$Err(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(i, b),
								a)));
				}
			}
		});
	return A3(
		$elm$core$List$foldr,
		accum,
		_Utils_Tuple2(
			$elm$core$List$length(list) - 1,
			$elm$core$Result$Ok(_List_Nil)),
		list).b;
};
var $ericgj$elm_csv_decode$Csv$Decode$decodeCsv = F2(
	function (decoder, _v0) {
		var headers = _v0.bk;
		var records = _v0.bG;
		return A2(
			$elm$core$Result$mapError,
			$ericgj$elm_csv_decode$Csv$Decode$DecodeErrors,
			$ericgj$elm_csv_decode$Csv$Decode$sequenceResultsAccumErrs(
				A2(
					$elm$core$List$map,
					A2($ericgj$elm_csv_decode$Csv$Decode$decodeRecord, decoder, headers),
					records)));
	});
var $author$project$Type$Import$Item = F7(
	function (itemNumber, title, tags, template, ratio, backImageName, cornerRadius) {
		return {a2: backImageName, G: cornerRadius, cG: itemNumber, I: ratio, ag: tags, ah: template, b$: title};
	});
var $ericgj$elm_csv_decode$Csv$Decode$Decoder = $elm$core$Basics$identity;
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $ericgj$elm_csv_decode$Csv$Decode$andMap = F2(
	function (_v0, _v1) {
		var decodeAfter = _v0;
		var decodeBefore = _v1;
		return function (state) {
			return A2(
				$elm$core$Result$andThen,
				decodeAfter,
				decodeBefore(state));
		};
	});
var $ericgj$elm_csv_decode$Csv$Decode$State = F3(
	function (visited, unvisited, value) {
		return {L: unvisited, D: value, T: visited};
	});
var $ericgj$elm_csv_decode$Csv$Decode$listFind = F2(
	function (pred, list) {
		listFind:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (pred(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$pred = pred,
						$temp$list = rest;
					pred = $temp$pred;
					list = $temp$list;
					continue listFind;
				}
			}
		}
	});
var $ericgj$elm_csv_decode$Csv$Decode$field = F2(
	function (name, fn) {
		return function (_v0) {
			var visited = _v0.T;
			var unvisited = _v0.L;
			var value = _v0.D;
			var _v1 = A2(
				$ericgj$elm_csv_decode$Csv$Decode$listFind,
				function (_v2) {
					var name_ = _v2.a;
					return _Utils_eq(name_, name);
				},
				unvisited);
			if (_v1.$ === 1) {
				return $elm$core$Result$Err('No field named \'' + (name + '\' found'));
			} else {
				var _v3 = _v1.a;
				var rawField = _v3.a;
				var rawValue = _v3.b;
				var _v4 = fn(rawValue);
				if (!_v4.$) {
					var nextValue = _v4.a;
					return $elm$core$Result$Ok(
						A3(
							$ericgj$elm_csv_decode$Csv$Decode$State,
							visited,
							unvisited,
							value(nextValue)));
				} else {
					var msg = _v4.a;
					return $elm$core$Result$Err(msg);
				}
			}
		};
	});
var $ericgj$elm_csv_decode$Csv$Decode$mapHelp = F2(
	function (fn, _v0) {
		var visited = _v0.T;
		var unvisited = _v0.L;
		var value = _v0.D;
		return {
			L: unvisited,
			D: fn(value),
			T: visited
		};
	});
var $ericgj$elm_csv_decode$Csv$Decode$map = F2(
	function (subValue, _v0) {
		var decoder = _v0;
		return function (_v1) {
			var visited = _v1.T;
			var unvisited = _v1.L;
			var value = _v1.D;
			return A2(
				$elm$core$Result$map,
				$ericgj$elm_csv_decode$Csv$Decode$mapHelp(value),
				decoder(
					{L: unvisited, D: subValue, T: visited}));
		};
	});
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $author$project$Type$Import$stringToFloatResult = function (s) {
	return A2(
		$elm$core$Result$fromMaybe,
		'Cannot convert \'' + (s + '\' to Float'),
		$elm$core$String$toFloat(s));
};
var $author$project$Type$Import$stringToIntResult = function (s) {
	return A2(
		$elm$core$Result$fromMaybe,
		'Cannot convert \'' + (s + '\' to Int'),
		$elm$core$String$toInt(s));
};
var $author$project$Type$Import$stringToListResult = function (s) {
	return $elm$core$Result$Ok(
		A2($elm$core$String$split, ',', s));
};
var $author$project$Type$Import$decodeCsvItem = A2(
	$ericgj$elm_csv_decode$Csv$Decode$map,
	$author$project$Type$Import$Item,
	A2(
		$ericgj$elm_csv_decode$Csv$Decode$andMap,
		A2($ericgj$elm_csv_decode$Csv$Decode$field, 'cornerRadius', $author$project$Type$Import$stringToFloatResult),
		A2(
			$ericgj$elm_csv_decode$Csv$Decode$andMap,
			A2($ericgj$elm_csv_decode$Csv$Decode$field, 'backImageName', $elm$core$Result$Ok),
			A2(
				$ericgj$elm_csv_decode$Csv$Decode$andMap,
				A2($ericgj$elm_csv_decode$Csv$Decode$field, 'ratio', $author$project$Type$Import$stringToFloatResult),
				A2(
					$ericgj$elm_csv_decode$Csv$Decode$andMap,
					A2($ericgj$elm_csv_decode$Csv$Decode$field, 'template', $author$project$Type$Import$stringToIntResult),
					A2(
						$ericgj$elm_csv_decode$Csv$Decode$andMap,
						A2($ericgj$elm_csv_decode$Csv$Decode$field, 'tags', $author$project$Type$Import$stringToListResult),
						A2(
							$ericgj$elm_csv_decode$Csv$Decode$andMap,
							A2($ericgj$elm_csv_decode$Csv$Decode$field, 'title', $elm$core$Result$Ok),
							A2($ericgj$elm_csv_decode$Csv$Decode$field, 'itemNumber', $author$project$Type$Import$stringToIntResult))))))));
var $author$project$Type$Import$Set = function (title) {
	return function (description) {
		return function (tags) {
			return function (imageName) {
				return function (itemTemplate) {
					return function (editionType) {
						return function (countMax) {
							return function (batchSize) {
								return function (maxBatches) {
									return function (itemCount) {
										return {aC: batchSize, aF: countMax, cl: description, aK: editionType, W: imageName, cE: itemCount, cI: itemTemplate, ap: maxBatches, ag: tags, b$: title};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Import$decodeCsvSet = A2(
	$ericgj$elm_csv_decode$Csv$Decode$map,
	$author$project$Type$Import$Set,
	A2(
		$ericgj$elm_csv_decode$Csv$Decode$andMap,
		A2($ericgj$elm_csv_decode$Csv$Decode$field, 'itemCount', $author$project$Type$Import$stringToIntResult),
		A2(
			$ericgj$elm_csv_decode$Csv$Decode$andMap,
			A2($ericgj$elm_csv_decode$Csv$Decode$field, 'maxBatches', $author$project$Type$Import$stringToIntResult),
			A2(
				$ericgj$elm_csv_decode$Csv$Decode$andMap,
				A2($ericgj$elm_csv_decode$Csv$Decode$field, 'batchSize', $author$project$Type$Import$stringToIntResult),
				A2(
					$ericgj$elm_csv_decode$Csv$Decode$andMap,
					A2($ericgj$elm_csv_decode$Csv$Decode$field, 'countMax', $author$project$Type$Import$stringToIntResult),
					A2(
						$ericgj$elm_csv_decode$Csv$Decode$andMap,
						A2($ericgj$elm_csv_decode$Csv$Decode$field, 'editionType', $author$project$Type$Import$stringToIntResult),
						A2(
							$ericgj$elm_csv_decode$Csv$Decode$andMap,
							A2($ericgj$elm_csv_decode$Csv$Decode$field, 'itemTemplate', $author$project$Type$Import$stringToIntResult),
							A2(
								$ericgj$elm_csv_decode$Csv$Decode$andMap,
								A2($ericgj$elm_csv_decode$Csv$Decode$field, 'imageName', $elm$core$Result$Ok),
								A2(
									$ericgj$elm_csv_decode$Csv$Decode$andMap,
									A2($ericgj$elm_csv_decode$Csv$Decode$field, 'tags', $author$project$Type$Import$stringToListResult),
									A2(
										$ericgj$elm_csv_decode$Csv$Decode$andMap,
										A2($ericgj$elm_csv_decode$Csv$Decode$field, 'description', $elm$core$Result$Ok),
										A2($ericgj$elm_csv_decode$Csv$Decode$field, 'title', $elm$core$Result$Ok)))))))))));
var $author$project$Type$Import$Variant = F5(
	function (imageName, title, rarityFactor, backgroundColor, backgroundImageName) {
		return {aA: backgroundColor, a4: backgroundImageName, W: imageName, aV: rarityFactor, b$: title};
	});
var $author$project$Type$Import$decodeCsvVariant = A2(
	$ericgj$elm_csv_decode$Csv$Decode$map,
	$author$project$Type$Import$Variant,
	A2(
		$ericgj$elm_csv_decode$Csv$Decode$andMap,
		A2($ericgj$elm_csv_decode$Csv$Decode$field, 'backgroundImageName', $elm$core$Result$Ok),
		A2(
			$ericgj$elm_csv_decode$Csv$Decode$andMap,
			A2($ericgj$elm_csv_decode$Csv$Decode$field, 'backgroundColor', $elm$core$Result$Ok),
			A2(
				$ericgj$elm_csv_decode$Csv$Decode$andMap,
				A2($ericgj$elm_csv_decode$Csv$Decode$field, 'rarityFactor', $author$project$Type$Import$stringToFloatResult),
				A2(
					$ericgj$elm_csv_decode$Csv$Decode$andMap,
					A2($ericgj$elm_csv_decode$Csv$Decode$field, 'title', $elm$core$Result$Ok),
					A2($ericgj$elm_csv_decode$Csv$Decode$field, 'imageName', $elm$core$Result$Ok))))));
var $author$project$Type$Import$CsvVariantSummary = F3(
	function (imageName, rarityFactor, title) {
		return {W: imageName, aV: rarityFactor, b$: title};
	});
var $author$project$Type$Import$decodeCsvVariantSummary = A2(
	$ericgj$elm_csv_decode$Csv$Decode$map,
	$author$project$Type$Import$CsvVariantSummary,
	A2(
		$ericgj$elm_csv_decode$Csv$Decode$andMap,
		A2($ericgj$elm_csv_decode$Csv$Decode$field, 'title', $elm$core$Result$Ok),
		A2(
			$ericgj$elm_csv_decode$Csv$Decode$andMap,
			A2($ericgj$elm_csv_decode$Csv$Decode$field, 'rarityFactor', $author$project$Type$Import$stringToFloatResult),
			A2($ericgj$elm_csv_decode$Csv$Decode$field, 'imageName', $elm$core$Result$Ok))));
var $elm_community$list_extra$List$Extra$groupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var thisGroup = A2($elm$core$List$take, size, xs);
		var okayLength = _Utils_eq(
			size,
			$elm$core$List$length(thisGroup));
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayLength) ? A2(
			$elm$core$List$cons,
			thisGroup,
			A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$groupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, size, xs);
	});
var $author$project$Type$Variant$importVariantEncoder = function (_var) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'batchSize',
				$elm$json$Json$Encode$int(_var.aC)),
				_Utils_Tuple2(
				'content',
				$author$project$Type$Variant$contentEncoder(_var.N)),
				_Utils_Tuple2(
				'countMax',
				$elm$json$Json$Encode$int(_var.aF)),
				_Utils_Tuple2(
				'editionType',
				$elm$json$Json$Encode$int(_var.aK)),
				_Utils_Tuple2(
				'imageUrl',
				$elm$json$Json$Encode$string(_var.bo)),
				_Utils_Tuple2(
				'itemTemplate',
				$elm$json$Json$Encode$int(_var.cI)),
				_Utils_Tuple2(
				'itemTitle',
				$elm$json$Json$Encode$string(_var.cJ)),
				_Utils_Tuple2(
				'maxBatches',
				$elm$json$Json$Encode$int(_var.ap)),
				_Utils_Tuple2(
				'rarityFactor',
				$elm$json$Json$Encode$float(_var.aV)),
				_Utils_Tuple2(
				'ratio',
				$elm$json$Json$Encode$float(_var.I)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(_var.aZ)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(_var.b$)),
				_Utils_Tuple2(
				'variantTemplateRef',
				$author$project$Type$DocRef$docRefEncoder(_var.b4))
			]));
};
var $author$project$Type$Item$importItemEncoder = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'batchSize',
				$elm$json$Json$Encode$int(item.aC)),
				_Utils_Tuple2(
				'content',
				$author$project$Type$Item$contentEncoder(item.N)),
				_Utils_Tuple2(
				'countMax',
				$elm$json$Json$Encode$int(item.aF)),
				_Utils_Tuple2(
				'editionType',
				$elm$json$Json$Encode$int(item.aK)),
				_Utils_Tuple2(
				'itemNumber',
				$elm$json$Json$Encode$int(item.cG)),
				_Utils_Tuple2(
				'maxBatches',
				$elm$json$Json$Encode$int(item.ap)),
				_Utils_Tuple2(
				'ratio',
				$elm$json$Json$Encode$float(item.I)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(item.aZ)),
				_Utils_Tuple2(
				'tags',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, item.ag)),
				_Utils_Tuple2(
				'template',
				$elm$json$Json$Encode$int(item.ah)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(item.b$)),
				_Utils_Tuple2(
				'variants',
				A2($elm$json$Json$Encode$list, $author$project$Type$Variant$importVariantEncoder, item.b5))
			]));
};
var $author$project$Type$CSet$importSetEncoder = function (set) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'batchSize',
				$elm$json$Json$Encode$int(set.aC)),
				_Utils_Tuple2(
				'countMax',
				$elm$json$Json$Encode$int(set.aF)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(set.cl)),
				_Utils_Tuple2(
				'editionType',
				$elm$json$Json$Encode$int(set.aK)),
				_Utils_Tuple2(
				'imageUrl',
				$elm$json$Json$Encode$string(set.bo)),
				_Utils_Tuple2(
				'itemCount',
				$elm$json$Json$Encode$int(set.cE)),
				_Utils_Tuple2(
				'itemTemplate',
				$elm$json$Json$Encode$int(set.cI)),
				_Utils_Tuple2(
				'maxBatches',
				$elm$json$Json$Encode$int(set.ap)),
				_Utils_Tuple2(
				'status',
				$elm$json$Json$Encode$int(set.aZ)),
				_Utils_Tuple2(
				'tags',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, set.ag)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(set.b$)),
				_Utils_Tuple2(
				'variantSummaries',
				$elm$json$Json$Encode$list($author$project$Type$CSet$variantSummaryEncoder)(set.dp))
			]));
};
var $author$project$Page$SetImport$importSetAndItemsEncoder = F2(
	function (set, items) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'set',
					$author$project$Type$CSet$importSetEncoder(set)),
					_Utils_Tuple2(
					'items',
					A2($elm$json$Json$Encode$list, $author$project$Type$Item$importItemEncoder, items))
				]));
	});
var $author$project$Page$SetImport$isError = function (result) {
	if (result.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$String$lines = _String_lines;
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $lovasoa$elm_csv$Helper$parseRemaining = F5(
	function (separator, quoted, startOfLine, remaining, result) {
		parseRemaining:
		while (true) {
			if (remaining === '') {
				return result;
			} else {
				if ((separator !== '') && ((!quoted) && A2($elm$core$String$startsWith, separator, remaining))) {
					var nextChars = A2(
						$elm$core$String$dropLeft,
						$elm$core$String$length(separator),
						remaining);
					var newResult = startOfLine ? _List_fromArray(
						['', '']) : A2($elm$core$List$cons, '', result);
					var newQuoted = false;
					var $temp$separator = separator,
						$temp$quoted = false,
						$temp$startOfLine = false,
						$temp$remaining = nextChars,
						$temp$result = newResult;
					separator = $temp$separator;
					quoted = $temp$quoted;
					startOfLine = $temp$startOfLine;
					remaining = $temp$remaining;
					result = $temp$result;
					continue parseRemaining;
				} else {
					var others = A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						$elm$core$List$tail(result));
					var nextNextChar = A3($elm$core$String$slice, 1, 2, remaining);
					var nextChar = A3($elm$core$String$slice, 0, 1, remaining);
					var isEscapedQuote = (!quoted) && (((nextChar === '\\') || (nextChar === '\"')) && (nextNextChar === '\"'));
					var nextChars = A2(
						$elm$core$String$dropLeft,
						isEscapedQuote ? 2 : 1,
						remaining);
					var endQuote = quoted && ((nextChar === '\"') && (!isEscapedQuote));
					var current = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(result));
					var startQuote = (nextChar === '\"') && ((nextNextChar !== '\"') && (current === ''));
					var newChar = isEscapedQuote ? '\"' : ((startQuote || endQuote) ? '' : nextChar);
					var newDone = A2(
						$elm$core$List$cons,
						_Utils_ap(current, newChar),
						others);
					var newQuoted = (quoted && (!endQuote)) || startQuote;
					var $temp$separator = separator,
						$temp$quoted = newQuoted,
						$temp$startOfLine = false,
						$temp$remaining = nextChars,
						$temp$result = newDone;
					separator = $temp$separator;
					quoted = $temp$quoted;
					startOfLine = $temp$startOfLine;
					remaining = $temp$remaining;
					result = $temp$result;
					continue parseRemaining;
				}
			}
		}
	});
var $lovasoa$elm_csv$Helper$splitLineWith = F2(
	function (separator, line) {
		return $elm$core$List$reverse(
			A5($lovasoa$elm_csv$Helper$parseRemaining, separator, false, true, line, _List_Nil));
	});
var $lovasoa$elm_csv$Csv$splitWith = F2(
	function (separator, lines) {
		var values = A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			$elm$core$String$lines(lines));
		return A2(
			$elm$core$List$map,
			$lovasoa$elm_csv$Helper$splitLineWith(separator),
			values);
	});
var $lovasoa$elm_csv$Csv$parseWith = F2(
	function (separator, lines) {
		var values = A2($lovasoa$elm_csv$Csv$splitWith, separator, lines);
		var records = A2($elm$core$List$drop, 1, values);
		var headers = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(values));
		return {bk: headers, bG: records};
	});
var $lovasoa$elm_csv$Csv$parse = $lovasoa$elm_csv$Csv$parseWith(',');
var $elm_community$list_extra$List$Extra$indexedFoldr = F3(
	function (func, acc, list) {
		var step = F2(
			function (x, _v0) {
				var i = _v0.a;
				var thisAcc = _v0.b;
				return _Utils_Tuple2(
					i - 1,
					A3(func, i, x, thisAcc));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(
				$elm$core$List$length(list) - 1,
				acc),
			list).b;
	});
var $elm_community$list_extra$List$Extra$removeIfIndex = function (predicate) {
	return A2(
		$elm_community$list_extra$List$Extra$indexedFoldr,
		F3(
			function (index, item, acc) {
				return predicate(index) ? acc : A2($elm$core$List$cons, item, acc);
			}),
		_List_Nil);
};
var $author$project$Type$Import$emptyCSet = {aC: 0, aF: 0, aI: 0, cl: '', aK: 0, bn: '', bo: '', cE: 0, cI: 0, ap: 0, aZ: 0, ag: _List_Nil, b$: 'Untitled', dp: _List_Nil};
var $author$project$Type$Import$toCSet = F3(
	function (url, variantSummaries, set) {
		return $elm$core$Maybe$Just(
			_Utils_update(
				$author$project$Type$Import$emptyCSet,
				{aC: set.aC, aF: set.aF, cl: set.cl, aK: set.aK, bo: url + (set.W + '?alt=media'), cE: set.cE, cI: set.cI, ap: set.ap, ag: set.ag, b$: set.b$, dp: variantSummaries}));
	});
var $author$project$Type$Item$toContent = F3(
	function (imagedir, backImageName, cornerRadius) {
		var maybeBackImageUrl = (backImageName === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(imagedir + (backImageName + '?alt=media'));
		return {G: cornerRadius, aO: maybeBackImageUrl};
	});
var $author$project$Type$Variant$emptyVariant = {
	aC: 0,
	N: A2($author$project$Type$Variant$Content, $elm$core$Maybe$Nothing, '#ffffff'),
	aF: 0,
	aK: 0,
	bn: '',
	bo: '',
	cI: 1,
	cJ: '',
	ap: 0,
	aT: '',
	aV: 1,
	I: 1,
	aZ: 0,
	b$: '',
	b4: A2($author$project$Type$DocRef$DocRef, '', '')
};
var $elm$core$Basics$round = _Basics_round;
var $author$project$Type$Variant$toContent = F3(
	function (imagedir, imageName, backgroundColor) {
		var maybeBackgroundImageUrl = (imageName === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(imagedir + (imageName + '?alt=media'));
		return {aA: backgroundColor, bu: maybeBackgroundImageUrl};
	});
var $author$project$Type$Import$toItemVariant = F4(
	function (url, set, item, _var) {
		var variantStr = $elm$core$String$fromInt(
			$elm$core$Basics$round(10 * _var.aV));
		var content = A3($author$project$Type$Variant$toContent, url, _var.a4, _var.aA);
		return _Utils_update(
			$author$project$Type$Variant$emptyVariant,
			{
				aC: $elm$core$Basics$floor(_var.aV * set.aC),
				N: content,
				aF: set.aF,
				aK: set.aK,
				bo: url + (_var.W + '?alt=media'),
				cI: item.ah,
				cJ: item.b$,
				ap: set.ap,
				aV: _var.aV,
				I: item.I,
				b$: _var.b$,
				b4: A2($author$project$Type$DocRef$DocRef, variantStr, 'variantTemplates/' + variantStr)
			});
	});
var $author$project$Type$Import$toItem = F4(
	function (imagedir, set, csvItemVariants, item) {
		var itemVariants = A2(
			$elm$core$List$map,
			A3($author$project$Type$Import$toItemVariant, imagedir, set, item),
			csvItemVariants);
		return _Utils_update(
			$author$project$Type$Item$emptyItem,
			{
				aC: set.aC,
				N: A3($author$project$Type$Item$toContent, imagedir, item.a2, item.G),
				aF: set.aF,
				aK: set.aK,
				cG: item.cG,
				ap: set.ap,
				I: item.I,
				ag: item.ag,
				ah: item.ah,
				b$: item.b$,
				b5: itemVariants
			});
	});
var $elm$file$File$toString = _File_toString;
var $author$project$Type$Import$toVariantSummary = F2(
	function (path, _var) {
		var variantStr = $elm$core$String$fromInt(
			$elm$core$Basics$round(10 * _var.aV));
		var imageUrl = path + (_var.W + '?alt=media');
		return {bo: imageUrl, aV: _var.aV, b$: _var.b$, b4: 'variants/' + variantStr};
	});
var $author$project$Page$SetImport$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: _List_Nil}),
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Page$SetImport$GotFile));
			case 1:
				var file = msg.a;
				var fileUrl = $elm$file$File$toUrl(file);
				var fileString = $elm$file$File$toString(file);
				var fileName = $elm$file$File$name(file);
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Page$SetImport$GotCsvData(fileName),
						fileString));
			case 2:
				var fileName = msg.a;
				var data = msg.b;
				var newFileName = A2(
					$elm$core$String$join,
					'_',
					_List_fromArray(
						[model.aH, fileName]));
				var errors = _List_Nil;
				var dataLines = $elm$core$String$lines(data);
				var newLines = A3($elm$core$List$foldl, $author$project$Page$SetImport$addSectionNameTo, _List_Nil, dataLines);
				var imagedir = A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm_community$list_extra$List$Extra$getAt,
						1,
						A2(
							$elm$core$String$split,
							',',
							A2(
								$elm$core$String$join,
								'\r\n',
								A2(
									$elm$core$List$filter,
									function (line) {
										return A2($elm$core$String$startsWith, 'imagedir', line);
									},
									newLines)))));
				var results1 = (imagedir === '') ? A2(
					$elm$core$List$cons,
					$elm$core$Result$Err('ImageDir is empty'),
					model.J) : A2(
					$elm$core$List$cons,
					$elm$core$Result$Ok('ImageDir exists'),
					model.J);
				var itemLines = A2(
					$elm$core$List$filter,
					function (line) {
						return A2($elm$core$String$startsWith, 'item', line);
					},
					newLines);
				var itemHeader = A2($elm$core$List$take, 1, itemLines);
				var itemLinesParsed = A2(
					$elm$core$Result$withDefault,
					_List_Nil,
					A2(
						$ericgj$elm_csv_decode$Csv$Decode$decodeCsv,
						$author$project$Type$Import$decodeCsvItem,
						$lovasoa$elm_csv$Csv$parse(
							A2(
								$elm$core$String$join,
								'\r\n',
								_Utils_ap(
									itemHeader,
									A2(
										$elm_community$list_extra$List$Extra$removeIfIndex,
										function (n) {
											return !A2($elm$core$Basics$modBy, 2, n);
										},
										itemLines))))));
				var itemVariantLines = A2(
					$elm$core$List$filter,
					function (line) {
						return A2($elm$core$String$startsWith, 'variant,', line);
					},
					newLines);
				var itemVariantHeader = A2($elm$core$List$take, 1, itemVariantLines);
				var setLinesParsed = $lovasoa$elm_csv$Csv$parse(
					A2(
						$elm$core$String$join,
						'\r\n',
						A2(
							$elm$core$List$filter,
							function (line) {
								return A2($elm$core$String$startsWith, 'set', line);
							},
							newLines)));
				var variantSummaryParsed = $lovasoa$elm_csv$Csv$parse(
					A2(
						$elm$core$String$join,
						'\r\n',
						A2(
							$elm$core$List$filter,
							function (line) {
								return A2($elm$core$String$startsWith, 'variantSummary', line);
							},
							newLines)));
				var variantSummaries = A2(
					$elm$core$Result$withDefault,
					_List_Nil,
					A2(
						$elm$core$Result$map,
						$elm$core$List$map(
							$author$project$Type$Import$toVariantSummary(imagedir)),
						A2($ericgj$elm_csv_decode$Csv$Decode$decodeCsv, $author$project$Type$Import$decodeCsvVariantSummary, variantSummaryParsed)));
				var numbOfVariants = $elm$core$List$length(variantSummaries);
				var itemVariantLinesParsed = A2(
					$elm$core$Result$withDefault,
					_List_Nil,
					A2(
						$ericgj$elm_csv_decode$Csv$Decode$decodeCsv,
						$author$project$Type$Import$decodeCsvVariant,
						$lovasoa$elm_csv$Csv$parse(
							A2(
								$elm$core$String$join,
								'\r\n',
								_Utils_ap(
									itemVariantHeader,
									A2(
										$elm_community$list_extra$List$Extra$removeIfIndex,
										function (n) {
											return !A2($elm$core$Basics$modBy, numbOfVariants + 1, n);
										},
										itemVariantLines))))));
				var itemVariantsGroups = A2($elm_community$list_extra$List$Extra$groupsOf, numbOfVariants, itemVariantLinesParsed);
				var results2 = (!numbOfVariants) ? A2(
					$elm$core$List$cons,
					$elm$core$Result$Err('Number of variant summaries must be greater than zero'),
					results1) : A2(
					$elm$core$List$cons,
					$elm$core$Result$Ok(
						$elm$core$String$fromInt(numbOfVariants) + ' number of variants'),
					results1);
				var appId = $author$project$Type$Session$appId(model.db);
				var path = A2(
					$elm$core$String$join,
					'/',
					_List_fromArray(
						['apps', appId, newFileName]));
				var _v1 = function () {
					var _v2 = A2($ericgj$elm_csv_decode$Csv$Decode$decodeCsv, $author$project$Type$Import$decodeCsvSet, setLinesParsed);
					if (!_v2.$) {
						var lines = _v2.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$Maybe$andThen,
								A2($author$project$Type$Import$toCSet, imagedir, variantSummaries),
								$elm$core$List$head(lines)),
							A2(
								$elm$core$List$cons,
								$elm$core$Result$Ok('Set parsed from CSV file'),
								results2));
					} else {
						var error = _v2.a;
						return _Utils_Tuple2(
							$elm$core$Maybe$Nothing,
							A2(
								$elm$core$List$cons,
								$elm$core$Result$Err('Failed to parse set data'),
								results2));
					}
				}();
				var maybeCsvSet = _v1.a;
				var results3 = _v1.b;
				var _v3 = function () {
					if (!maybeCsvSet.$) {
						var cset = maybeCsvSet.a;
						var items = A3(
							$elm$core$List$map2,
							A2($author$project$Type$Import$toItem, imagedir, cset),
							itemVariantsGroups,
							itemLinesParsed);
						return _Utils_Tuple2(
							A2($author$project$Page$SetImport$Parsed, cset, items),
							results3);
					} else {
						return _Utils_Tuple2(
							$author$project$Page$SetImport$Error,
							A2(
								$elm$core$List$cons,
								$elm$core$Result$Err('Valid set not found'),
								results3));
					}
				}();
				var stage = _v3.a;
				var results4 = _v3.b;
				var newStage = A2($elm$core$List$any, $author$project$Page$SetImport$isError, results4) ? $author$project$Page$SetImport$Error : stage;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: results3, av: newStage}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var set = msg.a;
				var items = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: _List_Nil, av: $author$project$Page$SetImport$Ready}),
					$author$project$Firebase$createSetAndItems(
						A2($author$project$Page$SetImport$importSetAndItemsEncoder, set, items)));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateSetImportMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$SetImport$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$SetImportPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetImportMsg, subCmd));
	});
var $author$project$Firebase$batchDelete = _Platform_outgoingPort('batchDelete', $elm$core$Basics$identity);
var $author$project$Type$CSet$toPath = function (setId) {
	return 'sets/' + setId;
};
var $author$project$Type$Item$toPath = function (item) {
	return 'sets/' + (item.dd + ('/items/' + item.bn));
};
var $author$project$Type$Variant$toPath = F2(
	function (itemPath, variant) {
		return itemPath + ('/variants/' + variant.bn);
	});
var $author$project$Page$SetList$update = F2(
	function (msg, model) {
		if (msg.$ === 2) {
			var id = msg.a;
			return _Utils_Tuple2(
				model,
				A2(
					$author$project$Route$pushUrl,
					$author$project$Type$Session$navKey(model.db),
					A2($author$project$Route$SetEdit, id, $elm$core$Maybe$Nothing)));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updatePassThruMsg = F3(
	function (model, subMsg, subModel) {
		var _v0 = A2($author$project$Page$SetList$update, subMsg, subModel);
		var newSubModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					c3: $author$project$Type$Page$SetListPage(newSubModel)
				}),
			A2($elm$core$Platform$Cmd$map, $author$project$Msg$SetListMsg, subCmd));
	});
var $author$project$Update$updateSetListMsg = F3(
	function (model, subMsg, subModel) {
		if (subMsg.$ === 6) {
			var id = subMsg.a;
			var setPath = $author$project$Type$CSet$toPath(id);
			var setItems = A2(
				$elm$core$List$filter,
				function (i) {
					return _Utils_eq(i.dd, id);
				},
				$elm$core$Dict$values(model.cF));
			var itemsToVariantPaths = function (item) {
				var ipath = $author$project$Type$Item$toPath(item);
				return A2(
					$elm$core$List$map,
					function (v) {
						return A2($author$project$Type$Variant$toPath, ipath, v);
					},
					item.b5);
			};
			var variantPaths = $elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (i) {
						return itemsToVariantPaths(i);
					},
					setItems));
			var itemPaths = A2(
				$elm$core$List$map,
				function (i) {
					return $author$project$Type$Item$toPath(i);
				},
				setItems);
			var paths = A2(
				$elm$core$List$cons,
				setPath,
				_Utils_ap(itemPaths, variantPaths));
			var dataEncoded = $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'paths',
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, paths)),
						_Utils_Tuple2(
						'appId',
						$elm$json$Json$Encode$string(model.al))
					]));
			return _Utils_Tuple2(
				model,
				$author$project$Firebase$batchDelete(dataEncoded));
		} else {
			return A3($author$project$Update$updatePassThruMsg, model, subMsg, subModel);
		}
	});
var $author$project$Update$updateUrlChanged = F2(
	function (url, model) {
		var maybeRoute = A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
		var _v0 = A2($author$project$Update$pageForRoute, model, maybeRoute);
		var newModel = _v0.a;
		var subCmdMsg = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				newModel,
				{cQ: maybeRoute, b3: url}),
			subCmdMsg);
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.c3);
		_v0$33:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var urlRequest = _v0.a.a;
					return A2($author$project$Update$updateLinkClicked, urlRequest, model);
				case 1:
					var url = _v0.a.a;
					return A2($author$project$Update$updateUrlChanged, url, model);
				case 40:
					var subMsg = _v0.a.a;
					return A2($author$project$Update$updateAlertView, model, subMsg);
				case 2:
					if (!_v0.b.$) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateHomeMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 6:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateProfileMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 4:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateAnnouncementMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 8:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateSetEditMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 11:
					if (_v0.b.$ === 8) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateSetImportMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 7:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateSetListMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 9:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updateItemEditMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 10:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return A3($author$project$Update$updatePackEditMsg, model, subMsg, subModel);
					} else {
						break _v0$33;
					}
				case 3:
					if (_v0.b.$ === 1) {
						if (_v0.a.a.$ === 1) {
							var _v1 = _v0.a.a;
							var subModel = _v0.b.a;
							return A2($author$project$Update$updateGetStartedForCheckEmail, model, subModel);
						} else {
							var subMsg = _v0.a.a;
							var subModel = _v0.b.a;
							return A3($author$project$Update$updateGetStarted, model, subMsg, subModel);
						}
					} else {
						break _v0$33;
					}
				case 13:
					var data = _v0.a.a;
					return A2($author$project$Update$updateAuthStateChanged, model, data);
				case 15:
					var data = _v0.a.a;
					return A2($author$project$Update$updateProfileChanged, model, data);
				case 18:
					var data = _v0.a.a;
					return A2($author$project$Update$updateSetChanged, model, data);
				case 14:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotAlert, model, data);
				case 5:
					var subMsg = _v0.a.a;
					return A2($author$project$Update$updatePageMsg, model, subMsg);
				case 39:
					if (_v0.b.$ === 5) {
						var packId = _v0.a.a;
						var subModel = _v0.b.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$Route$pushUrl,
								$author$project$Type$Session$navKey(model.db),
								A2($author$project$Route$PackEdit, subModel.bn, packId)));
					} else {
						break _v0$33;
					}
				case 19:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotPack, model, data);
				case 25:
					var data = _v0.a.a;
					return A2($author$project$Update$updateAccountChanged, model, data);
				case 23:
					return _Utils_Tuple2(
						model,
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport));
				case 22:
					var _v2 = _v0.a;
					return _Utils_Tuple2(
						model,
						A2($elm$core$Task$perform, $author$project$Msg$GotViewport, $elm$browser$Browser$Dom$getViewport));
				case 24:
					var viewport = _v0.a.a;
					return A2($author$project$Update$updateGotViewport, model, viewport);
				case 30:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotAnnouncement, model, data);
				case 31:
					var id = _v0.a.a;
					return A2($author$project$Update$updateRemoveAnnouncement, model, id);
				case 32:
					var newZone = _v0.a.a;
					return A2($author$project$Update$updateAdjustTimeZone, model, newZone);
				case 34:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotImage, model, data);
				case 36:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotItem, model, data);
				case 37:
					var id = _v0.a.a;
					return A2($author$project$Update$updateRemoveItem, model, id);
				case 38:
					var data = _v0.a.a;
					return A2($author$project$Update$updateGotVariant, model, data);
				case 41:
					var id = _v0.a.a;
					return A2($author$project$Update$updateGotSetRemoved, model, id);
				case 42:
					var time = _v0.a.a;
					var now = $elm$time$Time$posixToMillis(time);
					var newAlerts = A2(
						$elm$core$List$filter,
						function (a) {
							return (_Utils_cmp(a.ck + $author$project$Ox$Theme$alertTimeOut, now) > 0) || (a.bq !== '');
						},
						model.cb);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cb: newAlerts,
								cY: $elm$time$Time$posixToMillis(time)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					break _v0$33;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Msg$AlertMsg = function (a) {
	return {$: 40, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Ox$Alert$RemoveAlert = $elm$core$Basics$identity;
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Ox$Icon$cancel = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Theme$colorDanger = '#B00020';
var $author$project$Ox$Theme$colorDark = '#333';
var $author$project$Ox$Theme$colorLight = '#ddd';
var $author$project$Ox$Theme$colorSuccess = 'ForestGreen';
var $author$project$Ox$Theme$colorWarning = 'Gold';
var $author$project$Ox$Theme$colorWhite = 'White';
var $author$project$Ox$Theme$colorsForAlertKind = function (kind) {
	switch (kind) {
		case 'success':
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorSuccess);
		case 'warning':
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorWarning);
		case 'error':
			return _Utils_Tuple2($author$project$Ox$Theme$colorWhite, $author$project$Ox$Theme$colorDanger);
		default:
			return _Utils_Tuple2($author$project$Ox$Theme$colorDark, $author$project$Ox$Theme$colorLight);
	}
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Ox$Alert$onCustomClick = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{bv: msg, c5: true, dh: true}));
};
var $author$project$Ox$Alert$viewAlert = F2(
	function (index, alert) {
		var _v0 = $author$project$Ox$Theme$colorsForAlertKind(alert.cK);
		var color = _v0.a;
		var bgColor = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('br2 w-90 pv3 ph3 mb2 relative'),
					A2($elm$html$Html$Attributes$style, 'background-color', bgColor),
					A2($elm$html$Html$Attributes$style, 'color', color),
					A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
					A2($elm$html$Html$Attributes$style, 'max-width', '328px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-column tl'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '30px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f5 fw4')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(alert.bv)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Ox$Alert$onCustomClick(index),
							$elm$html$Html$Attributes$class('absolute right-1 top-1 pointer'),
							A2($elm$html$Html$Attributes$style, 'height', '24px'),
							A2($elm$html$Html$Attributes$style, 'width', '24px')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$cancel(color)
						]))
				]));
	});
var $author$project$Ox$Alert$view = function (alerts) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fixed w-100 flex flex-column items-center justify-center'),
				A2($elm$html$Html$Attributes$style, 'top', '1em'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000')
			]),
		A2($elm$core$List$indexedMap, $author$project$Ox$Alert$viewAlert, alerts));
};
var $author$project$Page$pageTitle = function (title) {
	return title + (' | ' + $author$project$Settings$appName);
};
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $elm$html$Html$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Page$viewFooter = function (size) {
	var mobileFooter = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '60px')
			]),
		_List_Nil);
	var copyright = '© 2021 ' + $author$project$Settings$appName;
	var desktopFooter = A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph3 ph4-ns pv4 avenir mb3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f6 db fw3 lh-solid')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(copyright)
					]))
			]));
	switch (size.$) {
		case 0:
			return mobileFooter;
		case 1:
			return mobileFooter;
		default:
			return desktopFooter;
	}
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Page$headerLink = F3(
	function (path, pathTitle, color) {
		return A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('link dim dib mr3 ' + color),
							$elm$html$Html$Attributes$href(path)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(pathTitle)
						]))
				]));
	});
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Page$viewHeaderGuest = A2(
	$elm$html$Html$header,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('bg-black-90 avenir fixed w-100 ph3 pv3')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw4 ttu tracked tr')
				]),
			_List_fromArray(
				[
					A3($author$project$Page$headerLink, '/home', 'Home', 'white'),
					A3($author$project$Page$headerLink, '/signin', 'Sign In', 'white')
				]))
		]));
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $author$project$Ox$Icon$clubhouseSmall = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('52'),
						$elm$svg$Svg$Attributes$r('33'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('34'),
						$elm$svg$Svg$Attributes$cy('56'),
						$elm$svg$Svg$Attributes$r('23'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('166'),
						$elm$svg$Svg$Attributes$cy('56'),
						$elm$svg$Svg$Attributes$r('23'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M99.987,97.52c27.582,0 49.975,22.21 49.975,49.567c0,5.523 0.084,31.314 0.003,36.518c-0.082,5.23 -27.821,9.875 -49.835,9.875c-19.266,0 -49.821,-3.613 -49.986,-9.93c-0.202,-7.717 -0.132,-28.215 -0.132,-36.463c0,-27.357 22.393,-49.567 49.975,-49.567Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M37.51,151.445c-1.158,0.036 -2.303,0.055 -3.425,0.055c-12.524,0 -32.384,-2.372 -32.491,-6.519c-0.132,-5.067 -0.087,-18.524 -0.087,-23.939c0,-17.96 14.556,-32.542 32.484,-32.542c9.79,0 18.574,4.348 24.532,11.219c-12.885,11.354 -21.009,27.908 -21.009,46.33c0,1.505 -0.001,3.336 -0.004,5.396Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M141.473,99.705c5.957,-6.863 14.736,-11.205 24.518,-11.205c17.929,0 32.484,14.582 32.484,32.542c0,3.626 0.055,20.558 0.002,23.975c-0.053,3.434 -18.083,6.483 -32.392,6.483c-1.153,0 -2.369,-0.02 -3.625,-0.06c-0.004,-2.37 -0.007,-4.263 -0.007,-5.431c0,-18.408 -8.112,-34.952 -20.98,-46.304Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Settings$fullAppName = function (appId) {
	switch (appId) {
		case 'com.tibles.drseuss.dev':
			return 'CMS Dr Seuss Dev';
		case 'com.tibles.drseuss':
			return 'CMS Dr Seuss';
		case 'com.tibles.valiant.dev':
			return 'CMS Valiant Dev';
		case 'com.tibles.blank':
			return 'CMS Blank';
		default:
			return 'CMS Blank';
	}
};
var $author$project$Ox$Icon$homeSmall = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M130.138,30.287l0,-22.831l38.294,0l-0.128,52.385l31.202,24.355l-16.965,22l-8.255,-5.568l0,92.487l-54.038,0.002l0,-63.16l-40.496,0l0,63.16l-53.914,-0.002l0,-92.557l-8.431,5.371l-16.965,-22l99.558,-76.929l30.138,23.287Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$itemsSmall = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('46.923'),
						$elm$svg$Svg$Attributes$cy('46.923'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('153.161'),
						$elm$svg$Svg$Attributes$cy('46.923'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('46.923'),
						$elm$svg$Svg$Attributes$cy('153.161'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('153.161'),
						$elm$svg$Svg$Attributes$cy('153.161'),
						$elm$svg$Svg$Attributes$r('40'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Type$Session$route = function (session) {
	return $author$project$Type$Session$params(session).c9;
};
var $author$project$Ox$Icon$signout = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M87,150l0,-70.02c0,-1.321 0.525,-2.588 1.459,-3.521c0.933,-0.934 2.2,-1.459 3.521,-1.459c4.602,0 11.405,0 16.015,0c2.764,0 5.005,2.241 5.005,5.005l0,69.995l20.395,0c0.765,0 1.463,0.436 1.798,1.124c0.335,0.688 0.249,1.506 -0.223,2.109c-7.197,9.196 -27.639,35.317 -33.426,42.711c-0.372,0.475 -0.941,0.752 -1.544,0.752c-0.603,0 -1.172,-0.277 -1.544,-0.752c-5.787,-7.394 -26.229,-33.515 -33.426,-42.711c-0.472,-0.603 -0.558,-1.421 -0.223,-2.109c0.335,-0.688 1.033,-1.124 1.798,-1.124c6.976,0 20.395,0 20.395,0Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M41.211,164.098c-2.442,2.58 -5.876,3.986 -9.426,3.86c-3.55,-0.126 -6.875,-1.772 -9.128,-4.518c-14.141,-17.293 -22.657,-39.38 -22.657,-63.44c0,-55.192 44.808,-100 100,-100c55.192,0 100,44.808 100,100c0,24.042 -8.503,46.114 -22.662,63.369c-2.251,2.746 -5.574,4.393 -9.122,4.52c-3.549,0.126 -6.98,-1.278 -9.422,-3.857c-0.035,0.033 -0.036,0.033 -0.036,0.033c-4.291,-4.533 -4.543,-11.549 -0.587,-16.378c10.704,-12.961 17.109,-29.582 17.109,-47.687c0,-41.548 -33.732,-75.28 -75.28,-75.28c-41.548,0 -75.28,33.732 -75.28,75.28c0,18.135 6.427,34.782 17.125,47.783c3.913,4.765 3.669,11.698 -0.57,16.176c0.002,0.069 -0.031,0.104 -0.064,0.139Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Type$Color$hiliteBlue = '#0A99F6';
var $author$project$Page$viewDesktopNavLink = F6(
	function (path, pathTitle, icon, _v0, myRoute, route) {
		var color = _Utils_eq(myRoute, route) ? $author$project$Type$Color$hiliteBlue : 'DimGray';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mr3 f6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ml1 link dib flex items-center'),
							A2($elm$html$Html$Attributes$style, 'outline', 'none'),
							$elm$html$Html$Attributes$href(path)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '1.5em')
								]),
							_List_fromArray(
								[
									icon(color)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f6 pl2 fw5 dim'),
									A2($elm$html$Html$Attributes$style, 'color', color)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(pathTitle)
								]))
						]))
				]));
	});
var $author$project$Page$viewDesktopNav = F2(
	function (_v0, session) {
		var screenSize = $author$project$Type$Session$screenSize(session);
		var route = $author$project$Type$Session$route(session);
		var appId = $author$project$Type$Session$appId(session);
		var appName = $author$project$Settings$fullAppName(appId);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-100 tc'),
					A2($elm$html$Html$Attributes$style, 'z-index', '1000'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorWhite),
					A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 2px rgba(0,0,0,0.1)')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$nav,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 fw4 tracked flex justify-between pt3 ph2 pb2'),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f4 fw5 ml2 link dib black'),
									$elm$html$Html$Attributes$href('/'),
									A2($elm$html$Html$Attributes$style, 'outline', 'none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(appName)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex ')
								]),
							_List_fromArray(
								[
									A6($author$project$Page$viewDesktopNavLink, '/home', 'Home', $author$project$Ox$Icon$homeSmall, screenSize, $author$project$Route$Home, route),
									A6($author$project$Page$viewDesktopNavLink, '/sets', 'Sets', $author$project$Ox$Icon$itemsSmall, screenSize, $author$project$Route$SetList, route),
									A6($author$project$Page$viewDesktopNavLink, '/announcements', 'Announcements', $author$project$Ox$Icon$clubhouseSmall, screenSize, $author$project$Route$Announcement, route),
									A6($author$project$Page$viewDesktopNavLink, '/signout', 'Sign out', $author$project$Ox$Icon$signout, screenSize, $author$project$Route$SignOut, route)
								]))
						]))
				]));
	});
var $author$project$Ox$Icon$clubhouse = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('66.997'),
						$elm$svg$Svg$Attributes$cy('28.232'),
						$elm$svg$Svg$Attributes$r('20.915'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('130.569'),
						$elm$svg$Svg$Attributes$cy('28.232'),
						$elm$svg$Svg$Attributes$r('20.915'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('35.347'),
						$elm$svg$Svg$Attributes$cy('79.665'),
						$elm$svg$Svg$Attributes$r('24.644'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('164.576'),
						$elm$svg$Svg$Attributes$cy('79.665'),
						$elm$svg$Svg$Attributes$r('24.644'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('99.827'),
						$elm$svg$Svg$Attributes$cy('103.331'),
						$elm$svg$Svg$Attributes$r('25.722'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M99.894,131.556c20.989,0 38.029,17.04 38.029,38.029c0,4.237 0.064,11.654 0.002,15.646c-0.062,4.013 -21.17,7.576 -37.922,7.576c-14.661,0 -37.911,-2.771 -38.037,-7.618c-0.153,-5.921 -0.101,-9.276 -0.101,-15.604c0,-20.989 17.041,-38.029 38.029,-38.029Z'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M55.999,163.046c-6.291,0.972 -13.649,1.568 -20.278,1.568c-13.729,0 -35.501,-2.596 -35.618,-7.134c-0.144,-5.544 -0.095,-8.687 -0.095,-14.612c0,-19.654 15.957,-35.61 35.611,-35.61c16.591,0 30.547,11.371 34.494,26.738c-8.486,6.874 -13.959,17.325 -14.114,29.05Z'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M144.001,163.046c6.291,0.972 13.649,1.568 20.278,1.568c13.729,0 35.501,-2.596 35.618,-7.134c0.144,-5.544 0.095,-8.687 0.095,-14.612c0,-19.654 -15.957,-35.61 -35.611,-35.61c-16.591,0 -30.547,11.371 -34.494,26.738c8.486,6.874 13.959,17.325 14.114,29.05Z'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M142.48,100.823c-3.548,5.583 -11.203,9.901 -11.383,9.881c0,0 0.907,-4.221 0.854,-8.801c-0.217,-18.747 -13.82,-29.285 -28.339,-31.075c4.131,-11.768 16.919,-19.191 30.091,-19.191c3.621,0 9.121,0.528 12.34,1.699c-6.919,5.426 -12.574,14.418 -12.574,23.885c0,9.318 2.277,18.168 9.011,23.602Z'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M56.92,100.823c3.549,5.583 11.204,9.901 11.383,9.881c0,0 -0.907,-4.221 -0.854,-8.801c0.217,-18.747 13.821,-29.285 28.34,-31.075c-4.132,-11.768 -16.92,-19.191 -30.091,-19.191c-3.622,0 -9.122,0.528 -12.341,1.699c6.92,5.426 12.574,14.418 12.574,23.885c0,9.318 -2.277,18.168 -9.011,23.602Z'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$home = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2,align-self:center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M119,121l0,72l-38,0l0,-72l38,0Zm-7.344,29.971l-7.697,0l0,8.003l7.697,0l0,-8.003Z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M70,192.887l-52.771,0l0,-110.94l-9.271,6.671l-4.669,-6.579l97.048,-75.413l0.165,0.114l40.976,31.841l0,-15.039l22.044,0l0,32.169l34.028,26.441l-4.669,6.579l-9.272,-6.671l0,110.94l-53.609,0l0,-83l-60,0l0,82.887Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Icon$items = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('32.364'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('100'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('32.364'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('100'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$circle,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$cx('167.636'),
						$elm$svg$Svg$Attributes$cy('167.636'),
						$elm$svg$Svg$Attributes$r('25.364'),
						$elm$svg$Svg$Attributes$fill(color)
					]),
				_List_Nil)
			]));
};
var $author$project$Page$viewMobileNavLink = F4(
	function (path, icon, myRoute, route) {
		var color = _Utils_eq(myRoute, route) ? $author$project$Type$Color$hiliteBlue : 'gray';
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('link dim dib black'),
					A2($elm$html$Html$Attributes$style, 'outline', 'none'),
					$elm$html$Html$Attributes$href(path)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '2em'),
							A2($elm$html$Html$Attributes$style, 'height', '2em')
						]),
					_List_fromArray(
						[
							icon(color)
						]))
				]));
	});
var $author$project$Page$viewMobileNav = F2(
	function (_v0, session) {
		var route = $author$project$Type$Session$route(session);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-white avenir fixed w-100 tc bottom-0 bt b--black-20'),
					A2($elm$html$Html$Attributes$style, 'z-index', '3000')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$nav,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 fw4 tracked flex justify-between pv2 ph4')
						]),
					_List_fromArray(
						[
							A4($author$project$Page$viewMobileNavLink, '/home', $author$project$Ox$Icon$home, $author$project$Route$Home, route),
							A4($author$project$Page$viewMobileNavLink, '/sets', $author$project$Ox$Icon$items, $author$project$Route$SetList, route),
							A4($author$project$Page$viewMobileNavLink, '/announcements', $author$project$Ox$Icon$clubhouse, $author$project$Route$Announcement, route)
						]))
				]));
	});
var $author$project$Page$viewHeaderSignedIn = F3(
	function (model, session, user) {
		var screenSize = $author$project$Type$Session$screenSize(session);
		var viewNav = function () {
			switch (screenSize.$) {
				case 0:
					return A2($author$project$Page$viewMobileNav, model, session);
				case 1:
					return A2($author$project$Page$viewMobileNav, model, session);
				default:
					return A2($author$project$Page$viewDesktopNav, model, session);
			}
		}();
		return viewNav;
	});
var $author$project$Page$viewHeader = F2(
	function (model, session) {
		if (session.$ === 3) {
			var user = session.b;
			return A3($author$project$Page$viewHeaderSignedIn, model, session, user);
		} else {
			return $author$project$Page$viewHeaderGuest;
		}
	});
var $author$project$Page$Announcement$New = {$: 2};
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Ox$Theme$btnBorderColor = '#ccc';
var $author$project$Ox$Theme$btnTextColor = '#333';
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Ox$Button$outlinedIcon = F3(
	function (title, icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$btnBorderColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$btnTextColor),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '18px'),
							A2($elm$html$Html$Attributes$style, 'height', '18px')
						]),
					_List_fromArray(
						[icon])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-left', '8px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Ox$Icon$plus = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z')
					]),
				_List_Nil)
			]));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Ox$Theme$desktopModalMaskStyles = function (attributes) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0.5)'),
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'backdrop-filter', 'blur(5px)'),
				A2($elm$html$Html$Attributes$style, '-webkit-backdrop-filter', 'blur(5px)'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000')
			]),
		attributes);
};
var $author$project$Ox$Theme$mobileModalMaskStyles = function (attributes) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(0,0,0,0.5)'),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%'),
				A2($elm$html$Html$Attributes$style, 'z-index', '5000')
			]),
		attributes);
};
var $author$project$Ox$Theme$desktopModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'max-height', '90%'),
		A2($elm$html$Html$Attributes$style, 'width', '840px'),
		A2($elm$html$Html$Attributes$style, 'margin-top', '60px'),
		A2($elm$html$Html$Attributes$style, 'padding', '0'),
		A2($elm$html$Html$Attributes$style, 'border-radius', '6px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)'),
		$elm$html$Html$Attributes$class('animated fadeIn faster')
	]);
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Modal$Announcement$mobileModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)')
	]);
var $author$project$Modal$Announcement$Close = {$: 2};
var $author$project$Modal$Announcement$viewCloseOrCancelBtn = function (model) {
	return model.az ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph3 pv2 f5 pointer'),
				$elm$html$Html$Events$onClick($author$project$Modal$Announcement$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph3 pv2 f5 pointer'),
				$elm$html$Html$Events$onClick($author$project$Modal$Announcement$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Close')
			]));
};
var $author$project$Modal$Announcement$Create = function (a) {
	return {$: 0, a: a};
};
var $author$project$Modal$Announcement$Save = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$Announcement$saveOrCreateMsg = function (model) {
	return model.an ? $author$project$Modal$Announcement$Create(model.i) : $author$project$Modal$Announcement$Save(model.i);
};
var $author$project$Modal$Announcement$viewSaveBtn = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('br2 ba b--black-20 ph3 pv2 f5 pointer mr3'),
				$elm$html$Html$Events$onClick(
				$author$project$Modal$Announcement$saveOrCreateMsg(model))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Save')
			]));
};
var $author$project$Modal$Announcement$viewEditMenu = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Announcement$viewCloseOrCancelBtn(model),
				$author$project$Modal$Announcement$viewSaveBtn(model)
			]));
};
var $author$project$Type$Screen$toScrollTop = function (size) {
	switch (size.$) {
		case 0:
			var scrollTop = size.b;
			return scrollTop;
		case 1:
			var scrollTop = size.b;
			return scrollTop;
		case 2:
			var scrollTop = size.b;
			return scrollTop;
		default:
			var scrollTop = size.b;
			return scrollTop;
	}
};
var $author$project$Page$getMobileTitleClassStyles = F3(
	function (title, screenSize, kind) {
		if (!kind) {
			return ($author$project$Type$Screen$toScrollTop(screenSize) > 44) ? _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 fw6 tc fadeIn animated flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
				'bg-white avenir fixed w-100 top-0 flex flex-column justify-center bb b--black-20') : _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fadeOut animated')
						]),
					_List_Nil),
				'bg-white avenir fixed w-100 top-0 tl flex flex-column justify-center');
		} else {
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 fw6 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
				'bg-white avenir fixed w-100 top-0 flex justify-between bb b--black-20');
		}
	});
var $author$project$Page$viewMobileHeader = F4(
	function (title, screenSize, kind, _v0) {
		var leftMenu = _v0.a;
		var rightMenu = _v0.b;
		var _v1 = A3($author$project$Page$getMobileTitleClassStyles, title, screenSize, kind);
		var newTitle = _v1.a;
		var classStyle = _v1.b;
		var mobileView = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classStyle),
					A2($elm$html$Html$Attributes$style, 'z-index', '2000'),
					A2($elm$html$Html$Attributes$style, 'height', '48px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-100'),
							A2($elm$html$Html$Attributes$style, 'display', 'grid'),
							A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr 1fr')
						]),
					_List_fromArray(
						[leftMenu, newTitle, rightMenu]))
				]));
		switch (screenSize.$) {
			case 0:
				return mobileView;
			case 1:
				return mobileView;
			default:
				return A2($elm$html$Html$span, _List_Nil, _List_Nil);
		}
	});
var $author$project$Modal$Announcement$viewMobileHeader = function (model) {
	var rightMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pl2 f5 pointer tr pv2 pr3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick(
				$author$project$Modal$Announcement$saveOrCreateMsg(model))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Save')
			]));
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pr2 f5 pointer tl pv2 pl3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$Announcement$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		'title',
		model.bP,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Modal$Announcement$Title = 1;
var $author$project$Modal$Announcement$UpdateInput = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Modal$Announcement$viewEditField = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ph2 w-100 f3 fw6 pa1'),
						$elm$html$Html$Attributes$value(model.i.b$),
						$elm$html$Html$Events$onInput(
						$author$project$Modal$Announcement$UpdateInput(1)),
						$elm$html$Html$Attributes$name('titleField')
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Announcement$viewPageTitle = function (model) {
	var viewerMobile = A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tl flex flex-column justify-between ph3 mb2 pb2 bb b--black-20')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Announcement$viewEditField(model)
			]));
	var viewerDesktop = A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tl flex justify-between items-center ph2 mv4 mh2')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Announcement$viewEditField(model)
			]));
	var _v0 = model.bP;
	switch (_v0.$) {
		case 0:
			return viewerMobile;
		case 1:
			return viewerMobile;
		default:
			return viewerDesktop;
	}
};
var $author$project$Modal$Announcement$Text = 0;
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Modal$Announcement$viewColumnA = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-70-ns pa2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('body-edit'),
						$elm$html$Html$Attributes$value(model.i.aw),
						$elm$html$Html$Attributes$class('ph2 w-100 f4 pa2'),
						$elm$html$Html$Attributes$rows(8),
						$elm$html$Html$Events$onInput(
						$author$project$Modal$Announcement$UpdateInput(0))
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$Announcement$ColorBg = 3;
var $author$project$Modal$Announcement$ColorText = 2;
var $author$project$Modal$Announcement$ColorTint = 4;
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Page$viewColorField = F3(
	function (labelStr, valueStr, msg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pb3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(labelStr)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt1 ba b--black-20 br2 relative')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f5 ph2 pa1 absolute br2 ba b--black-20 code'),
									A2($elm$html$Html$Attributes$style, 'width', '20px'),
									A2($elm$html$Html$Attributes$style, 'height', '20px'),
									A2($elm$html$Html$Attributes$style, 'background-color', '#' + valueStr),
									A2($elm$html$Html$Attributes$style, 'top', '3px'),
									A2($elm$html$Html$Attributes$style, 'left', '3px')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('br f5 ph2 pa1 absolute'),
									A2($elm$html$Html$Attributes$style, 'top', '0px'),
									A2($elm$html$Html$Attributes$style, 'left', '26px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('#')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ph2 w-100 f5 pa1 tracked code'),
									A2($elm$html$Html$Attributes$style, 'border', '0'),
									A2($elm$html$Html$Attributes$style, 'background-color', 'transparent'),
									A2($elm$html$Html$Attributes$style, 'padding-left', '60px'),
									A2($elm$html$Html$Attributes$style, 'left', '52px'),
									$elm$html$Html$Attributes$value(valueStr),
									$elm$html$Html$Events$onInput(msg)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Modal$Announcement$viewColors = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$Page$viewColorField,
				'Text',
				model.i.M.aw,
				$author$project$Modal$Announcement$UpdateInput(2)),
				A3(
				$author$project$Page$viewColorField,
				'Background',
				model.i.M.a3,
				$author$project$Modal$Announcement$UpdateInput(3)),
				A3(
				$author$project$Page$viewColorField,
				'Tint',
				model.i.M.b_,
				$author$project$Modal$Announcement$UpdateInput(4))
			]));
};
var $author$project$Modal$Announcement$OpenDatePicker = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Type$DateTime$toEnglishMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $author$project$Type$DateTime$intToDate = F2(
	function (zone, timestamp) {
		var posixTime = $elm$time$Time$millisToPosix(timestamp);
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, posixTime));
		var month = $author$project$Type$DateTime$toEnglishMonth(
			A2($elm$time$Time$toMonth, zone, posixTime));
		var day = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, posixTime));
		return month + (' ' + (day + (', ' + year)));
	});
var $author$project$Type$DateTime$addLeadingZero = function (numString) {
	return ($elm$core$String$length(numString) < 2) ? ('0' + numString) : numString;
};
var $author$project$Type$DateTime$intToTime = F2(
	function (zone, timestamp) {
		var posixTime = $elm$time$Time$millisToPosix(timestamp);
		var minute = $author$project$Type$DateTime$addLeadingZero(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toMinute, zone, posixTime)));
		var hour = $author$project$Type$DateTime$addLeadingZero(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toHour, zone, posixTime)));
		return hour + (':' + minute);
	});
var $author$project$Type$DateTime$toEnglishWeekday = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Mon';
		case 1:
			return 'Tue';
		case 2:
			return 'Wed';
		case 3:
			return 'Thu';
		case 4:
			return 'Fri';
		case 5:
			return 'Sat';
		default:
			return 'Sun';
	}
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $author$project$Type$DateTime$intToWeekday = F2(
	function (zone, timestamp) {
		return $author$project$Type$DateTime$toEnglishWeekday(
			A2(
				$elm$time$Time$toWeekday,
				zone,
				$elm$time$Time$millisToPosix(timestamp)));
	});
var $author$project$Modal$Announcement$viewDate = function (model) {
	var id = model.i.bn;
	var datePublished = model.i.aI;
	var time = A2($author$project$Type$DateTime$intToTime, model.bZ, datePublished);
	var weekday = A2($author$project$Type$DateTime$intToWeekday, model.bZ, datePublished);
	var date = A2($author$project$Type$DateTime$intToDate, model.bZ, datePublished);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ba b--black-20 br2 f5 ph2 pa1 fw3 ttu mt1 pointer mb3'),
				$elm$html$Html$Events$onClick(
				A2($author$project$Modal$Announcement$OpenDatePicker, id, datePublished))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(weekday),
				$elm$html$Html$text(' '),
				$elm$html$Html$text(date),
				$elm$html$Html$text(' '),
				$elm$html$Html$text(time)
			]));
};
var $author$project$Ox$DatePicker$Cancel = {$: 8};
var $author$project$Ox$DatePicker$Save = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ox$Button$textLink = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$btnTextColor),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 15px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Ox$DatePicker$viewActionBar = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 f5 fw5 flex items-center justify-end mt2 b--black-10 ph3')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$textLink,
				'Cancel',
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Ox$DatePicker$Cancel)
					])),
				A2(
				$author$project$Ox$Button$textLink,
				'OK',
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Ox$DatePicker$Save(model.b))
					]))
			]));
};
var $author$project$Ox$DatePicker$OpenPanel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ox$DatePicker$Time = 3;
var $author$project$Ox$DatePicker$Year = 2;
var $author$project$Ox$Icon$clock = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$DatePicker$dayString = F2(
	function (timeZone, posix) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, timeZone, posix));
	});
var $author$project$Ox$DatePicker$addLeadingZero = function (numString) {
	return ($elm$core$String$length(numString) < 2) ? ('0' + numString) : numString;
};
var $author$project$Ox$DatePicker$hourString = F2(
	function (timeZone, posix) {
		return $author$project$Ox$DatePicker$addLeadingZero(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toHour, timeZone, posix)));
	});
var $author$project$Ox$DatePicker$minuteString = F2(
	function (timeZone, posix) {
		return $author$project$Ox$DatePicker$addLeadingZero(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toMinute, timeZone, posix)));
	});
var $author$project$Type$DateTime$toShortEnglishMonth = function (month) {
	switch (month) {
		case 0:
			return 'Jan';
		case 1:
			return 'Feb';
		case 2:
			return 'Mar';
		case 3:
			return 'Apr';
		case 4:
			return 'May';
		case 5:
			return 'Jun';
		case 6:
			return 'Jul';
		case 7:
			return 'Aug';
		case 8:
			return 'Sep';
		case 9:
			return 'Oct';
		case 10:
			return 'Nov';
		default:
			return 'Dec';
	}
};
var $author$project$Ox$DatePicker$monthString = F2(
	function (timeZone, posix) {
		return $author$project$Type$DateTime$toShortEnglishMonth(
			A2($elm$time$Time$toMonth, timeZone, posix));
	});
var $author$project$Ox$Theme$primaryDarkColor = '#8d8d8d';
var $author$project$Ox$Theme$primaryLightColor = '#efefef';
var $author$project$Ox$DatePicker$weekdayString = F2(
	function (timeZone, posix) {
		return $author$project$Type$DateTime$toEnglishWeekday(
			A2($elm$time$Time$toWeekday, timeZone, posix));
	});
var $author$project$Ox$DatePicker$yearString = F2(
	function (timeZone, posix) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, timeZone, posix));
	});
var $author$project$Ox$DatePicker$viewHeader = function (model) {
	var yearStr = A2($author$project$Ox$DatePicker$yearString, model.bZ, model.b);
	var weekdayStr = A2($author$project$Ox$DatePicker$weekdayString, model.bZ, model.b);
	var monthStr = A2($author$project$Ox$DatePicker$monthString, model.bZ, model.b);
	var minuteStr = A2($author$project$Ox$DatePicker$minuteString, model.bZ, model.b);
	var hourStr = A2($author$project$Ox$DatePicker$hourString, model.bZ, model.b);
	var theSimpleTime = hourStr + (':' + minuteStr);
	var dayStr = A2($author$project$Ox$DatePicker$dayString, model.bZ, model.b);
	var headerText = weekdayStr + (', ' + (monthStr + (' ' + dayStr)));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2 flex justify-between flex-column'),
						A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryLightColor),
						A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor),
						A2($elm$html$Html$Attributes$style, 'height', '64px'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '8px 8px 0 0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f6 pointer'),
								$elm$html$Html$Events$onClick(
								$author$project$Ox$DatePicker$OpenPanel(2))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(yearStr)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex justify-between items-end')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('f4 flex pointer'),
										$elm$html$Html$Events$onClick(
										$author$project$Ox$DatePicker$OpenPanel(1))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(headerText)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex pointer'),
										$elm$html$Html$Events$onClick(
										$author$project$Ox$DatePicker$OpenPanel(3))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mr1'),
												A2($elm$html$Html$Attributes$style, 'height', '16px'),
												A2($elm$html$Html$Attributes$style, 'width', '16px')
											]),
										_List_fromArray(
											[
												$author$project$Ox$Icon$clock($author$project$Ox$Theme$primaryLightColor)
											])),
										$elm$html$Html$text(theSimpleTime)
									]))
							]))
					]))
			]));
};
var $author$project$Ox$DatePicker$SetDay = function (a) {
	return {$: 2, a: a};
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Ox$DatePicker$SetMonth = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ox$Icon$navNext = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Theme$primaryTextColor = '#000000';
var $author$project$Ox$DatePicker$navNextMonth = function (month) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointer inline-flex items-center'),
				$elm$html$Html$Events$onClick(
				$author$project$Ox$DatePicker$SetMonth(month))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'width', '24px'),
						A2($elm$html$Html$Attributes$style, 'height', '24px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$navNext($author$project$Ox$Theme$primaryTextColor)
					]))
			]));
};
var $author$project$Ox$Icon$navPrev = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$DatePicker$navPrevMonth = function (month) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointer inline-flex items-center'),
				$elm$html$Html$Events$onClick(
				$author$project$Ox$DatePicker$SetMonth(month))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'width', '24px'),
						A2($elm$html$Html$Attributes$style, 'height', '24px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$navPrev($author$project$Ox$Theme$primaryTextColor)
					]))
			]));
};
var $author$project$Type$DateTime$intToMonth = function (num) {
	switch (num) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		case 12:
			return 11;
		default:
			return 0;
	}
};
var $author$project$Type$DateTime$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$Type$DateTime$nextMonth = function (month) {
	var nextMonthNum = 1 + $author$project$Type$DateTime$monthToInt(month);
	return (nextMonthNum > 12) ? 0 : $author$project$Type$DateTime$intToMonth(nextMonthNum);
};
var $author$project$Type$DateTime$prevMonth = function (month) {
	var prevMonthNum = $author$project$Type$DateTime$monthToInt(month) - 1;
	return (prevMonthNum < 1) ? 11 : $author$project$Type$DateTime$intToMonth(prevMonthNum);
};
var $author$project$Ox$DatePicker$Month = 0;
var $author$project$Ox$DatePicker$monthLongString = F2(
	function (timeZone, posix) {
		return $author$project$Type$DateTime$toEnglishMonth(
			A2($elm$time$Time$toMonth, timeZone, posix));
	});
var $author$project$Ox$DatePicker$viewSelectSegment = F3(
	function (curPanel, panel, title) {
		return _Utils_eq(panel, curPanel) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pv1 bb b--black-40 ph1'),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryTextColor)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pv1 pointer ph1'),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryTextColor),
					$elm$html$Html$Events$onClick(
					$author$project$Ox$DatePicker$OpenPanel(panel))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]));
	});
var $author$project$Ox$DatePicker$viewDateSegmentPicker = function (model) {
	var yearStr = A2($author$project$Ox$DatePicker$yearString, model.bZ, model.b);
	var monthStr = A2($author$project$Ox$DatePicker$monthLongString, model.bZ, model.b);
	var dayStr = A2($author$project$Ox$DatePicker$dayString, model.bZ, model.b);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex')
			]),
		_List_fromArray(
			[
				A3($author$project$Ox$DatePicker$viewSelectSegment, model.w, 0, monthStr),
				A3($author$project$Ox$DatePicker$viewSelectSegment, model.w, 2, yearStr)
			]));
};
var $author$project$Ox$DatePicker$viewDateNav = function (model) {
	var theMonth = A2($elm$time$Time$toMonth, model.bZ, model.b);
	var prevMonth = $author$project$Type$DateTime$prevMonth(theMonth);
	var nextMonth = $author$project$Type$DateTime$nextMonth(theMonth);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2 f5 fw5 flex items-center justify-between mt1 w-100')
			]),
		_List_fromArray(
			[
				$author$project$Ox$DatePicker$navPrevMonth(prevMonth),
				$author$project$Ox$DatePicker$viewDateSegmentPicker(model),
				$author$project$Ox$DatePicker$navNextMonth(nextMonth)
			]));
};
var $author$project$Ox$DatePicker$viewDaysOfWeek = function () {
	var classes = 'tuc tc f6';
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Sun')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Mon')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Tue')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Wed')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Thu')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Fri')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Sat')
				]))
		]);
}();
var $author$project$Ox$DatePicker$viewEmptyDay = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('')
		]));
var $author$project$Ox$DatePicker$viewNumberBtn = F3(
	function (msg, currNum, num) {
		return _Utils_eq(currNum, num) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center justify-center'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$colorWhite),
					A2($elm$html$Html$Attributes$style, 'border-radius', '20px')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(num))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('h-100 w-100 pointer flex items-center justify-center'),
					$elm$html$Html$Events$onClick(
					msg(num))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(num))
				]));
	});
var $waratuman$time_extra$Time$Extra$weekdayToInt = function (d) {
	switch (d) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $author$project$Ox$DatePicker$viewDayPicker = function (model) {
	var year = A2($elm$time$Time$toYear, model.bZ, model.b);
	var month = A2($elm$time$Time$toMonth, model.bZ, model.b);
	var lastDay = A2($waratuman$time_extra$Time$Extra$daysInMonth, year, month);
	var range = A2($elm$core$List$range, 1, lastDay);
	var day = A2($elm$time$Time$toDay, model.bZ, model.b);
	var countEmptyDays = $waratuman$time_extra$Time$Extra$weekdayToInt(
		A2(
			$elm$time$Time$toWeekday,
			$elm$time$Time$utc,
			A2(
				$waratuman$time_extra$Time$Extra$fromDateTuple,
				$elm$time$Time$utc,
				_Utils_Tuple3(year, month, 1))));
	var emptyWeekdays = A2($elm$core$List$repeat, countEmptyDays, $author$project$Ox$DatePicker$viewEmptyDay);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 flex flex-column items-center')
			]),
		_List_fromArray(
			[
				$author$project$Ox$DatePicker$viewDateNav(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt2 pa1'),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', 'repeat(7, 40px)'),
						A2($elm$html$Html$Attributes$style, 'grid-auto-rows', '40px')
					]),
				_Utils_ap(
					$author$project$Ox$DatePicker$viewDaysOfWeek,
					_Utils_ap(
						emptyWeekdays,
						A2(
							$elm$core$List$map,
							A2($author$project$Ox$DatePicker$viewNumberBtn, $author$project$Ox$DatePicker$SetDay, day),
							range))))
			]));
};
var $author$project$Type$DateTime$monthList = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $author$project$Ox$DatePicker$viewMonthBtn = F3(
	function (currMonth, index, month) {
		var monthNumber = index + 1;
		var monthName = $author$project$Type$DateTime$toShortEnglishMonth(month);
		return _Utils_eq(monthNumber, currMonth) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba br1 ph2 pv1 ma1 tc pointer'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$colorWhite),
					A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
					$elm$html$Html$Events$onClick(
					$author$project$Ox$DatePicker$SetMonth(month))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(monthName)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ph2 pv1 ma1 tc pointer'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid transparent'),
					$elm$html$Html$Events$onClick(
					$author$project$Ox$DatePicker$SetMonth(month))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(monthName)
				]));
	});
var $author$project$Ox$DatePicker$viewMonthPicker = function (model) {
	var selectedMonth = $waratuman$time_extra$Time$Extra$monthToInt(
		A2($elm$time$Time$toMonth, model.bZ, model.b));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 flex flex-column items-center')
			]),
		_List_fromArray(
			[
				$author$project$Ox$DatePicker$viewDateNav(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt2 pa1'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', 'repeat(3, 1fr)')
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Ox$DatePicker$viewMonthBtn(selectedMonth),
					$author$project$Type$DateTime$monthList))
			]));
};
var $author$project$Ox$DatePicker$HourField = 0;
var $author$project$Ox$DatePicker$MinuteField = 1;
var $author$project$Ox$DatePicker$SecondField = 2;
var $author$project$Ox$DatePicker$SetFieldFocus = function (a) {
	return {$: 9, a: a};
};
var $author$project$Ox$DatePicker$SetHour = function (a) {
	return {$: 4, a: a};
};
var $author$project$Ox$DatePicker$SetMinute = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ox$DatePicker$SetSecond = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ox$DatePicker$UnsetFieldFocus = function (a) {
	return {$: 10, a: a};
};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Ox$Theme$Activated = 2;
var $author$project$Ox$Theme$Focused = 0;
var $author$project$Ox$Theme$Inactive = 1;
var $author$project$Ox$DatePicker$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.aM,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Ox$Theme$colorForHelper = function (kind) {
	if (kind === 'error') {
		return $author$project$Ox$Theme$colorDanger;
	} else {
		return $author$project$Ox$Theme$colorDark;
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Ox$Theme$primaryColor = '#bdbdbd';
var $author$project$Ox$Text$outlined = F4(
	function (labelName, state, inputAttrs, maybeAlert) {
		var labelAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
				A2($elm$html$Html$Attributes$style, 'top', '50%'),
				A2($elm$html$Html$Attributes$style, 'left', '16px'),
				A2($elm$html$Html$Attributes$style, 'right', 'initial'),
				A2($elm$html$Html$Attributes$style, 'transform-origin', 'left top'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
				$elm$html$Html$Attributes$class('infoLabel')
			]);
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, '-webkit-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, '-moz-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'padding', '6px 14px 6px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
					A2($elm$html$Html$Attributes$style, 'height', '56px'),
					A2($elm$html$Html$Attributes$style, 'box-sizing', 'border-box')
				]),
			inputAttrs);
		var helper = function () {
			if (!maybeAlert.$) {
				var alert = maybeAlert.a;
				var color = $author$project$Ox$Theme$colorForHelper(alert.cK);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'font-size', '12px'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '4px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(alert.bv)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var _v0 = function () {
			switch (state) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryDarkColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.4)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid rgba(128,128,128,.8)'),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-185%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
								$elm$html$Html$Attributes$disabled(true),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)')
							]));
			}
		}();
		var addLabelAttributes = _v0.a;
		var addInputAttributes = _v0.b;
		var newInputAttributes = _Utils_ap(inputAttributes, addInputAttributes);
		var newLabelAttributes = _Utils_ap(labelAttributes, addLabelAttributes);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative'),
					A2($elm$html$Html$Attributes$style, 'height', '56px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '4px 4px 0 0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					newLabelAttributes,
					_List_fromArray(
						[
							$elm$html$Html$text(labelName)
						])),
					A2($elm$html$Html$input, newInputAttributes, _List_Nil),
					helper
				]));
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Ox$DatePicker$viewTimePicker = function (model) {
	var selectedSecond = A2($elm$time$Time$toSecond, model.bZ, model.b);
	var selectedMinute = A2($elm$time$Time$toMinute, model.bZ, model.b);
	var selectedHour = A2($elm$time$Time$toHour, model.bZ, model.b);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mv2 pt2 flex tc f4 items-center justify-center'),
				A2($elm$html$Html$Attributes$style, 'min-height', '152px'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', 'repeat(6, 1fr)')
			]),
		_List_fromArray(
			[
				A4(
				$author$project$Ox$Text$outlined,
				'Hour',
				A3(
					$author$project$Ox$DatePicker$labelState,
					model,
					0,
					$elm$core$String$fromInt(selectedHour)),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('hourField'),
						$elm$html$Html$Attributes$autocomplete(false),
						$elm$html$Html$Events$onFocus(
						$author$project$Ox$DatePicker$SetFieldFocus(0)),
						$elm$html$Html$Events$onBlur(
						$author$project$Ox$DatePicker$UnsetFieldFocus(0)),
						$elm$html$Html$Attributes$type_('number'),
						$elm$html$Html$Attributes$min('0'),
						$elm$html$Html$Attributes$max('23'),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(selectedHour)),
						$elm$html$Html$Events$onInput($author$project$Ox$DatePicker$SetHour),
						$elm$html$Html$Attributes$name('hour')
					]),
				$elm$core$Maybe$Nothing),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pl1 pr2 f5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(':')
					])),
				A4(
				$author$project$Ox$Text$outlined,
				'Minute',
				A3(
					$author$project$Ox$DatePicker$labelState,
					model,
					1,
					$elm$core$String$fromInt(selectedMinute)),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('minuteField'),
						$elm$html$Html$Attributes$autocomplete(false),
						$elm$html$Html$Events$onFocus(
						$author$project$Ox$DatePicker$SetFieldFocus(1)),
						$elm$html$Html$Events$onBlur(
						$author$project$Ox$DatePicker$UnsetFieldFocus(1)),
						$elm$html$Html$Attributes$type_('number'),
						$elm$html$Html$Attributes$min('0'),
						$elm$html$Html$Attributes$max('59'),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(selectedMinute)),
						$elm$html$Html$Events$onInput($author$project$Ox$DatePicker$SetMinute),
						$elm$html$Html$Attributes$name('minute')
					]),
				$elm$core$Maybe$Nothing),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pl1 pr2 f5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(':')
					])),
				A4(
				$author$project$Ox$Text$outlined,
				'Second',
				A3(
					$author$project$Ox$DatePicker$labelState,
					model,
					2,
					$elm$core$String$fromInt(selectedSecond)),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('secondField'),
						$elm$html$Html$Attributes$autocomplete(false),
						$elm$html$Html$Events$onFocus(
						$author$project$Ox$DatePicker$SetFieldFocus(2)),
						$elm$html$Html$Events$onBlur(
						$author$project$Ox$DatePicker$UnsetFieldFocus(2)),
						$elm$html$Html$Attributes$type_('number'),
						$elm$html$Html$Attributes$min('0'),
						$elm$html$Html$Attributes$max('59'),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(selectedSecond)),
						$elm$html$Html$Events$onInput($author$project$Ox$DatePicker$SetSecond),
						$elm$html$Html$Attributes$name('second')
					]),
				$elm$core$Maybe$Nothing)
			]));
};
var $author$project$Ox$DatePicker$SetYear = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ox$DatePicker$viewYearBtn = F3(
	function (msg, currNum, num) {
		return _Utils_eq(currNum, num) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center justify-center pointer'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$colorWhite),
					A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'width', '72px'),
					$elm$html$Html$Events$onClick(
					msg(num))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(num))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer flex items-center justify-center'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'width', '72px'),
					$elm$html$Html$Events$onClick(
					msg(num))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(num))
				]));
	});
var $author$project$Ox$DatePicker$viewYearPicker = function (model) {
	var year = A2($elm$time$Time$toYear, model.bZ, model.b);
	var range = A2($elm$core$List$range, 2021, 2022);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100')
			]),
		_List_fromArray(
			[
				$author$project$Ox$DatePicker$viewDateNav(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt2 '),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'padding', '24px'),
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', 'repeat(3, 88px)'),
						A2($elm$html$Html$Attributes$style, 'grid-auto-rows', '52px'),
						A2($elm$html$Html$Attributes$style, 'min-height', '152px')
					]),
				A2(
					$elm$core$List$map,
					A2($author$project$Ox$DatePicker$viewYearBtn, $author$project$Ox$DatePicker$SetYear, year),
					range))
			]));
};
var $author$project$Ox$DatePicker$viewPanel = function (model) {
	var _v0 = model.w;
	switch (_v0) {
		case 0:
			return $author$project$Ox$DatePicker$viewMonthPicker(model);
		case 1:
			return $author$project$Ox$DatePicker$viewDayPicker(model);
		case 2:
			return $author$project$Ox$DatePicker$viewYearPicker(model);
		default:
			return $author$project$Ox$DatePicker$viewTimePicker(model);
	}
};
var $author$project$Ox$DatePicker$view = function (model) {
	var _v0 = model.bP;
	if (!_v0.$) {
		return A2(
			$elm$html$Html$div,
			$author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bg-near-white relative flex flex-column items-end'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
							A2($elm$html$Html$Attributes$style, 'bottom', '0'),
							A2($elm$html$Html$Attributes$style, 'left', '0'),
							$elm$html$Html$Attributes$class('animated slideInUp faster'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '8px 8px 0 0')
						]),
					_List_fromArray(
						[
							$author$project$Ox$DatePicker$viewHeader(model),
							$author$project$Ox$DatePicker$viewPanel(model),
							$author$project$Ox$DatePicker$viewActionBar(model)
						]))
				]));
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('br2 bg-near-white relative flex flex-column items-center'),
					A2($elm$html$Html$Attributes$style, 'z-index', '5000'),
					A2($elm$html$Html$Attributes$style, 'width', '328px'),
					A2($elm$html$Html$Attributes$style, 'box-shadow', '2px 2px 5px rgba(0,0,0,0.1)')
				]),
			_List_fromArray(
				[
					$author$project$Ox$DatePicker$viewHeader(model),
					$author$project$Ox$DatePicker$viewPanel(model),
					$author$project$Ox$DatePicker$viewActionBar(model)
				]));
	}
};
var $author$project$Modal$Announcement$viewPicker = F2(
	function (model, datePickerModel) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative'),
					A2($elm$html$Html$Attributes$style, 'z-index', '6000')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute'),
							A2($elm$html$Html$Attributes$style, 'left', '0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Modal$Announcement$DatePickerMsg,
							$author$project$Ox$DatePicker$view(datePickerModel))
						])),
					$author$project$Modal$Announcement$viewDate(model)
				]));
	});
var $author$project$Modal$Announcement$viewDateOrPicker = F2(
	function (model, announcement) {
		var _v0 = model.z;
		if (_v0.b.$ === 1) {
			var _v1 = _v0.b;
			return $author$project$Modal$Announcement$viewDate(model);
		} else {
			var id = _v0.a;
			var datePickerModel = _v0.b.a;
			return _Utils_eq(id, announcement.bn) ? A2($author$project$Modal$Announcement$viewPicker, model, datePickerModel) : $author$project$Modal$Announcement$viewDate(model);
		}
	});
var $author$project$Modal$Announcement$viewColumnB = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-30-ns pa2 tl')
			]),
		_List_fromArray(
			[
				A2($author$project$Modal$Announcement$viewDateOrPicker, model, model.i),
				$author$project$Modal$Announcement$viewColors(model)
			]));
};
var $author$project$Modal$Announcement$viewSection = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('cf ph2-ns')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Announcement$viewColumnA(model),
				$author$project$Modal$Announcement$viewColumnB(model)
			]));
};
var $author$project$Page$viewShimTop = function (paddingTop) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tl flex justify-between items-center'),
				A2($elm$html$Html$Attributes$style, 'padding-top', paddingTop)
			]),
		_List_Nil);
};
var $author$project$Page$viewMobileShim = $author$project$Page$viewShimTop('60px');
var $author$project$Page$viewShim = function (screenSize) {
	switch (screenSize.$) {
		case 0:
			return $author$project$Page$viewMobileShim;
		case 1:
			return $author$project$Page$viewMobileShim;
		default:
			return $author$project$Page$viewShimTop('80px');
	}
};
var $author$project$Modal$Announcement$viewModal = function (model) {
	var viewMobileModal = A2(
		$elm$html$Html$div,
		$author$project$Modal$Announcement$mobileModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						$elm$html$Html$Attributes$class('overflow-y-scroll'),
						$elm$html$Html$Attributes$id('print-grid')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim(model.bP),
						$author$project$Modal$Announcement$viewPageTitle(model),
						$author$project$Modal$Announcement$viewSection(model),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tc mt2 pb2')
							]),
						_List_Nil)
					])),
				$author$project$Modal$Announcement$viewMobileHeader(model)
			]));
	var viewDesktopModal = A2(
		$elm$html$Html$div,
		$author$project$Ox$Theme$desktopModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 bg-white'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '6px')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShimTop('10px'),
						$author$project$Modal$Announcement$viewEditMenu(model),
						$author$project$Modal$Announcement$viewPageTitle(model),
						$author$project$Modal$Announcement$viewSection(model)
					]))
			]));
	var _v0 = model.bP;
	switch (_v0.$) {
		case 0:
			return viewMobileModal;
		case 1:
			return viewMobileModal;
		default:
			return viewDesktopModal;
	}
};
var $author$project$Modal$Announcement$view = function (model) {
	var modalStyles = function () {
		var _v0 = model.bP;
		switch (_v0.$) {
			case 0:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			case 1:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			default:
				return $author$project$Ox$Theme$desktopModalMaskStyles(_List_Nil);
		}
	}();
	return A2(
		$elm$html$Html$div,
		modalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Modal$Announcement$viewModal(model)
					]))
			]));
};
var $author$project$Page$Announcement$viewModalOrNot = function (model) {
	var _v0 = model.A;
	if (!_v0.$) {
		var modalModel = _v0.a;
		return A2(
			$elm$html$Html$map,
			$author$project$Page$Announcement$ModalMsg,
			$author$project$Modal$Announcement$view(modalModel));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$viewPageTitle = F3(
	function (title, screenSize, htmlMsg) {
		var classStyle = function () {
			switch (screenSize.$) {
				case 0:
					return 'tl flex justify-between items-center ph2 mb2 pb2 bb b--black-20';
				case 1:
					return 'tl flex justify-between items-center ph2 mb2 pb2 bb b--black-20';
				default:
					return 'tl flex justify-between items-center ph2 mb2';
			}
		}();
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classStyle)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f3 fw7 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					htmlMsg
				]));
	});
var $author$project$Page$Announcement$Edit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Announcement$Remove = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$article = _VirtualDom_node('article');
var $author$project$Page$Announcement$OpenDatePicker = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Announcement$viewDate = F2(
	function (model, announcement) {
		var timeZone = $author$project$Type$Session$timeZone(model.db);
		var weekday = A2($author$project$Type$DateTime$intToWeekday, timeZone, announcement.aI);
		var time = A2($author$project$Type$DateTime$intToTime, timeZone, announcement.aI);
		var date = A2($author$project$Type$DateTime$intToDate, timeZone, announcement.aI);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw3 ttu mt1 pointer'),
					$elm$html$Html$Events$onClick(
					$author$project$Page$Announcement$OpenDatePicker(announcement))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(weekday),
					$elm$html$Html$text(' '),
					$elm$html$Html$text(date),
					$elm$html$Html$text(' '),
					$elm$html$Html$text(time)
				]));
	});
var $author$project$Page$Announcement$viewPicker = function (datePickerModel) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('relative'),
				A2($elm$html$Html$Attributes$style, 'z-index', '6000')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute'),
						A2($elm$html$Html$Attributes$style, 'left', '0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$Page$Announcement$DatePickerMsg,
						$author$project$Ox$DatePicker$view(datePickerModel))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f6 fw3 ttu mt1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('.')
					]))
			]));
};
var $author$project$Page$Announcement$viewDateOrPicker = F2(
	function (model, announcement) {
		var _v0 = model.z;
		if (!_v0.$) {
			var _v1 = _v0.a;
			var announce = _v1.a;
			var datePickerModel = _v1.b;
			return _Utils_eq(announce.bn, announcement.bn) ? $author$project$Page$Announcement$viewPicker(datePickerModel) : A2($author$project$Page$Announcement$viewDate, model, announcement);
		} else {
			return A2($author$project$Page$Announcement$viewDate, model, announcement);
		}
	});
var $author$project$Page$Announcement$viewAnnouncement = F2(
	function (model, announcement) {
		var viewButtonLayer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex mt2 justify-between')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pointer ba br1 pa1 f7 b--black-20'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$Announcement$Edit(announcement))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Edit')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pointer ba br1 pa1 f7 b--black-20'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$Announcement$Remove(announcement.bn))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Delete')
						]))
				]));
		return A2(
			$elm$html$Html$article,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pa2 ba br2 b--black-20 mb2')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Announcement$viewDateOrPicker, model, announcement),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('b mt1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(announcement.b$)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(announcement.aw)
						])),
					viewButtonLayer
				]));
	});
var $author$project$Page$Announcement$viewSection = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tl pt2 relative')
			]),
		A2(
			$elm$core$List$map,
			$author$project$Page$Announcement$viewAnnouncement(model),
			model.cd));
};
var $author$project$Page$Announcement$viewMain = F2(
	function (model, _v0) {
		var addBtn = A3(
			$author$project$Ox$Button$outlinedIcon,
			'New',
			$author$project$Ox$Icon$plus('black'),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Page$Announcement$New)
				]));
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							A3($author$project$Page$viewPageTitle, model.b$, model.bP, addBtn),
							$author$project$Page$Announcement$viewSection(model),
							$author$project$Page$Announcement$viewModalOrNot(model)
						]))
				]));
	});
var $author$project$Page$Announcement$viewMobileHeader = function (model) {
	var emptyMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	return A4(
		$author$project$Page$viewMobileHeader,
		model.b$,
		model.bP,
		0,
		_Utils_Tuple2(emptyMenu, emptyMenu));
};
var $author$project$Page$Announcement$viewBody = function (model) {
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Announcement$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Announcement$viewMain, model, user),
				$author$project$Page$viewFooter(model.bP),
				$author$project$Page$Announcement$viewMobileHeader(model)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$Announcement$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.b$),
		$author$project$Page$Announcement$viewBody(model));
};
var $author$project$Page$GetStarted$SetFieldFocus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$GetStarted$UnsetFieldFocus = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$GetStarted$UpdateInput = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Ox$Theme$colorMuted = '#ccc';
var $author$project$Ox$Button$contained = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'color', 'white'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Page$GetStarted$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.aM,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $author$project$Page$GetStarted$viewEnterEmail = function (model) {
	var sendBtn = $rtfeldman$elm_validate$Validate$isValidEmail(model.cp) ? A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(false),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor)
			])) : A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(true),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorMuted)
			]));
	var maybeError = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (x) {
				return x.bq === 'password';
			},
			model.cb));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(''),
				A2($elm$html$Html$Attributes$style, 'max-width', '328px'),
				A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f2 w400 pt5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('tĭbles')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 mb4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enter your '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw9')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('email')
							])),
						$elm$html$Html$text(' to sign in.')
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$GetStarted$CheckEmail),
						$elm$html$Html$Attributes$class('')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb4')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Email',
								A3($author$project$Page$GetStarted$labelState, model, 0, model.cp),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('email'),
										$elm$html$Html$Attributes$id('emailField'),
										$elm$html$Html$Attributes$value(model.cp),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Events$onInput(
										$author$project$Page$GetStarted$UpdateInput(0)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$GetStarted$SetFieldFocus(0)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$GetStarted$UnsetFieldFocus(0)),
										$elm$html$Html$Attributes$name('email')
									]),
								maybeError)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2')
							]),
						_List_fromArray(
							[sendBtn]))
					]))
			]));
};
var $author$project$Ox$Icon$chevronLeft = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 200 200'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M128.445,190.568c-0.114,-0.114 -90.581,-90.568 -90.581,-90.568l0.034,-0.034l-0.034,-0.035l90.617,-90.584l0.024,0.025c3.124,-3.098 8.176,-3.09 11.29,0.024c3.122,3.122 3.122,8.192 0,11.314c-0.092,0.091 -79.269,79.256 -79.269,79.256l79.268,79.269l-0.027,0.027c3.114,3.123 3.111,8.187 -0.008,11.306c-3.122,3.122 -8.192,3.122 -11.314,0Z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$viewHeaderGetStarted = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('absolute tl ph3 pv4')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('link pointer'),
					$elm$html$Html$Attributes$href('/')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
							A2($elm$html$Html$Attributes$style, 'height', '1.5em')
						]),
					_List_fromArray(
						[
							$author$project$Ox$Icon$chevronLeft('black')
						]))
				]))
		]));
var $author$project$Page$GetStarted$viewNoSignUp = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Please sign up with the mobile app!')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3 fw1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('This site currently only allows you to sign in ')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3 f3 fw1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('with an existing account.')
					]))
			]));
};
var $author$project$Page$GetStarted$FirebaseSignIn = {$: 5};
var $author$project$Page$GetStarted$SendPasswordReset = {$: 8};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$Attributes$minlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'minLength',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$GetStarted$validateSignIn = function (model) {
	return ($elm$core$String$length(model.C) > 5) ? true : false;
};
var $author$project$Page$GetStarted$viewPasswordSignIn = function (model) {
	var sendBtn = $author$project$Page$GetStarted$validateSignIn(model) ? A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(false),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryDarkColor)
			])) : A2(
		$author$project$Ox$Button$contained,
		'Submit',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$disabled(true),
				A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$colorMuted)
			]));
	var maybeError = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (x) {
				return x.bq === 'password';
			},
			model.cb));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(''),
				A2($elm$html$Html$Attributes$style, 'max-width', '328px'),
				A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f2 w400 pt5')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('tĭbles')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 mb3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Enter the '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw9')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('password')
							])),
						$elm$html$Html$text(' for '),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw5')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(model.cp)
							]))
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$GetStarted$FirebaseSignIn),
						$elm$html$Html$Attributes$class('tc')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Password',
								A3($author$project$Page$GetStarted$labelState, model, 1, model.C),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('password'),
										$elm$html$Html$Attributes$id('password'),
										$elm$html$Html$Attributes$value(model.C),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Attributes$minlength(6),
										$elm$html$Html$Events$onInput(
										$author$project$Page$GetStarted$UpdateInput(1)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$GetStarted$SetFieldFocus(1)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$GetStarted$UnsetFieldFocus(1)),
										$elm$html$Html$Attributes$name('password')
									]),
								maybeError)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pv3 mb2 flex items-center flex-column')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Page$GetStarted$SendPasswordReset),
										$elm$html$Html$Attributes$class('underline tc pointer pa2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('I forgot my password')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb2 f5')
							]),
						_List_fromArray(
							[sendBtn]))
					]))
			]));
};
var $author$project$Page$GetStarted$view = function (model) {
	var title = model.b$;
	var stageView = function () {
		var _v0 = model.av;
		switch (_v0) {
			case 0:
				return $author$project$Page$GetStarted$viewEnterEmail(model);
			case 2:
				return $author$project$Page$GetStarted$viewNoSignUp(model);
			default:
				return $author$project$Page$GetStarted$viewPasswordSignIn(model);
		}
	}();
	var content = _List_fromArray(
		[
			$author$project$Page$viewHeaderGetStarted,
			A3(
			$elm$html$Html$Lazy$lazy2,
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100 bg-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
							A2($elm$html$Html$Attributes$style, 'height', '20px')
						]),
					_List_Nil),
					stageView
				])),
			$author$project$Page$viewFooter(model.bP)
		]);
	return _Utils_Tuple2(title, content);
};
var $author$project$Page$Home$viewGuest = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Ox$Theme$primaryColor),
					A2($elm$html$Html$Attributes$style, 'height', '20px')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f2 w400 pt5')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('tĭbles')
				])),
			A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f4 pb5')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Organize and publish your crypto-collectibles.')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('link'),
							$elm$html$Html$Attributes$href('/get-started')
						]),
					_List_fromArray(
						[
							A2($author$project$Ox$Button$contained, 'Get Stared', _List_Nil)
						]))
				]))
		]));
var $author$project$Page$Home$viewMain = F2(
	function (model, _v0) {
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							A3(
							$author$project$Page$viewPageTitle,
							model.b$,
							model.bP,
							A2($elm$html$Html$span, _List_Nil, _List_Nil))
						]))
				]));
	});
var $author$project$Page$Home$viewMobileHeader = F2(
	function (user, model) {
		var username = function () {
			var _v0 = user.cP;
			if (!_v0.$) {
				var profile = _v0.a;
				return profile.dn;
			} else {
				return 'No Username';
			}
		}();
		var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var leftMenu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex items-center tl pl3 pv2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/profile'),
							$elm$html$Html$Attributes$class('link black dib'),
							A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(username)
						]))
				]));
		return A4(
			$author$project$Page$viewMobileHeader,
			model.b$,
			model.bP,
			0,
			_Utils_Tuple2(leftMenu, rightMenu));
	});
var $author$project$Page$Home$viewLoggedInOrGuest = function (model) {
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$Home$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Home$viewMain, model, user),
				$author$project$Page$viewFooter(model.bP),
				A2($author$project$Page$Home$viewMobileHeader, user, model)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$Home$viewGuest,
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$Home$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.b$),
		$author$project$Page$Home$viewLoggedInOrGuest(model));
};
var $author$project$Page$ItemEdit$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ItemEdit$UpdateItem = {$: 8};
var $author$project$Ox$Theme$secondaryDarkColor = '#8d8d8d';
var $author$project$Ox$Theme$secondaryTextColor = '#000000';
var $author$project$Ox$Button$outlined = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br2 inline-flex items-center'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px 16px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'min-width', '64px'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$secondaryDarkColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$secondaryTextColor),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Page$viewBreadCrumb = function (titleUrls) {
	var pastLink = function (_v1) {
		var title = _v1.a;
		var url = _v1.b;
		var _v0 = _Utils_Tuple2(title, url);
		if (_v0.b === '') {
			if (_v0.a === '>') {
				return A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pr2 fas fa-angle-right')
						]),
					_List_Nil);
			} else {
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pr2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]));
			}
		} else {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pr2 link'),
						$elm$html$Html$Attributes$href(url)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]));
		}
	};
	var newTitleUrls = A2(
		$elm$core$List$intersperse,
		_Utils_Tuple2('>', ''),
		titleUrls);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2 flex items-center')
			]),
		A2($elm$core$List$map, pastLink, newTitleUrls));
};
var $author$project$Ox$Theme$mobileModalStyles = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'background-color', 'rgba(255,255,255,1.0)'),
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'left', '0'),
		A2($elm$html$Html$Attributes$style, 'height', '100%'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'padding', '0px'),
		A2($elm$html$Html$Attributes$style, 'box-shadow', '1px 1px 5px rgba(0,0,0,0.5)')
	]);
var $author$project$Modal$ImageLibrary$Close = {$: 1};
var $author$project$Ox$Icon$close = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z')
					]),
				_List_Nil)
			]));
};
var $author$project$Ox$Button$iconSmall = F2(
	function (icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer link br2 flex items-center justify-around'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'width', '36px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0px'),
					A2($elm$html$Html$Attributes$style, 'border', '0'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '20px'),
							A2($elm$html$Html$Attributes$style, 'height', '20px')
						]),
					_List_fromArray(
						[icon]))
				]));
	});
var $author$project$Modal$ImageLibrary$viewCloseOrCancelBtn = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$iconSmall,
				$author$project$Ox$Icon$close('black'),
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Modal$ImageLibrary$Close)
					]))
			]));
};
var $author$project$Modal$ImageLibrary$viewMenu = function (model) {
	var titleView = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ml3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.b$)
					])),
				$elm$html$Html$text(' — '),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.aN)
					]))
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between items-end pb2')
			]),
		_List_fromArray(
			[
				titleView,
				$author$project$Modal$ImageLibrary$viewCloseOrCancelBtn(model)
			]));
};
var $author$project$Modal$ImageLibrary$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$span, _List_Nil, _List_Nil);
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pr2 f5 pointer tl pv2 pl3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$ImageLibrary$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		'title',
		model.bP,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Modal$ImageLibrary$SelectImageFile = {$: 5};
var $author$project$Ox$Theme$checkerboardUrl = 'https://firebasestorage.googleapis.com/v0/b/collecting-club.appspot.com/o/misc%2Fcheckboard-20x20.svg?alt=media&token=3f6bc533-d0e1-452f-9ca9-9e612b446f10';
var $author$project$Ox$Icon$uploadFile = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z M8,15.01 l1.41,1.41L11,14.84V19h2v-4.16l1.59,1.59L16,15.01L12.01,11L8,15.01z')
					]),
				_List_Nil)
			]));
};
var $author$project$Modal$ImageLibrary$viewEmptyPreview = function () {
	var imageUrl = 'url(' + ($author$project$Ox$Theme$checkerboardUrl + ')');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 h-100 b--black-20 br3 flex flex-column items-center justify-center pointer b--dashed bw2'),
				$elm$html$Html$Events$onClick($author$project$Modal$ImageLibrary$SelectImageFile)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '60px'),
						A2($elm$html$Html$Attributes$style, 'height', '60px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$uploadFile('black')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br2 pa2 mt2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Select file to upload')
					]))
			]));
}();
var $author$project$Modal$ImageLibrary$Upload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Modal$ImageLibrary$viewImagePreview = F2(
	function (model, imageData) {
		var urlfn = 'url(' + (imageData + ')');
		var uploadBtn = A2(
			$author$project$Ox$Button$outlined,
			'Upload',
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Modal$ImageLibrary$Upload(imageData))
				]));
		var uploadAnother = A2(
			$author$project$Ox$Button$textLink,
			'Choose another',
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Modal$ImageLibrary$SelectImageFile)
				]));
		var progressBar = function () {
			var _v0 = model.aZ;
			if (_v0.$ === 1) {
				var prog = _v0.a;
				var progBarWidth = $elm$core$String$fromInt(
					$elm$core$Basics$round(prog));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-100'),
							A2($elm$html$Html$Attributes$style, 'height', '4px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-blue'),
									A2($elm$html$Html$Attributes$style, 'width', progBarWidth + '%'),
									A2($elm$html$Html$Attributes$style, 'height', '4px')
								]),
							_List_Nil)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var bgUrl = 'url(' + ($author$project$Ox$Theme$checkerboardUrl + ')');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100 ba b--black-10'),
					A2($elm$html$Html$Attributes$style, 'height', '360px'),
					A2($elm$html$Html$Attributes$style, 'background-image', bgUrl),
					A2($elm$html$Html$Attributes$style, 'background-repeat', 'repeat')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-100 h-100'),
							A2($elm$html$Html$Attributes$style, 'background-image', urlfn),
							A2($elm$html$Html$Attributes$style, 'background-size', 'contain'),
							A2($elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
							A2($elm$html$Html$Attributes$style, 'background-position', 'center')
						]),
					_List_Nil),
					progressBar,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt3 flex justify-end')
						]),
					_List_fromArray(
						[uploadAnother, uploadBtn]))
				]));
	});
var $author$project$Modal$ImageLibrary$viewPreview = function (model) {
	var viewEmptyOrPreview = function () {
		var _v0 = model.Q;
		if (!_v0.$) {
			var data = _v0.a;
			return A2($author$project$Modal$ImageLibrary$viewImagePreview, model, data);
		} else {
			return $author$project$Modal$ImageLibrary$viewEmptyPreview;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mb1 b--black-10 tc'),
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '400px')
			]),
		_List_fromArray(
			[viewEmptyOrPreview]));
};
var $author$project$Modal$ImageLibrary$viewSection = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex mh3 ')
			]),
		_List_fromArray(
			[
				$author$project$Modal$ImageLibrary$viewPreview(model)
			]));
};
var $author$project$Modal$ImageLibrary$viewModal = function (model) {
	var viewMobileModal = A2(
		$elm$html$Html$div,
		$author$project$Ox$Theme$mobileModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						$elm$html$Html$Attributes$class('overflow-y-scroll'),
						$elm$html$Html$Attributes$id('print-grid')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim(model.bP),
						$author$project$Modal$ImageLibrary$viewSection(model)
					])),
				$author$project$Modal$ImageLibrary$viewMobileHeader(model)
			]));
	var viewDesktopModal = A2(
		$elm$html$Html$div,
		_Utils_ap(
			$author$project$Ox$Theme$desktopModalStyles,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '750px'),
					A2($elm$html$Html$Attributes$style, 'height', '470px')
				])),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 bg-white pb2'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '6px')
					]),
				_List_fromArray(
					[
						$author$project$Modal$ImageLibrary$viewMenu(model),
						$author$project$Modal$ImageLibrary$viewSection(model)
					]))
			]));
	var _v0 = model.bP;
	switch (_v0.$) {
		case 0:
			return viewMobileModal;
		case 1:
			return viewMobileModal;
		default:
			return viewDesktopModal;
	}
};
var $author$project$Modal$ImageLibrary$view = function (model) {
	var modalStyles = function () {
		var _v0 = model.bP;
		switch (_v0.$) {
			case 0:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			case 1:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			default:
				return $author$project$Ox$Theme$desktopModalMaskStyles(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
							A2($elm$html$Html$Attributes$style, 'align-content', 'center')
						]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		modalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Modal$ImageLibrary$viewModal(model)
					]))
			]));
};
var $author$project$Modal$VariantEdit$Save = {$: 0};
var $author$project$Modal$VariantEdit$SetProgagate = function (a) {
	return {$: 8, a: a};
};
var $author$project$Ox$Button$switch = F2(
	function (attributes, status) {
		var btnAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '24px'),
				A2($elm$html$Html$Attributes$style, 'width', '24px'),
				A2($elm$html$Html$Attributes$style, 'border', '0'),
				A2($elm$html$Html$Attributes$style, 'outline', '0'),
				A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '12px')
			]);
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'height', '28px'),
					A2($elm$html$Html$Attributes$style, 'width', '46px'),
					A2($elm$html$Html$Attributes$style, 'padding', '0 2px'),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background-color', 'lightGray'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '14px'),
					$elm$html$Html$Attributes$class('flex items-center')
				]),
			attributes);
		var newAttributeList = status ? _Utils_ap(
			attributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('justify-end')
				])) : attributeList;
		return A2(
			$elm$html$Html$div,
			newAttributeList,
			_List_fromArray(
				[
					A2($elm$html$Html$div, btnAttributes, _List_Nil)
				]));
	});
var $author$project$Modal$VariantEdit$viewButtons = function (model) {
	var switchAttr = model.af ? _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'background-color', 'black'),
			$elm$html$Html$Events$onClick(
			$author$project$Modal$VariantEdit$SetProgagate(false))
		]) : _List_fromArray(
		[
			$elm$html$Html$Events$onClick(
			$author$project$Modal$VariantEdit$SetProgagate(true))
		]);
	var propagateStr = 'Save to all \"' + (model.t.b$ + '\" variants');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2 flex justify-between')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex justify-end items-center pointer')
					]),
				_List_fromArray(
					[
						A2($author$project$Ox$Button$switch, switchAttr, model.af),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(propagateStr)
							]))
					])),
				A2(
				$author$project$Ox$Button$outlined,
				'Save',
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Modal$VariantEdit$Save)
					]))
			]));
};
var $author$project$Modal$VariantEdit$Close = {$: 1};
var $author$project$Modal$VariantEdit$viewCloseOrCancelBtn = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Ox$Button$iconSmall,
				$author$project$Ox$Icon$close('black'),
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Modal$VariantEdit$Close)
					]))
			]));
};
var $author$project$Modal$VariantEdit$viewMenu = function (model) {
	var titleView = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ml3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.b$ + ' Variant')
					]))
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between items-end pb2')
			]),
		_List_fromArray(
			[
				titleView,
				$author$project$Modal$VariantEdit$viewCloseOrCancelBtn(model)
			]));
};
var $author$project$Modal$VariantEdit$viewMobileHeader = function (model) {
	var rightMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pl2 f5 pointer tr pv2 pr3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$VariantEdit$Save)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Save')
			]));
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pr2 f5 pointer tl pv2 pl3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$VariantEdit$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		'title',
		model.bP,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Modal$VariantEdit$viewModalOrNot = function (model) {
	var _v0 = model.v;
	if (!_v0.$) {
		var _v1 = _v0.a;
		var subModel = _v1.b;
		return A2(
			$elm$html$Html$map,
			$author$project$Modal$VariantEdit$ImageLibraryMsg,
			$author$project$Modal$ImageLibrary$view(subModel));
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Modal$VariantEdit$BackgroundColorField = 4;
var $author$project$Modal$VariantEdit$BatchSizeField = 3;
var $author$project$Modal$VariantEdit$CountMaxField = 1;
var $author$project$Modal$VariantEdit$RarityFactorField = 2;
var $author$project$Modal$VariantEdit$SetFieldFocus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Modal$VariantEdit$TitleField = 0;
var $author$project$Modal$VariantEdit$UnsetFieldFocus = function (a) {
	return {$: 4, a: a};
};
var $author$project$Modal$VariantEdit$UpdateField = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Modal$VariantEdit$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.aM,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Modal$VariantEdit$BackgroundImage = 1;
var $author$project$Modal$VariantEdit$OpenImageLibrary = function (a) {
	return {$: 5, a: a};
};
var $author$project$Modal$VariantEdit$RemoveImg = {$: 7};
var $author$project$Modal$VariantEdit$VariantImage = 0;
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Modal$VariantEdit$viewPreview = F4(
	function (title, url, uploadMsg, removeMsg) {
		var viewImg = (url === '') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba mb1 b--black-20 pointer w-100 bg-near-white b--black-10 pointer tc relative'),
					A2($elm$html$Html$Attributes$style, 'padding-top', '100%'),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-100 h-100 flex flex-column items-center justify-center'),
							A2($elm$html$Html$Attributes$style, 'top', '0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white br2 pa2 mt2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Update')
								]))
						]))
				])) : A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					$elm$html$Html$Attributes$class('ba b--black-20 pointer'),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100')
				]),
			_List_fromArray(
				[
					viewImg,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center justify-between f7')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(removeMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Remove')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(uploadMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Replace')
								]))
						]))
				]));
	});
var $author$project$Modal$VariantEdit$viewImages = function (model) {
	var backgroundImageUrl = A2($elm$core$Maybe$withDefault, '', model.t.N.bu);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt0'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', 'repeat(2, minmax(125px, 225px))'),
				A2($elm$html$Html$Attributes$style, 'grid-gap', '10px'),
				A2($elm$html$Html$Attributes$style, 'justify-items', 'stretch')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br2 b--black-20')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Modal$VariantEdit$viewPreview,
						'Variant',
						model.t.bo,
						$author$project$Modal$VariantEdit$OpenImageLibrary(0),
						$author$project$Modal$VariantEdit$RemoveImg)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br2 b--black-20')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Modal$VariantEdit$viewPreview,
						'Background',
						backgroundImageUrl,
						$author$project$Modal$VariantEdit$OpenImageLibrary(1),
						$author$project$Modal$VariantEdit$RemoveImg)
					]))
			]));
};
var $author$project$Modal$VariantEdit$viewSection = function (model) {
	var variant = model.t;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('cf ma2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Ox$Text$outlined,
						'Title',
						A3($author$project$Modal$VariantEdit$labelState, model, 0, variant.b$),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('titleField'),
								$elm$html$Html$Attributes$value(variant.b$),
								$elm$html$Html$Attributes$autocomplete(false),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Events$onInput(
								$author$project$Modal$VariantEdit$UpdateField(0)),
								$elm$html$Html$Events$onFocus(
								$author$project$Modal$VariantEdit$SetFieldFocus(0)),
								$elm$html$Html$Events$onBlur(
								$author$project$Modal$VariantEdit$UnsetFieldFocus(0)),
								$elm$html$Html$Attributes$name('title')
							]),
						$elm$core$Maybe$Nothing)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr'),
						A2($elm$html$Html$Attributes$style, 'grid-gap', '10px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Count Max',
								A3(
									$author$project$Modal$VariantEdit$labelState,
									model,
									1,
									$elm$core$String$fromInt(variant.aF)),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('countMaxField'),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(variant.aF)),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Events$onInput(
										$author$project$Modal$VariantEdit$UpdateField(1)),
										$elm$html$Html$Events$onFocus(
										$author$project$Modal$VariantEdit$SetFieldFocus(1)),
										$elm$html$Html$Events$onBlur(
										$author$project$Modal$VariantEdit$UnsetFieldFocus(1)),
										$elm$html$Html$Attributes$name('countMax')
									]),
								$elm$core$Maybe$Nothing)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Rarity Factor',
								A3(
									$author$project$Modal$VariantEdit$labelState,
									model,
									2,
									$elm$core$String$fromFloat(variant.aV)),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('rarityFactorField'),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromFloat(variant.aV)),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Attributes$min('0'),
										$elm$html$Html$Attributes$max('1'),
										$elm$html$Html$Attributes$step('0.0001'),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Events$onInput(
										$author$project$Modal$VariantEdit$UpdateField(2)),
										$elm$html$Html$Events$onFocus(
										$author$project$Modal$VariantEdit$SetFieldFocus(2)),
										$elm$html$Html$Events$onBlur(
										$author$project$Modal$VariantEdit$UnsetFieldFocus(2)),
										$elm$html$Html$Attributes$name('rarityFactor')
									]),
								$elm$core$Maybe$Nothing)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'grid'),
						A2($elm$html$Html$Attributes$style, 'grid-template-columns', '1fr 1fr'),
						A2($elm$html$Html$Attributes$style, 'grid-gap', '10px')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Batch Size',
								A3(
									$author$project$Modal$VariantEdit$labelState,
									model,
									3,
									$elm$core$String$fromInt(variant.aC)),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('batchSizeField'),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(variant.aC)),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Events$onInput(
										$author$project$Modal$VariantEdit$UpdateField(3)),
										$elm$html$Html$Events$onFocus(
										$author$project$Modal$VariantEdit$SetFieldFocus(3)),
										$elm$html$Html$Events$onBlur(
										$author$project$Modal$VariantEdit$UnsetFieldFocus(3)),
										$elm$html$Html$Attributes$name('batchSize')
									]),
								$elm$core$Maybe$Nothing)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Background Color',
								A3($author$project$Modal$VariantEdit$labelState, model, 4, variant.N.aA),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('backgroundColorField'),
										$elm$html$Html$Attributes$value(variant.N.aA),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Events$onInput(
										$author$project$Modal$VariantEdit$UpdateField(4)),
										$elm$html$Html$Events$onFocus(
										$author$project$Modal$VariantEdit$SetFieldFocus(4)),
										$elm$html$Html$Events$onBlur(
										$author$project$Modal$VariantEdit$UnsetFieldFocus(4)),
										$elm$html$Html$Attributes$name('backgroundColor')
									]),
								$elm$core$Maybe$Nothing)
							]))
					])),
				$author$project$Modal$VariantEdit$viewImages(model)
			]));
};
var $author$project$Modal$VariantEdit$viewModal = function (model) {
	var viewMobileModal = A2(
		$elm$html$Html$div,
		$author$project$Ox$Theme$mobileModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						$elm$html$Html$Attributes$class('overflow-y-scroll'),
						$elm$html$Html$Attributes$id('print-grid')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim(model.bP),
						$author$project$Modal$VariantEdit$viewSection(model),
						$author$project$Modal$VariantEdit$viewButtons(model)
					])),
				$author$project$Modal$VariantEdit$viewMobileHeader(model)
			]));
	var viewDesktopModal = A2(
		$elm$html$Html$div,
		_Utils_ap(
			$author$project$Ox$Theme$desktopModalStyles,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '480px'),
					A2($elm$html$Html$Attributes$style, 'height', '520px')
				])),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 bg-white pb2'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '6px')
					]),
				_List_fromArray(
					[
						$author$project$Modal$VariantEdit$viewMenu(model),
						$author$project$Modal$VariantEdit$viewSection(model),
						$author$project$Modal$VariantEdit$viewButtons(model),
						$author$project$Modal$VariantEdit$viewModalOrNot(model)
					]))
			]));
	var _v0 = model.bP;
	switch (_v0.$) {
		case 0:
			return viewMobileModal;
		case 1:
			return viewMobileModal;
		default:
			return viewDesktopModal;
	}
};
var $author$project$Modal$VariantEdit$view = function (model) {
	var modalStyles = function () {
		var _v0 = model.bP;
		switch (_v0.$) {
			case 0:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			case 1:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			default:
				return $author$project$Ox$Theme$desktopModalMaskStyles(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'justify-content', 'center')
						]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		modalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Modal$VariantEdit$viewModal(model)
					]))
			]));
};
var $author$project$Page$ItemEdit$viewModalOrNot = function (model) {
	var _v0 = model.v;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var _v1 = _v0.a;
			var subModel = _v1.b;
			return A2(
				$elm$html$Html$map,
				$author$project$Page$ItemEdit$ImageLibraryMsg,
				$author$project$Modal$ImageLibrary$view(subModel));
		} else {
			var subModel = _v0.a.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Page$ItemEdit$VariantEditMsg,
				$author$project$Modal$VariantEdit$view(subModel));
		}
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$ItemEdit$CornerRadiusField = 3;
var $author$project$Page$ItemEdit$RatioField = 1;
var $author$project$Page$ItemEdit$SetFieldFocus = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$ItemEdit$TitleField = 0;
var $author$project$Page$ItemEdit$UnsetFieldFocus = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$ItemEdit$UpdateField = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$ItemEdit$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.aM,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $author$project$Ox$Theme$Disabled = 3;
var $author$project$Page$ItemEdit$viewDate = F2(
	function (zone, dateInt) {
		var weekday = A2($author$project$Type$DateTime$intToWeekday, zone, dateInt);
		var time = A2($author$project$Type$DateTime$intToTime, zone, dateInt);
		var date = A2($author$project$Type$DateTime$intToDate, zone, dateInt);
		var _v0 = (!dateInt) ? _Utils_Tuple2('', 1) : _Utils_Tuple2(weekday + (', ' + (date + (' ' + time))), 3);
		var btnTitle = _v0.a;
		var status = _v0.b;
		return A4(
			$author$project$Ox$Text$outlined,
			'Date Published',
			status,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('datePublishedField'),
					$elm$html$Html$Attributes$value(btnTitle),
					$elm$html$Html$Attributes$name('datePublished')
				]),
			$elm$core$Maybe$Nothing);
	});
var $ohanhi$keyboard$Keyboard$Enter = {$: 15};
var $author$project$Page$ItemEdit$EnterTag = {$: 6};
var $ohanhi$keyboard$Keyboard$Escape = {$: 62};
var $Skinney$keyboard_events$Keyboard$Events$Keydown = 0;
var $author$project$Page$ItemEdit$TagField = 2;
var $Skinney$keyboard_events$Keyboard$Events$eventToString = function (event) {
	switch (event) {
		case 0:
			return 'keydown';
		case 1:
			return 'keyup';
		default:
			return 'keypress';
	}
};
var $ohanhi$keyboard$Keyboard$Backspace = {$: 26};
var $ohanhi$keyboard$Keyboard$Clear = {$: 27};
var $ohanhi$keyboard$Keyboard$Copy = {$: 28};
var $ohanhi$keyboard$Keyboard$CrSel = {$: 29};
var $ohanhi$keyboard$Keyboard$Cut = {$: 30};
var $ohanhi$keyboard$Keyboard$Delete = {$: 31};
var $ohanhi$keyboard$Keyboard$EraseEof = {$: 32};
var $ohanhi$keyboard$Keyboard$ExSel = {$: 33};
var $ohanhi$keyboard$Keyboard$Insert = {$: 34};
var $ohanhi$keyboard$Keyboard$Paste = {$: 35};
var $ohanhi$keyboard$Keyboard$Redo = {$: 36};
var $ohanhi$keyboard$Keyboard$Undo = {$: 37};
var $ohanhi$keyboard$Keyboard$editingKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Backspace':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Backspace);
		case 'Clear':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Clear);
		case 'Copy':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Copy);
		case 'CrSel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CrSel);
		case 'Cut':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Cut);
		case 'Delete':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Delete);
		case 'EraseEof':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$EraseEof);
		case 'ExSel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ExSel);
		case 'Insert':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Insert);
		case 'Paste':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Paste);
		case 'Redo':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Redo);
		case 'Undo':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Undo);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$F1 = {$: 38};
var $ohanhi$keyboard$Keyboard$F10 = {$: 47};
var $ohanhi$keyboard$Keyboard$F11 = {$: 48};
var $ohanhi$keyboard$Keyboard$F12 = {$: 49};
var $ohanhi$keyboard$Keyboard$F13 = {$: 50};
var $ohanhi$keyboard$Keyboard$F14 = {$: 51};
var $ohanhi$keyboard$Keyboard$F15 = {$: 52};
var $ohanhi$keyboard$Keyboard$F16 = {$: 53};
var $ohanhi$keyboard$Keyboard$F17 = {$: 54};
var $ohanhi$keyboard$Keyboard$F18 = {$: 55};
var $ohanhi$keyboard$Keyboard$F19 = {$: 56};
var $ohanhi$keyboard$Keyboard$F2 = {$: 39};
var $ohanhi$keyboard$Keyboard$F20 = {$: 57};
var $ohanhi$keyboard$Keyboard$F3 = {$: 40};
var $ohanhi$keyboard$Keyboard$F4 = {$: 41};
var $ohanhi$keyboard$Keyboard$F5 = {$: 42};
var $ohanhi$keyboard$Keyboard$F6 = {$: 43};
var $ohanhi$keyboard$Keyboard$F7 = {$: 44};
var $ohanhi$keyboard$Keyboard$F8 = {$: 45};
var $ohanhi$keyboard$Keyboard$F9 = {$: 46};
var $ohanhi$keyboard$Keyboard$functionKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'F1':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F1);
		case 'F2':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F2);
		case 'F3':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F3);
		case 'F4':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F4);
		case 'F5':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F5);
		case 'F6':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F6);
		case 'F7':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F7);
		case 'F8':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F8);
		case 'F9':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F9);
		case 'F10':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F10);
		case 'F11':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F11);
		case 'F12':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F12);
		case 'F13':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F13);
		case 'F14':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F14);
		case 'F15':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F15);
		case 'F16':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F16);
		case 'F17':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F17);
		case 'F18':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F18);
		case 'F19':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F19);
		case 'F20':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F20);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$ChannelDown = {$: 85};
var $ohanhi$keyboard$Keyboard$ChannelUp = {$: 86};
var $ohanhi$keyboard$Keyboard$MediaFastForward = {$: 87};
var $ohanhi$keyboard$Keyboard$MediaPause = {$: 88};
var $ohanhi$keyboard$Keyboard$MediaPlay = {$: 89};
var $ohanhi$keyboard$Keyboard$MediaPlayPause = {$: 90};
var $ohanhi$keyboard$Keyboard$MediaRecord = {$: 91};
var $ohanhi$keyboard$Keyboard$MediaRewind = {$: 92};
var $ohanhi$keyboard$Keyboard$MediaStop = {$: 93};
var $ohanhi$keyboard$Keyboard$MediaTrackNext = {$: 94};
var $ohanhi$keyboard$Keyboard$MediaTrackPrevious = {$: 95};
var $ohanhi$keyboard$Keyboard$mediaKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'ChannelDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ChannelDown);
		case 'ChannelUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ChannelUp);
		case 'MediaFastForward':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaFastForward);
		case 'MediaPause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPause);
		case 'MediaPlay':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPlay);
		case 'MediaPlayPause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPlayPause);
		case 'MediaRecord':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaRecord);
		case 'MediaRewind':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaRewind);
		case 'MediaStop':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaStop);
		case 'MediaTrackNext':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaTrackNext);
		case 'MediaTrackPrevious':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaTrackPrevious);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Alt = {$: 1};
var $ohanhi$keyboard$Keyboard$AltGraph = {$: 2};
var $ohanhi$keyboard$Keyboard$CapsLock = {$: 3};
var $ohanhi$keyboard$Keyboard$Control = {$: 4};
var $ohanhi$keyboard$Keyboard$Fn = {$: 5};
var $ohanhi$keyboard$Keyboard$FnLock = {$: 6};
var $ohanhi$keyboard$Keyboard$Hyper = {$: 7};
var $ohanhi$keyboard$Keyboard$Meta = {$: 8};
var $ohanhi$keyboard$Keyboard$NumLock = {$: 9};
var $ohanhi$keyboard$Keyboard$ScrollLock = {$: 10};
var $ohanhi$keyboard$Keyboard$Shift = {$: 11};
var $ohanhi$keyboard$Keyboard$Super = {$: 12};
var $ohanhi$keyboard$Keyboard$Symbol = {$: 13};
var $ohanhi$keyboard$Keyboard$SymbolLock = {$: 14};
var $ohanhi$keyboard$Keyboard$modifierKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Alt':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Alt);
		case 'AltGraph':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$AltGraph);
		case 'CapsLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CapsLock);
		case 'Control':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Control);
		case 'Fn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Fn);
		case 'FnLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$FnLock);
		case 'Hyper':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Hyper);
		case 'Meta':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Meta);
		case 'NumLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$NumLock);
		case 'ScrollLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ScrollLock);
		case 'Shift':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Shift);
		case 'Super':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Super);
		case 'OS':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Super);
		case 'Symbol':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Symbol);
		case 'SymbolLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$SymbolLock);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$ArrowDown = {$: 18};
var $ohanhi$keyboard$Keyboard$ArrowLeft = {$: 19};
var $ohanhi$keyboard$Keyboard$ArrowRight = {$: 20};
var $ohanhi$keyboard$Keyboard$ArrowUp = {$: 21};
var $ohanhi$keyboard$Keyboard$End = {$: 22};
var $ohanhi$keyboard$Keyboard$Home = {$: 23};
var $ohanhi$keyboard$Keyboard$PageDown = {$: 24};
var $ohanhi$keyboard$Keyboard$PageUp = {$: 25};
var $ohanhi$keyboard$Keyboard$navigationKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'ArrowDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowDown);
		case 'ArrowLeft':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowLeft);
		case 'ArrowRight':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowRight);
		case 'ArrowUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowUp);
		case 'Down':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowDown);
		case 'Left':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowLeft);
		case 'Right':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowRight);
		case 'Up':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowUp);
		case 'End':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$End);
		case 'Home':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Home);
		case 'PageDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$PageDown);
		case 'PageUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$PageUp);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$oneOf = F2(
	function (fns, key) {
		oneOf:
		while (true) {
			if (!fns.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var fn = fns.a;
				var rest = fns.b;
				var _v1 = fn(key);
				if (!_v1.$) {
					var a = _v1.a;
					return $elm$core$Maybe$Just(a);
				} else {
					var $temp$fns = rest,
						$temp$key = key;
					fns = $temp$fns;
					key = $temp$key;
					continue oneOf;
				}
			}
		}
	});
var $ohanhi$keyboard$Keyboard$AppSwitch = {$: 73};
var $ohanhi$keyboard$Keyboard$Call = {$: 74};
var $ohanhi$keyboard$Keyboard$Camera = {$: 75};
var $ohanhi$keyboard$Keyboard$CameraFocus = {$: 76};
var $ohanhi$keyboard$Keyboard$EndCall = {$: 77};
var $ohanhi$keyboard$Keyboard$GoBack = {$: 78};
var $ohanhi$keyboard$Keyboard$GoHome = {$: 79};
var $ohanhi$keyboard$Keyboard$HeadsetHook = {$: 80};
var $ohanhi$keyboard$Keyboard$LastNumberRedial = {$: 81};
var $ohanhi$keyboard$Keyboard$MannerMode = {$: 83};
var $ohanhi$keyboard$Keyboard$Notification = {$: 82};
var $ohanhi$keyboard$Keyboard$VoiceDial = {$: 84};
var $ohanhi$keyboard$Keyboard$phoneKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'AppSwitch':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$AppSwitch);
		case 'Call':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Call);
		case 'Camera':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Camera);
		case 'CameraFocus':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CameraFocus);
		case 'EndCall':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$EndCall);
		case 'GoBack':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$GoBack);
		case 'GoHome':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$GoHome);
		case 'HeadsetHook':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$HeadsetHook);
		case 'LastNumberRedial':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$LastNumberRedial);
		case 'Notification':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Notification);
		case 'MannerMode':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MannerMode);
		case 'VoiceDial':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$VoiceDial);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Again = {$: 58};
var $ohanhi$keyboard$Keyboard$Attn = {$: 59};
var $ohanhi$keyboard$Keyboard$Cancel = {$: 60};
var $ohanhi$keyboard$Keyboard$ContextMenu = {$: 61};
var $ohanhi$keyboard$Keyboard$Execute = {$: 63};
var $ohanhi$keyboard$Keyboard$Find = {$: 64};
var $ohanhi$keyboard$Keyboard$Finish = {$: 65};
var $ohanhi$keyboard$Keyboard$Help = {$: 66};
var $ohanhi$keyboard$Keyboard$Pause = {$: 67};
var $ohanhi$keyboard$Keyboard$Play = {$: 68};
var $ohanhi$keyboard$Keyboard$Props = {$: 69};
var $ohanhi$keyboard$Keyboard$Select = {$: 70};
var $ohanhi$keyboard$Keyboard$ZoomIn = {$: 71};
var $ohanhi$keyboard$Keyboard$ZoomOut = {$: 72};
var $ohanhi$keyboard$Keyboard$uiKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Again':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Again);
		case 'Attn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Attn);
		case 'Cancel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Cancel);
		case 'ContextMenu':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ContextMenu);
		case 'Escape':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Escape);
		case 'Execute':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Execute);
		case 'Find':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Find);
		case 'Finish':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Finish);
		case 'Help':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Help);
		case 'Pause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Pause);
		case 'Play':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Play);
		case 'Props':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Props);
		case 'Select':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Select);
		case 'ZoomIn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ZoomIn);
		case 'ZoomOut':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ZoomOut);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Spacebar = {$: 17};
var $ohanhi$keyboard$Keyboard$Tab = {$: 16};
var $ohanhi$keyboard$Keyboard$whitespaceKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Enter':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Enter);
		case 'Tab':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Tab);
		case 'Spacebar':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Spacebar);
		case ' ':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Spacebar);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$anyKeyWith = function (charParser) {
	return $ohanhi$keyboard$Keyboard$oneOf(
		_List_fromArray(
			[$ohanhi$keyboard$Keyboard$whitespaceKey, charParser, $ohanhi$keyboard$Keyboard$modifierKey, $ohanhi$keyboard$Keyboard$navigationKey, $ohanhi$keyboard$Keyboard$editingKey, $ohanhi$keyboard$Keyboard$functionKey, $ohanhi$keyboard$Keyboard$uiKey, $ohanhi$keyboard$Keyboard$phoneKey, $ohanhi$keyboard$Keyboard$mediaKey]));
};
var $ohanhi$keyboard$Keyboard$Character = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$toUpper = _String_toUpper;
var $ohanhi$keyboard$Keyboard$characterKeyUpper = function (_v0) {
	var value = _v0;
	return ($elm$core$String$length(value) === 1) ? $elm$core$Maybe$Just(
		$ohanhi$keyboard$Keyboard$Character(
			$elm$core$String$toUpper(value))) : $elm$core$Maybe$Nothing;
};
var $ohanhi$keyboard$Keyboard$anyKeyUpper = $ohanhi$keyboard$Keyboard$anyKeyWith($ohanhi$keyboard$Keyboard$characterKeyUpper);
var $ohanhi$keyboard$Keyboard$RawKey = $elm$core$Basics$identity;
var $ohanhi$keyboard$Keyboard$eventKeyDecoder = A2(
	$elm$json$Json$Decode$field,
	'key',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string));
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $Skinney$keyboard_events$Keyboard$Events$messageSelector = function (decisionMap) {
	var helper = function (maybeKey) {
		if (maybeKey.$ === 1) {
			return $elm$json$Json$Decode$fail('No key we\'re interested in');
		} else {
			var key = maybeKey.a;
			return A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Decode$fail('No key we\'re interested in'),
				A2(
					$elm$core$Maybe$map,
					$elm$json$Json$Decode$succeed,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$second,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (_v1) {
									var k = _v1.a;
									return _Utils_eq(k, key);
								},
								decisionMap)))));
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		helper,
		A2($elm$json$Json$Decode$map, $ohanhi$keyboard$Keyboard$anyKeyUpper, $ohanhi$keyboard$Keyboard$eventKeyDecoder));
};
var $Skinney$keyboard_events$Keyboard$Events$on = F2(
	function (event, decisionMap) {
		return A2(
			$elm$html$Html$Events$on,
			$Skinney$keyboard_events$Keyboard$Events$eventToString(event),
			$Skinney$keyboard_events$Keyboard$Events$messageSelector(decisionMap));
	});
var $author$project$Ox$Box$outlined = F5(
	function (labelName, state, inputAttrs, contentList, maybeAlert) {
		var labelAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
				A2($elm$html$Html$Attributes$style, 'top', '26px'),
				A2($elm$html$Html$Attributes$style, 'left', '16px'),
				A2($elm$html$Html$Attributes$style, 'right', 'initial'),
				A2($elm$html$Html$Attributes$style, 'transform-origin', 'left top'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
				$elm$html$Html$Attributes$class('infoLabel')
			]);
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, '-webkit-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, '-moz-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'padding', '14px 14px 6px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
					A2($elm$html$Html$Attributes$style, 'min-height', '56px'),
					A2($elm$html$Html$Attributes$style, 'box-sizing', 'border-box')
				]),
			inputAttrs);
		var helper = function () {
			if (!maybeAlert.$) {
				var alert = maybeAlert.a;
				var color = $author$project$Ox$Theme$colorForHelper(alert.cK);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'font-size', '12px'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '4px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(alert.bv)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var _v0 = function () {
			switch (state) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryDarkColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.4)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid rgba(128,128,128,.8)'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'rgb(255,255,255)')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
								$elm$html$Html$Attributes$disabled(true),
								A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryColor)
							]));
			}
		}();
		var addLabelAttributes = _v0.a;
		var addInputAttributes = _v0.b;
		var newInputAttributes = _Utils_ap(inputAttributes, addInputAttributes);
		var newLabelAttributes = _Utils_ap(labelAttributes, addLabelAttributes);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					newLabelAttributes,
					_List_fromArray(
						[
							$elm$html$Html$text(labelName)
						])),
					A2($elm$html$Html$div, newInputAttributes, contentList),
					helper
				]));
	});
var $author$project$Page$ItemEdit$ClearTag = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ox$Chip$outlinedIcon = F3(
	function (title, icon, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 pointer link br4 inline-flex items-center tc'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px 8px 10px 15px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$btnBorderColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$btnTextColor),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-right', '6px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'width', '18px'),
							A2($elm$html$Html$Attributes$style, 'height', '18px')
						]),
					_List_fromArray(
						[icon]))
				]));
	});
var $author$project$Page$ItemEdit$viewTagChip = function (title) {
	return A3(
		$author$project$Ox$Chip$outlinedIcon,
		title,
		$author$project$Ox$Icon$cancel('black'),
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$Page$ItemEdit$ClearTag(title))
			]));
};
var $author$project$Page$ItemEdit$viewTagContainer = function (model) {
	var tags = A2($elm$core$List$map, $author$project$Page$ItemEdit$viewTagChip, model.ao.ag);
	var tagInput = A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2'),
				A2($elm$html$Html$Attributes$style, 'flex', '1'),
				A2($elm$html$Html$Attributes$style, 'border', '0'),
				$elm$html$Html$Attributes$id('tagInput'),
				$elm$html$Html$Events$onInput(
				$author$project$Page$ItemEdit$UpdateField(2)),
				A2(
				$Skinney$keyboard_events$Keyboard$Events$on,
				0,
				_List_fromArray(
					[
						_Utils_Tuple2($ohanhi$keyboard$Keyboard$Enter, $author$project$Page$ItemEdit$EnterTag),
						_Utils_Tuple2($ohanhi$keyboard$Keyboard$Escape, $author$project$Page$ItemEdit$EnterTag)
					])),
				$elm$html$Html$Attributes$value(model.Y)
			]),
		_List_Nil);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Ox$Box$outlined,
				'Tags',
				2,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'flex-wrap', 'wrap'),
						A2($elm$html$Html$Attributes$style, 'gap', '6px')
					]),
				_Utils_ap(
					tags,
					_List_fromArray(
						[tagInput])),
				$elm$core$Maybe$Nothing)
			]));
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Page$ItemEdit$OpenVariantEdit = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$ItemEdit$viewVariant = function (variant) {
	var bgUrl = 'url(' + ($author$project$Ox$Theme$checkerboardUrl + ')');
	var viewImg = function (url) {
		return (url === '') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba b--black-10 w-100 bg-near-white h-100'),
					A2($elm$html$Html$Attributes$style, 'background', bgUrl)
				]),
			_List_Nil) : A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					$elm$html$Html$Attributes$class('ba b--black-10')
				]),
			_List_Nil);
	};
	var backgroundImageUrl = A2($elm$core$Maybe$withDefault, '', variant.N.bu);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bb b--black-10 pa1 pointer'),
				A2($elm$html$Html$Attributes$style, 'display', 'grid'),
				A2($elm$html$Html$Attributes$style, 'grid-template-columns', '120px auto 120px'),
				$elm$html$Html$Events$onClick(
				$author$project$Page$ItemEdit$OpenVariantEdit(variant))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						A2($elm$html$Html$Attributes$style, 'width', '120px')
					]),
				_List_fromArray(
					[
						viewImg(variant.bo),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tc')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Image')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 pv1 ph3')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw6 pv1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(variant.b$)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pv1 flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(variant.aC))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ml2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Batch Size')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pv1 flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(variant.aF))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ml2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Count Max')
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pv1 flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromFloat(variant.aV))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ml2')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Rarity Factor')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column justify-between'),
						A2($elm$html$Html$Attributes$style, 'width', '120px')
					]),
				_List_fromArray(
					[
						viewImg(backgroundImageUrl),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tc mt2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Background')
							]))
					]))
			]));
};
var $author$project$Page$ItemEdit$viewVariants = function (model) {
	var sortedVariants = $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.aV;
			},
			$elm$core$Dict$values(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (_v0, v) {
							return _Utils_eq(
								$author$project$Type$Variant$toItemId(v),
								model.bn);
						}),
					model.$7))));
	var content = _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-column'),
					A2($elm$html$Html$Attributes$style, 'margin', '10px 10px')
				]),
			A2($elm$core$List$map, $author$project$Page$ItemEdit$viewVariant, sortedVariants))
		]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mv3')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Ox$Box$outlined,
				'Variants',
				2,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'padding', '0')
					]),
				content,
				$elm$core$Maybe$Nothing)
			]));
};
var $author$project$Page$ItemEdit$viewBasicInfoColumn = function (model) {
	var timeZone = $author$project$Type$Session$timeZone(model.db);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-70-ns flex flex-column pr3-ns mb3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Ox$Text$outlined,
						'Title',
						A3($author$project$Page$ItemEdit$labelState, model, 0, model.ao.b$),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('titleField'),
								$elm$html$Html$Attributes$value(model.ao.b$),
								$elm$html$Html$Attributes$autocomplete(false),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Events$onInput(
								$author$project$Page$ItemEdit$UpdateField(0)),
								$elm$html$Html$Events$onFocus(
								$author$project$Page$ItemEdit$SetFieldFocus(0)),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$ItemEdit$UnsetFieldFocus(0)),
								$elm$html$Html$Attributes$name('title')
							]),
						$elm$core$Maybe$Nothing)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3 w-100 mr2')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Ratio',
								A3($author$project$Page$ItemEdit$labelState, model, 1, model.I),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('ratioField'),
										$elm$html$Html$Attributes$value(model.I),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Attributes$min('0'),
										$elm$html$Html$Attributes$max('1'),
										$elm$html$Html$Attributes$step('0.0001'),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Events$onInput(
										$author$project$Page$ItemEdit$UpdateField(1)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$ItemEdit$SetFieldFocus(1)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$ItemEdit$UnsetFieldFocus(1)),
										$elm$html$Html$Attributes$name('ratio')
									]),
								$elm$core$Maybe$Nothing)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb3  w-100')
							]),
						_List_fromArray(
							[
								A4(
								$author$project$Ox$Text$outlined,
								'Corner Radius',
								A3($author$project$Page$ItemEdit$labelState, model, 3, model.G),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('cornerRadiusField'),
										$elm$html$Html$Attributes$value(model.G),
										$elm$html$Html$Attributes$type_('number'),
										$elm$html$Html$Attributes$min('0'),
										$elm$html$Html$Attributes$max('1'),
										$elm$html$Html$Attributes$step('0.01'),
										$elm$html$Html$Attributes$autocomplete(false),
										$elm$html$Html$Attributes$required(true),
										$elm$html$Html$Events$onInput(
										$author$project$Page$ItemEdit$UpdateField(3)),
										$elm$html$Html$Events$onFocus(
										$author$project$Page$ItemEdit$SetFieldFocus(3)),
										$elm$html$Html$Events$onBlur(
										$author$project$Page$ItemEdit$UnsetFieldFocus(3)),
										$elm$html$Html$Attributes$name('cornerRadius')
									]),
								$elm$core$Maybe$Nothing)
							]))
					])),
				A2($author$project$Page$ItemEdit$viewDate, timeZone, model.ao.aI),
				$author$project$Page$ItemEdit$viewTagContainer(model),
				$author$project$Page$ItemEdit$viewVariants(model)
			]));
};
var $author$project$Page$ItemEdit$BackImage = 0;
var $author$project$Page$ItemEdit$OpenImageLibrary = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ItemEdit$RemoveImg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ItemEdit$viewPreview = F4(
	function (title, url, uploadMsg, removeMsg) {
		var addOrUpdateStr = (url === '') ? 'Add' : 'Update';
		var viewImg = (url === '') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba mb1 b--black-20 pointer w-100 bg-near-white b--black-10 pointer tc relative'),
					A2($elm$html$Html$Attributes$style, 'padding-top', '100%'),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-100 h-100 flex flex-column items-center justify-center'),
							A2($elm$html$Html$Attributes$style, 'top', '0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white br2 pa2 mt2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(addOrUpdateStr)
								]))
						]))
				])) : A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					$elm$html$Html$Attributes$class('ba b--black-20 pointer'),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mb1 w-100')
				]),
			_List_fromArray(
				[
					viewImg,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center justify-between f7')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(removeMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Remove')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(uploadMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(addOrUpdateStr)
								]))
						]))
				]));
	});
var $author$project$Page$ItemEdit$viewImages = F2(
	function (model, imageKind) {
		var title = $author$project$Page$ItemEdit$imageKindToString(imageKind);
		var imageUrl = A2($elm$core$Maybe$withDefault, '', model.ao.N.aO);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f6 b--black-20 mb3')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Page$ItemEdit$viewPreview,
							title,
							imageUrl,
							$author$project$Page$ItemEdit$OpenImageLibrary(imageKind),
							$author$project$Page$ItemEdit$RemoveImg(imageKind))
						]))
				]));
	});
var $author$project$Page$ItemEdit$viewSideColumn = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-30-ns tl')
			]),
		_List_fromArray(
			[
				A2($author$project$Page$ItemEdit$viewImages, model, 0)
			]));
};
var $author$project$Page$ItemEdit$viewSection = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2 t1 mt3 cf')
			]),
		_List_fromArray(
			[
				$author$project$Page$ItemEdit$viewBasicInfoColumn(model),
				$author$project$Page$ItemEdit$viewSideColumn(model)
			]));
};
var $author$project$Page$ItemEdit$viewMain = F2(
	function (model, _v0) {
		var setPath = '/set/' + (model.dd + '#items');
		var saveBtn = A2(
			$author$project$Ox$Button$outlined,
			'Save',
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('submit'),
					$elm$html$Html$Events$onClick($author$project$Page$ItemEdit$UpdateItem)
				]));
		var breadcrumbTitlesUrls = _List_fromArray(
			[
				_Utils_Tuple2('Sets', '/sets'),
				_Utils_Tuple2(model.bT.b$, setPath),
				_Utils_Tuple2('Item', '')
			]);
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif tl')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							$author$project$Page$viewBreadCrumb(breadcrumbTitlesUrls),
							A3($author$project$Page$viewPageTitle, model.b$, model.bP, saveBtn),
							$author$project$Page$ItemEdit$viewSection(model),
							$author$project$Page$ItemEdit$viewModalOrNot(model)
						]))
				]));
	});
var $author$project$Page$ItemEdit$viewBody = function (model) {
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$ItemEdit$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$ItemEdit$viewMain, model, user),
				$author$project$Page$viewFooter(model.bP)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$ItemEdit$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.ao.b$),
		$author$project$Page$ItemEdit$viewBody(model));
};
var $author$project$Page$PackEdit$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PackEdit$UpdatePack = {$: 8};
var $author$project$Page$PackEdit$viewImageLibrayModal = F2(
	function (model, modalModel) {
		return A2(
			$elm$html$Html$map,
			$author$project$Page$PackEdit$ImageLibraryMsg,
			$author$project$Modal$ImageLibrary$view(modalModel));
	});
var $author$project$Page$PackEdit$viewModalOrNot = function (model) {
	var _v0 = model.v;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var subModel = _v0.a.a;
			return A2($author$project$Page$PackEdit$viewImageLibrayModal, model, subModel);
		} else {
			var _v1 = _v0.a;
			return A2($elm$html$Html$span, _List_Nil, _List_Nil);
		}
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$PackEdit$Count = 1;
var $author$project$Page$PackEdit$Desc = 2;
var $author$project$Page$PackEdit$OddsDesc = 3;
var $author$project$Page$PackEdit$Title = 0;
var $author$project$Page$PackEdit$UpdateInput = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$PackEdit$viewDate = F2(
	function (zone, dateInt) {
		var weekday = A2($author$project$Type$DateTime$intToWeekday, zone, dateInt);
		var time = A2($author$project$Type$DateTime$intToTime, zone, dateInt);
		var date = A2($author$project$Type$DateTime$intToDate, zone, dateInt);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex ba br2 b--black-20 pa2 mt1 black-50 bg-near-white')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(weekday)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ph1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(date)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(time)
						]))
				]));
	});
var $author$project$Page$PackEdit$viewBasicInfoColumnA = function (model) {
	var timeZone = $author$project$Type$Session$timeZone(model.db);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-70-ns flex flex-column pr1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 mt3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Title')
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$name('title'),
						$elm$html$Html$Attributes$value(model.ae.b$),
						$elm$html$Html$Attributes$class('w-100 f5 mt1 pa2 b--black-20 ba b1 br2'),
						$elm$html$Html$Events$onInput(
						$author$project$Page$PackEdit$UpdateInput(0))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 mt3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Description')
					])),
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$name('description'),
						$elm$html$Html$Attributes$value(model.ae.cl),
						$elm$html$Html$Attributes$class('w-100 f5 mt1 pa2 b--black-20 ba b1 br2'),
						$elm$html$Html$Attributes$rows(4),
						$elm$html$Html$Events$onInput(
						$author$project$Page$PackEdit$UpdateInput(2))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 mt3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Odds Description')
					])),
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$name('description'),
						$elm$html$Html$Attributes$value(model.ae.by),
						$elm$html$Html$Attributes$class('w-100 f5 mt1 pa2 b--black-20 ba b1 br2'),
						$elm$html$Html$Attributes$rows(4),
						$elm$html$Html$Events$onInput(
						$author$project$Page$PackEdit$UpdateInput(3))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 mt3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Count')
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$name('count'),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(model.ae.a8)),
						$elm$html$Html$Attributes$class('w-100 f5 mt1 pa2 b--black-20 ba b1 br2'),
						$elm$html$Html$Events$onInput(
						$author$project$Page$PackEdit$UpdateInput(1)),
						$elm$html$Html$Attributes$type_('number')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw6 mt3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Date Created')
					])),
				A2($author$project$Page$PackEdit$viewDate, timeZone, model.ae.ck)
			]));
};
var $author$project$Page$PackEdit$viewBasicInfoColumnB = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-30-ns tl pl3')
			]),
		_List_Nil);
};
var $author$project$Page$PackEdit$viewBasicInfo = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph1 t1 mt3 cf')
			]),
		_List_fromArray(
			[
				$author$project$Page$PackEdit$viewBasicInfoColumnA(model),
				$author$project$Page$PackEdit$viewBasicInfoColumnB(model)
			]));
};
var $author$project$Page$PackEdit$viewItem = function (item) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bb b--black-10 mt2 pv2')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(item.aT)
			]));
};
var $author$project$Page$PackEdit$viewItems = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 pa2')
			]),
		A2($elm$core$List$map, $author$project$Page$PackEdit$viewItem, model.ae.cH));
};
var $author$project$Page$PackEdit$viewSection = function (model) {
	var _v0 = model.w;
	if (!_v0) {
		return $author$project$Page$PackEdit$viewBasicInfo(model);
	} else {
		return $author$project$Page$PackEdit$viewItems(model);
	}
};
var $author$project$Page$PackEdit$Items = 1;
var $author$project$Page$PackEdit$SetPanel = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PackEdit$viewTabs = function (model) {
	var tab = F2(
		function (title, panel) {
			return _Utils_eq(panel, model.w) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2 mh1 bg-white')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2 mh1 pointer'),
						$elm$html$Html$Events$onClick(
						$author$project$Page$PackEdit$SetPanel(panel))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]));
		});
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex bg-light-gray mt3 pt1 mh1')
			]),
		_List_fromArray(
			[
				A2(tab, 'Basic Info', 0),
				A2(tab, 'Items', 1)
			]));
};
var $author$project$Page$PackEdit$viewMain = F2(
	function (model, _v0) {
		var setPath = '/set/' + model.dd;
		var saveBtn = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pv1 ph2 ba b--black-20 pointer br2'),
					$elm$html$Html$Events$onClick($author$project$Page$PackEdit$UpdatePack)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Save')
				]));
		var breadcrumbTitlesUrls = _List_fromArray(
			[
				_Utils_Tuple2('Sets', '/sets'),
				_Utils_Tuple2(model.bT.b$, setPath),
				_Utils_Tuple2('Packs', '')
			]);
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif tl')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							$author$project$Page$viewBreadCrumb(breadcrumbTitlesUrls),
							A3($author$project$Page$viewPageTitle, model.ae.b$, model.bP, saveBtn),
							$author$project$Page$PackEdit$viewTabs(model),
							$author$project$Page$PackEdit$viewSection(model),
							$author$project$Page$PackEdit$viewModalOrNot(model)
						]))
				]));
	});
var $author$project$Page$PackEdit$viewBody = function (model) {
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var params = _v0.a;
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$PackEdit$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$PackEdit$viewMain, model, user),
				$author$project$Page$viewFooter(model.bP)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$PackEdit$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.ae.b$),
		$author$project$Page$PackEdit$viewBody(model));
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Page$Profile$viewPersonalInfo = function (profile) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(profile.cv),
						A2($elm$html$Html$Attributes$style, 'border-radius', '50%'),
						A2($elm$html$Html$Attributes$style, 'max-width', '160px')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt3 f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(profile.dn)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt3 f5 fw3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(profile.cf)
					]))
			]));
};
var $author$project$Page$Profile$viewLoggedIn = F3(
	function (profile, model, _v0) {
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir ph2 sans-serif')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							$author$project$Page$Profile$viewPersonalInfo(profile)
						]))
				]));
	});
var $author$project$Page$Profile$viewMobileHeader = function (model) {
	var rightMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex items-center tl pv2 pl3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '1.5em'),
						A2($elm$html$Html$Attributes$style, 'height', '1.5em'),
						A2($elm$html$Html$Attributes$style, 'margin-left', '-10px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$chevronLeft($author$project$Type$Color$hiliteBlue)
					])),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href('/home'),
						$elm$html$Html$Attributes$class('link black dib'),
						A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('My Room')
					]))
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		model.b$,
		model.bP,
		1,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Page$viewMobileNavAlt = F2(
	function (menu, screenSize) {
		var mobileView = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-white avenir fixed w-100 tc bottom-0 bt b--black-20'),
					A2($elm$html$Html$Attributes$style, 'z-index', '3000')
				]),
			_List_fromArray(
				[menu]));
		var desktopView = A2($elm$html$Html$span, _List_Nil, _List_Nil);
		switch (screenSize.$) {
			case 0:
				return mobileView;
			case 1:
				return mobileView;
			default:
				return desktopView;
		}
	});
var $author$project$Page$Profile$viewLoggedInOrGuest = function (model) {
	var _v0 = _Utils_Tuple2(model.db, model.cP);
	if ((_v0.a.$ === 3) && (!_v0.b.$)) {
		var _v1 = _v0.a;
		var user = _v1.b;
		var profile = _v0.b.a;
		var menu = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pa2')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('ern@mac.com')
				]));
		return _List_fromArray(
			[
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewMobileNavAlt, menu, model.bP),
				A4($elm$html$Html$Lazy$lazy3, $author$project$Page$Profile$viewLoggedIn, profile, model, user),
				$author$project$Page$Profile$viewMobileHeader(model)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$Profile$view = function (model) {
	return _Utils_Tuple2(
		model.b$,
		$author$project$Page$Profile$viewLoggedInOrGuest(model));
};
var $author$project$Page$SetEdit$PageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$SetEdit$viewImageLibrary = F2(
	function (model, modalModel) {
		return A2(
			$elm$html$Html$map,
			$author$project$Page$SetEdit$ImageLibraryMsg,
			$author$project$Modal$ImageLibrary$view(modalModel));
	});
var $author$project$Modal$Pack$Close = {$: 1};
var $author$project$Modal$Pack$viewCloseOrCancelBtn = function (model) {
	return model.az ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph3 pv2 f5 pointer'),
				$elm$html$Html$Events$onClick($author$project$Modal$Pack$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph3 pv2 f5 pointer'),
				$elm$html$Html$Events$onClick($author$project$Modal$Pack$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Close')
			]));
};
var $author$project$Modal$Pack$Create = {$: 0};
var $elm$svg$Svg$animateTransform = $elm$svg$Svg$trustedNode('animateTransform');
var $elm$svg$Svg$Attributes$attributeName = _VirtualDom_attribute('attributeName');
var $elm$svg$Svg$Attributes$dur = _VirtualDom_attribute('dur');
var $elm$svg$Svg$Attributes$from = function (value) {
	return A2(
		_VirtualDom_attribute,
		'from',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Attributes$repeatCount = _VirtualDom_attribute('repeatCount');
var $elm$svg$Svg$Attributes$to = function (value) {
	return A2(
		_VirtualDom_attribute,
		'to',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$type_ = _VirtualDom_attribute('type');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$View$Svg$spinner = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$id('svg-spinner'),
			$elm$svg$Svg$Attributes$x('0px'),
			$elm$svg$Svg$Attributes$y('0px'),
			$elm$svg$Svg$Attributes$viewBox('0 0 50 50')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$id('spinner'),
					$elm$svg$Svg$Attributes$fill($author$project$Type$Color$hiliteBlue),
					$elm$svg$Svg$Attributes$d('M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$animateTransform,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$attributeName('transform'),
							$elm$svg$Svg$Attributes$type_('rotate'),
							$elm$svg$Svg$Attributes$from('0 25 25'),
							$elm$svg$Svg$Attributes$to('360 25 25'),
							$elm$svg$Svg$Attributes$dur('0.5s'),
							$elm$svg$Svg$Attributes$repeatCount('indefinite')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Modal$Pack$viewSaveBtn = function (model) {
	return (model.aZ === 1) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('br2 ba b--black-20 ph4 pv2 f5 pointer mr3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '18px'),
						A2($elm$html$Html$Attributes$style, 'height', '18px')
					]),
				_List_fromArray(
					[$author$project$View$Svg$spinner]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('br2 ba b--black-20 ph3 pv2 f5 pointer mr3'),
				$elm$html$Html$Events$onClick($author$project$Modal$Pack$Create)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Save')
			]));
};
var $author$project$Modal$Pack$viewEditMenu = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between')
			]),
		_List_fromArray(
			[
				$author$project$Modal$Pack$viewCloseOrCancelBtn(model),
				$author$project$Modal$Pack$viewSaveBtn(model)
			]));
};
var $author$project$Modal$Pack$viewMobileHeader = function (model) {
	var rightMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pl2 f5 pointer tr pv2 pr3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$Pack$Create)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Save')
			]));
	var leftMenu = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pv2 pr2 f5 pointer tl pv2 pl3'),
				A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
				$elm$html$Html$Events$onClick($author$project$Modal$Pack$Close)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Cancel')
			]));
	return A4(
		$author$project$Page$viewMobileHeader,
		'title',
		model.bP,
		0,
		_Utils_Tuple2(leftMenu, rightMenu));
};
var $author$project$Modal$Pack$Count = 0;
var $author$project$Modal$Pack$UpdateInput = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Modal$Pack$viewSection = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('cf')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fl w-50-ns pa3')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw6 mt3')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Items per pack')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$name('countField'),
								$elm$html$Html$Attributes$id('countField'),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(model.ae.a8)),
								$elm$html$Html$Attributes$min('1'),
								$elm$html$Html$Attributes$class('w-100 f5 mt1 pa2 b--black-20 ba b1 br2'),
								$elm$html$Html$Events$onInput(
								$author$project$Modal$Pack$UpdateInput(0))
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Modal$Pack$viewModal = function (model) {
	var viewMobileModal = A2(
		$elm$html$Html$div,
		$author$project$Ox$Theme$mobileModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						$elm$html$Html$Attributes$class('overflow-y-scroll'),
						$elm$html$Html$Attributes$id('print-grid')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShim(model.bP),
						$author$project$Modal$Pack$viewSection(model),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tc mt2 pb2')
							]),
						_List_Nil)
					])),
				$author$project$Modal$Pack$viewMobileHeader(model)
			]));
	var viewDesktopModal = A2(
		$elm$html$Html$div,
		$author$project$Ox$Theme$desktopModalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('overflow-y-scroll'),
						A2($elm$html$Html$Attributes$style, 'padding-top', '140px'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '98%'),
						$elm$html$Html$Attributes$id('print-grid')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute top-0 w-100 bg-white'),
						A2($elm$html$Html$Attributes$style, 'border-radius', '6px')
					]),
				_List_fromArray(
					[
						$author$project$Page$viewShimTop('10px'),
						$author$project$Modal$Pack$viewEditMenu(model),
						$author$project$Modal$Pack$viewSection(model)
					]))
			]));
	var _v0 = model.bP;
	switch (_v0.$) {
		case 0:
			return viewMobileModal;
		case 1:
			return viewMobileModal;
		default:
			return viewDesktopModal;
	}
};
var $author$project$Modal$Pack$view = function (model) {
	var modalStyles = function () {
		var _v0 = model.bP;
		switch (_v0.$) {
			case 0:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			case 1:
				return $author$project$Ox$Theme$mobileModalMaskStyles(_List_Nil);
			default:
				return $author$project$Ox$Theme$desktopModalMaskStyles(_List_Nil);
		}
	}();
	return A2(
		$elm$html$Html$div,
		modalStyles,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Modal$Pack$viewModal(model)
					]))
			]));
};
var $author$project$Page$SetEdit$viewPackModal = F2(
	function (model, modalModel) {
		return A2(
			$elm$html$Html$map,
			$author$project$Page$SetEdit$PackModalMsg,
			$author$project$Modal$Pack$view(modalModel));
	});
var $author$project$Page$SetEdit$viewModalOrNot = function (model) {
	var _v0 = model.v;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var _v1 = _v0.a;
			var imageKind = _v1.a;
			var subModel = _v1.b;
			return A2($author$project$Page$SetEdit$viewImageLibrary, model, subModel);
		} else {
			var subModel = _v0.a.a;
			return A2($author$project$Page$SetEdit$viewPackModal, model, subModel);
		}
	} else {
		return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$SetEdit$UpdateSetDetails = {$: 3};
var $author$project$Type$CSet$intToItemTemplateName = function (num) {
	switch (num) {
		case 1:
			return 'Stickers';
		case 2:
			return 'Cards';
		case 3:
			return '3d';
		case 4:
			return 'Custom';
		case 5:
			return 'Mixed';
		default:
			return 'Undefinied';
	}
};
var $author$project$Ox$Chip$outlined = F2(
	function (title, attributes) {
		var attributeList = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f6 fw5 link br4 inline-flex items-center tc'),
					A2($elm$html$Html$Attributes$style, 'letter-spacing', '.07em'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px 15px 10px 15px'),
					A2($elm$html$Html$Attributes$style, 'height', '36px'),
					A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$btnBorderColor),
					A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$btnTextColor),
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent')
				]),
			attributes);
		return A2(
			$elm$html$Html$div,
			attributeList,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Page$SetEdit$DescriptionField = 1;
var $author$project$Page$SetEdit$SetFieldFocus = function (a) {
	return {$: 23, a: a};
};
var $author$project$Page$SetEdit$TitleField = 0;
var $author$project$Page$SetEdit$UnsetFieldFocus = function (a) {
	return {$: 24, a: a};
};
var $author$project$Page$SetEdit$UpdateField = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Page$SetEdit$labelState = F3(
	function (model, formField, value) {
		return _Utils_eq(
			model.aM,
			$elm$core$Maybe$Just(formField)) ? 0 : (($elm$core$String$length(value) > 0) ? 2 : 1);
	});
var $author$project$Ox$Textarea$outlined = F4(
	function (labelName, state, inputAttrs, maybeAlert) {
		var labelAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
				A2($elm$html$Html$Attributes$style, 'top', '26px'),
				A2($elm$html$Html$Attributes$style, 'left', '16px'),
				A2($elm$html$Html$Attributes$style, 'right', 'initial'),
				A2($elm$html$Html$Attributes$style, 'transform-origin', 'left top'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
				$elm$html$Html$Attributes$class('infoLabel')
			]);
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'outline', '0'),
					A2($elm$html$Html$Attributes$style, '-webkit-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, '-moz-appearance', 'none'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'padding', '20px 14px 6px'),
					A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
					A2($elm$html$Html$Attributes$style, 'font-size', '16px'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
					A2($elm$html$Html$Attributes$style, 'min-height', '56px'),
					A2($elm$html$Html$Attributes$style, 'box-sizing', 'border-box')
				]),
			inputAttrs);
		var helper = function () {
			if (!maybeAlert.$) {
				var alert = maybeAlert.a;
				var color = $author$project$Ox$Theme$colorForHelper(alert.cK);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'font-size', '12px'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '4px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(alert.bv)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var _v0 = function () {
			switch (state) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryDarkColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.4)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid rgba(128,128,128,.8)'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'rgb(255,255,255)')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-175%) scale(0.75)'),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)'),
								A2($elm$html$Html$Attributes$style, 'transition', '.1s ease-in-out'),
								A2($elm$html$Html$Attributes$style, 'background-color', 'white'),
								A2($elm$html$Html$Attributes$style, 'padding', '0 4px'),
								A2($elm$html$Html$Attributes$style, 'margin-left', '-4px')
							]),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'border', '1px solid ' + $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'background', 'transparent'),
								$elm$html$Html$Attributes$disabled(true),
								A2($elm$html$Html$Attributes$style, 'color', $author$project$Ox$Theme$primaryColor),
								A2($elm$html$Html$Attributes$style, 'color', 'rgba(0,0,0,.6)')
							]));
			}
		}();
		var addLabelAttributes = _v0.a;
		var addInputAttributes = _v0.b;
		var newInputAttributes = _Utils_ap(inputAttributes, addInputAttributes);
		var newLabelAttributes = _Utils_ap(labelAttributes, addLabelAttributes);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative'),
					A2($elm$html$Html$Attributes$style, 'border-radius', '4px 4px 0 0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					newLabelAttributes,
					_List_fromArray(
						[
							$elm$html$Html$text(labelName)
						])),
					A2($elm$html$Html$textarea, newInputAttributes, _List_Nil),
					helper
				]));
	});
var $author$project$Page$SetEdit$EditDate = {$: 19};
var $author$project$Ox$Icon$calendar = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$SetEdit$viewDate = F2(
	function (zone, dateInt) {
		var weekday = A2($author$project$Type$DateTime$intToWeekday, zone, dateInt);
		var time = A2($author$project$Type$DateTime$intToTime, zone, dateInt);
		var icon = $author$project$Ox$Icon$calendar('black');
		var date = A2($author$project$Type$DateTime$intToDate, zone, dateInt);
		var _v0 = (!dateInt) ? _Utils_Tuple2('', 1) : _Utils_Tuple2(weekday + (', ' + (date + (' ' + time))), 2);
		var btnTitle = _v0.a;
		var status = _v0.b;
		return A4(
			$author$project$Ox$Text$outlined,
			'Date Published',
			status,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('datePublishedField'),
					$elm$html$Html$Attributes$value(btnTitle),
					$elm$html$Html$Attributes$name('datePublished'),
					$elm$html$Html$Events$onClick($author$project$Page$SetEdit$EditDate)
				]),
			$elm$core$Maybe$Nothing);
	});
var $author$project$Page$SetEdit$viewPicker = F2(
	function (model, datePickerModel) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative'),
					A2($elm$html$Html$Attributes$style, 'z-index', '6000')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute'),
							A2($elm$html$Html$Attributes$style, 'left', '0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Page$SetEdit$DatePickerMsg,
							$author$project$Ox$DatePicker$view(datePickerModel))
						])),
					A2($author$project$Page$SetEdit$viewDate, model.bZ, model.bT.aI)
				]));
	});
var $author$project$Page$SetEdit$viewDateOrPicker = function (model) {
	var _v0 = model.z;
	if (!_v0.$) {
		var datePickerModel = _v0.a;
		return A2($author$project$Page$SetEdit$viewPicker, model, datePickerModel);
	} else {
		return A2($author$project$Page$SetEdit$viewDate, model.bZ, model.bT.aI);
	}
};
var $author$project$Page$SetEdit$BatchLimitField = 3;
var $author$project$Page$SetEdit$BatchSizeField = 2;
var $author$project$Page$SetEdit$CountMaxField = 4;
var $author$project$Page$SetEdit$viewEditionType = function (model) {
	var viewCountMax = (!model.bT.aZ) ? A4(
		$author$project$Ox$Text$outlined,
		'Print Limit',
		A3(
			$author$project$Page$SetEdit$labelState,
			model,
			4,
			$elm$core$String$fromInt(model.bT.aF)),
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('countMaxField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.aF)),
				$elm$html$Html$Attributes$autocomplete(false),
				$elm$html$Html$Events$onInput(
				$author$project$Page$SetEdit$UpdateField(4)),
				$elm$html$Html$Events$onFocus(
				$author$project$Page$SetEdit$SetFieldFocus(4)),
				$elm$html$Html$Events$onBlur(
				$author$project$Page$SetEdit$UnsetFieldFocus(4)),
				$elm$html$Html$Attributes$name('countMax')
			]),
		$elm$core$Maybe$Nothing) : A4(
		$author$project$Ox$Text$outlined,
		'Print Limit',
		3,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('countMaxField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.aF)),
				$elm$html$Html$Attributes$disabled(true),
				$elm$html$Html$Attributes$name('countMax')
			]),
		$elm$core$Maybe$Nothing);
	var viewBatchSize = (!model.bT.aZ) ? A4(
		$author$project$Ox$Text$outlined,
		'Batch Size',
		A3(
			$author$project$Page$SetEdit$labelState,
			model,
			2,
			$elm$core$String$fromInt(model.bT.aC)),
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('batchSizeField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.aC)),
				$elm$html$Html$Attributes$autocomplete(false),
				$elm$html$Html$Events$onInput(
				$author$project$Page$SetEdit$UpdateField(2)),
				$elm$html$Html$Events$onFocus(
				$author$project$Page$SetEdit$SetFieldFocus(2)),
				$elm$html$Html$Events$onBlur(
				$author$project$Page$SetEdit$UnsetFieldFocus(2)),
				$elm$html$Html$Attributes$name('batchSize')
			]),
		$elm$core$Maybe$Nothing) : A4(
		$author$project$Ox$Text$outlined,
		'Batch Size',
		3,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('batchSizeField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.aC)),
				$elm$html$Html$Attributes$disabled(true),
				$elm$html$Html$Attributes$name('batchSize')
			]),
		$elm$core$Maybe$Nothing);
	var viewBatchLimit = (!model.bT.aZ) ? A4(
		$author$project$Ox$Text$outlined,
		'Batch Limit',
		A3(
			$author$project$Page$SetEdit$labelState,
			model,
			3,
			$elm$core$String$fromInt(model.bT.ap)),
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('maxBatchesField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.ap)),
				$elm$html$Html$Attributes$autocomplete(false),
				$elm$html$Html$Events$onInput(
				$author$project$Page$SetEdit$UpdateField(3)),
				$elm$html$Html$Events$onFocus(
				$author$project$Page$SetEdit$SetFieldFocus(3)),
				$elm$html$Html$Events$onBlur(
				$author$project$Page$SetEdit$UnsetFieldFocus(3)),
				$elm$html$Html$Attributes$name('maxBatches')
			]),
		$elm$core$Maybe$Nothing) : A4(
		$author$project$Ox$Text$outlined,
		'Batch Limit',
		3,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('batchSizeField'),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(model.bT.ap)),
				$elm$html$Html$Attributes$disabled(true),
				$elm$html$Html$Attributes$name('batchSize')
			]),
		$elm$core$Maybe$Nothing);
	return (!model.bT.aK) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt1 br2 ba b--black-20 ')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex bg-light-gray br2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pa2 br2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Standard Edition Info')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2')
					]),
				_List_fromArray(
					[viewBatchSize])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2')
					]),
				_List_fromArray(
					[viewBatchLimit]))
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt1 br2 ba b--black-20 ')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex bg-light-gray br2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pa2 br2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Limited Edition Info')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2')
					]),
				_List_fromArray(
					[viewCountMax]))
			]));
};
var $author$project$Page$SetEdit$EnterTag = {$: 6};
var $author$project$Page$SetEdit$TagField = 5;
var $author$project$Page$SetEdit$ClearTag = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$SetEdit$viewTagChip = function (title) {
	return A3(
		$author$project$Ox$Chip$outlinedIcon,
		title,
		$author$project$Ox$Icon$cancel('black'),
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$Page$SetEdit$ClearTag(title))
			]));
};
var $author$project$Page$SetEdit$viewTagContainer = function (model) {
	var tags = A2($elm$core$List$map, $author$project$Page$SetEdit$viewTagChip, model.bT.ag);
	var tagInput = A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pa2'),
				A2($elm$html$Html$Attributes$style, 'flex', '1'),
				A2($elm$html$Html$Attributes$style, 'border', '0'),
				$elm$html$Html$Attributes$id('tagInput'),
				$elm$html$Html$Events$onInput(
				$author$project$Page$SetEdit$UpdateField(5)),
				A2(
				$Skinney$keyboard_events$Keyboard$Events$on,
				0,
				_List_fromArray(
					[
						_Utils_Tuple2($ohanhi$keyboard$Keyboard$Enter, $author$project$Page$SetEdit$EnterTag),
						_Utils_Tuple2($ohanhi$keyboard$Keyboard$Escape, $author$project$Page$SetEdit$EnterTag)
					])),
				$elm$html$Html$Attributes$value(model.Y)
			]),
		_List_Nil);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Ox$Box$outlined,
				'Tags',
				2,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'flex-wrap', 'wrap'),
						A2($elm$html$Html$Attributes$style, 'gap', '6px')
					]),
				_Utils_ap(
					tags,
					_List_fromArray(
						[tagInput])),
				$elm$core$Maybe$Nothing)
			]));
};
var $author$project$Page$SetEdit$viewDetailsColumnA = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-70-ns flex flex-column pr3-ns mb3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Ox$Text$outlined,
						'Title',
						A3($author$project$Page$SetEdit$labelState, model, 0, model.bT.b$),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('titleField'),
								$elm$html$Html$Attributes$value(model.bT.b$),
								$elm$html$Html$Attributes$autocomplete(false),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Events$onInput(
								$author$project$Page$SetEdit$UpdateField(0)),
								$elm$html$Html$Events$onFocus(
								$author$project$Page$SetEdit$SetFieldFocus(0)),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$SetEdit$UnsetFieldFocus(0)),
								$elm$html$Html$Attributes$name('title')
							]),
						$elm$core$Maybe$Nothing)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mb3')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Ox$Textarea$outlined,
						'Description',
						A3($author$project$Page$SetEdit$labelState, model, 1, model.bT.cl),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('descriptionField'),
								$elm$html$Html$Attributes$value(model.bT.cl),
								$elm$html$Html$Attributes$rows(3),
								$elm$html$Html$Events$onInput(
								$author$project$Page$SetEdit$UpdateField(1)),
								$elm$html$Html$Events$onFocus(
								$author$project$Page$SetEdit$SetFieldFocus(1)),
								$elm$html$Html$Events$onBlur(
								$author$project$Page$SetEdit$UnsetFieldFocus(1))
							]),
						$elm$core$Maybe$Nothing)
					])),
				$author$project$Page$SetEdit$viewDateOrPicker(model),
				$author$project$Page$SetEdit$viewTagContainer(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column mt2')
					]),
				_List_fromArray(
					[
						$author$project$Page$SetEdit$viewEditionType(model)
					]))
			]));
};
var $author$project$Page$SetEdit$OpenImageLibrary = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$SetEdit$RemoveImg = {$: 10};
var $author$project$Page$SetEdit$SetImage = {$: 0};
var $author$project$Page$SetEdit$viewPreview = F4(
	function (title, url, uploadMsg, removeMsg) {
		var _v0 = _Utils_Tuple2('1 X 1', '100%');
		var aspectRatio = _v0.a;
		var paddingTop = _v0.b;
		var viewImg = (url === '') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba mb1 b--black-20 pointer w-100 bg-near-white b--black-10 pointer tc relative'),
					A2($elm$html$Html$Attributes$style, 'padding-top', paddingTop),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-100 h-100 flex flex-column items-center justify-center'),
							A2($elm$html$Html$Attributes$style, 'top', '0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(aspectRatio),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white br2 pa2 mt2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Update')
								]))
						]))
				])) : A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					$elm$html$Html$Attributes$class('ba b--black-20 pointer'),
					$elm$html$Html$Events$onClick(uploadMsg)
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100')
				]),
			_List_fromArray(
				[
					viewImg,
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center justify-between f7')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(removeMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Remove')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ba ph2 pv1 br2 b--black-20 pointer'),
									$elm$html$Html$Events$onClick(uploadMsg)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Replace')
								]))
						]))
				]));
	});
var $author$project$Page$SetEdit$viewImages = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt0')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br2 b--black-20')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Page$SetEdit$viewPreview,
						'Set Image',
						model.bT.bo,
						$author$project$Page$SetEdit$OpenImageLibrary($author$project$Page$SetEdit$SetImage),
						$author$project$Page$SetEdit$RemoveImg)
					]))
			]));
};
var $author$project$Page$SetEdit$VariantSummaryImage = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$SetEdit$viewVariantSummary = F2(
	function (index, variant) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pv1 bb b--black-20'),
					A2($elm$html$Html$Attributes$style, 'display', 'grid'),
					A2($elm$html$Html$Attributes$style, 'grid-template-columns', '90px auto')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ba b--black-20 pointer'),
							A2($elm$html$Html$Attributes$style, 'height', '90px'),
							A2($elm$html$Html$Attributes$style, 'width', '90px'),
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetEdit$OpenImageLibrary(
								A2($author$project$Page$SetEdit$VariantSummaryImage, variant.b$, index)))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(variant.bo)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ph2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(variant.b$)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mt2 flex flex')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fw5')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromFloat(variant.aV))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ml2 fw3 f5 mb1')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Rarity Factor')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$SetEdit$viewVariantSummaries = function (model) {
	var variantSummaries = $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.aV;
			},
			model.bT.dp));
	var summary = A2($elm$core$List$indexedMap, $author$project$Page$SetEdit$viewVariantSummary, variantSummaries);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt1 br2 ba b--black-20 ')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex bg-light-gray br2')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pa2 br2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Variant Summaries')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pa2')
					]),
				summary)
			]));
};
var $author$project$Page$SetEdit$viewDetailsColumnB = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fl w-100 w-30-ns tl')
			]),
		_List_fromArray(
			[
				$author$project$Page$SetEdit$viewImages(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-column mt2')
					]),
				_List_fromArray(
					[
						$author$project$Page$SetEdit$viewVariantSummaries(model)
					]))
			]));
};
var $author$project$Page$SetEdit$viewDetails = function (model) {
	var saveBtn = (!model.w) ? A2(
		$author$project$Ox$Button$outlined,
		'Save',
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick($author$project$Page$SetEdit$UpdateSetDetails)
			])) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var packTypeStr = $author$project$Type$CSet$intToItemTemplateName(model.bT.cI);
	var prodTypeChip = A2($author$project$Ox$Chip$outlined, packTypeStr, _List_Nil);
	var editionTypeStr = (!model.bT.aK) ? 'Standard Edition' : 'Limited Edition';
	var editionTypeChip = A2($author$project$Ox$Chip$outlined, editionTypeStr, _List_Nil);
	var infoChips = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex'),
				A2($elm$html$Html$Attributes$style, 'gap', '6px')
			]),
		_List_fromArray(
			[prodTypeChip, editionTypeChip]));
	var buttons = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex'),
				A2($elm$html$Html$Attributes$style, 'gap', '6px')
			]),
		_List_fromArray(
			[saveBtn]));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph2 t1 mt3 cf')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex pt1 mb3 justify-between')
					]),
				_List_fromArray(
					[infoChips, buttons])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Page$SetEdit$viewDetailsColumnA(model),
						$author$project$Page$SetEdit$viewDetailsColumnB(model)
					]))
			]));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$SetEdit$EditItem = function (a) {
	return {$: 13, a: a};
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Page$SetEdit$viewPrintVariantBtn = function (variant) {
	return A2($author$project$Ox$Chip$outlined, variant.b$, _List_Nil);
};
var $author$project$Page$SetEdit$viewPrintVariants = function (item) {
	var variants = $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.aV;
			},
			item.b5));
	return A2(
		$elm$html$Html$td,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex'),
						A2($elm$html$Html$Attributes$style, 'gap', '6px')
					]),
				A2($elm$core$List$map, $author$project$Page$SetEdit$viewPrintVariantBtn, variants))
			]));
};
var $author$project$Page$SetEdit$viewItem = F2(
	function (model, item) {
		var thumbnail = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('b--black-20 ba'),
					A2($elm$html$Html$Attributes$style, 'height', '80px'),
					A2($elm$html$Html$Attributes$style, 'width', '80px')
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cG))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetEdit$EditItem(item.bn)),
							$elm$html$Html$Attributes$class('bb b--black-20 pv2 pointer')
						]),
					_List_fromArray(
						[thumbnail])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetEdit$EditItem(item.bn)),
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2 pointer')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(item.b$)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2 tc')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.ah))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2 tc')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromFloat(item.I))
						])),
					$author$project$Page$SetEdit$viewPrintVariants(item),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2 tc')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Ox$Button$outlined,
							'Edit',
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Page$SetEdit$EditItem(item.bn))
								]))
						]))
				]));
	});
var $author$project$Page$SetEdit$viewItems = function (model) {
	var tableHeaders = A2(
		$elm$html$Html$tr,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('#')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preview')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Title')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1 tc')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Template')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1 tc')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Ratio')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1 tc')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Variants')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1 tr')
					]),
				_List_Nil)
			]));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph1 tl mt3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 collapse')
					]),
				A2(
					$elm$core$List$cons,
					tableHeaders,
					A2(
						$elm$core$List$map,
						$author$project$Page$SetEdit$viewItem(model),
						model.P)))
			]));
};
var $author$project$Page$SetEdit$AddPack = {$: 17};
var $author$project$Ox$Theme$colorPrimary = '#333';
var $author$project$Ox$Icon$download = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$SetEdit$EditPack = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$SetEdit$HilitePack = function (a) {
	return {$: 16, a: a};
};
var $elm$html$Html$Events$onMouseOver = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$SetEdit$viewPack = F3(
	function (model, index, pack) {
		var timeCreated = A2($author$project$Type$DateTime$intToTime, model.bZ, pack.ck);
		var dateCreated = A2($author$project$Type$DateTime$intToDate, model.bZ, pack.ck);
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer'),
					$elm$html$Html$Events$onClick(
					$author$project$Page$SetEdit$EditPack(pack.bn)),
					$elm$html$Html$Events$onMouseOver(
					$author$project$Page$SetEdit$HilitePack(index))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(pack.b$)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(pack.a8))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(dateCreated),
							$elm$html$Html$text(' '),
							$elm$html$Html$text(timeCreated)
						]))
				]));
	});
var $author$project$Page$SetEdit$viewPacks = function (model) {
	var tableHeaders = A2(
		$elm$html$Html$tr,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preview')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Title')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Credits')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Count')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Date Created')
					]))
			]));
	var addBtn = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$Ox$Button$outlinedIcon,
				'Download CSV',
				$author$project$Ox$Icon$download($author$project$Ox$Theme$colorPrimary),
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$SetEdit$AddPack)
					]))
			]));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph1 tl mt3 relative')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('absolute top-0 right-0')
					]),
				_List_fromArray(
					[addBtn])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 collapse')
					]),
				A2(
					$elm$core$List$cons,
					tableHeaders,
					A2(
						$elm$core$List$indexedMap,
						$author$project$Page$SetEdit$viewPack(model),
						model.at)))
			]));
};
var $author$project$Page$SetEdit$viewSection = function (model) {
	var _v0 = model.w;
	switch (_v0) {
		case 0:
			return $author$project$Page$SetEdit$viewDetails(model);
		case 1:
			return $author$project$Page$SetEdit$viewItems(model);
		default:
			return $author$project$Page$SetEdit$viewPacks(model);
	}
};
var $author$project$Page$SetEdit$SetPanel = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$SetEdit$viewTabs = function (model) {
	var tab = F3(
		function (label, sub, panel) {
			var sublabel = (sub !== '') ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br-pill bg-black-20 black f7 fw3 pa1 tc mh1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(sub)
					])) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
			return _Utils_eq(panel, model.w) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pv2 ph3 bb bw1 b--black-100 flex items-end justify-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							])),
						sublabel
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pv2 ph3 bb bw1 pointer flex items-end justify-center'),
						A2($elm$html$Html$Attributes$style, 'border-color', 'transparent'),
						$elm$html$Html$Events$onClick(
						$author$project$Page$SetEdit$SetPanel(panel))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							])),
						sublabel
					]));
		});
	var packCount = $elm$core$String$fromInt(
		$elm$core$List$length(model.at));
	var itemCount = $elm$core$String$fromInt(
		$elm$core$List$length(model.P));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex mt2 mh1 pt1 bb b--black-20')
			]),
		_List_fromArray(
			[
				A3(tab, 'Details', '', 0),
				A3(tab, 'Items', itemCount, 1),
				A3(tab, 'Packs', packCount, 2)
			]));
};
var $author$project$Page$SetEdit$viewMain = F2(
	function (model, _v0) {
		var screenSize = $author$project$Type$Session$screenSize(model.db);
		var emptyBtn = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var breadcrumbTitlesUrls = _List_fromArray(
			[
				_Utils_Tuple2('Sets', '/sets')
			]);
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif tl')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(screenSize),
							$author$project$Page$viewBreadCrumb(breadcrumbTitlesUrls),
							A3($author$project$Page$viewPageTitle, model.b$, screenSize, emptyBtn),
							$author$project$Page$SetEdit$viewTabs(model),
							$author$project$Page$SetEdit$viewSection(model),
							$author$project$Page$SetEdit$viewModalOrNot(model)
						]))
				]));
	});
var $author$project$Page$SetEdit$viewBody = function (model) {
	var screenSize = $author$project$Type$Session$screenSize(model.db);
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$SetEdit$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$SetEdit$viewMain, model, user),
				$author$project$Page$viewFooter(screenSize)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(screenSize)
			]);
	}
};
var $author$project$Page$SetEdit$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.b$),
		$author$project$Page$SetEdit$viewBody(model));
};
var $author$project$Page$SetImport$PageMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$SetImport$SelectCsvFile = {$: 0};
var $author$project$Page$SetImport$viewEmpty = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('w-100 h-100 b--black-20 br3 flex flex-column items-center justify-center pointer b--dashed bw2'),
			A2($elm$html$Html$Attributes$style, 'min-height', '300px'),
			$elm$html$Html$Events$onClick($author$project$Page$SetImport$SelectCsvFile)
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '60px'),
					A2($elm$html$Html$Attributes$style, 'height', '60px')
				]),
			_List_fromArray(
				[
					$author$project$Ox$Icon$uploadFile('black')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('br2 pa2 mt2')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Select file to upload')
				]))
		]));
var $author$project$Ox$Icon$check = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('none'),
						$elm$svg$Svg$Attributes$d('M0 0h24v24H0z')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$SetImport$viewResults = function (model) {
	var resultLine = function (result) {
		if (!result.$) {
			var desc = result.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tl flex items-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '40px'),
								A2($elm$html$Html$Attributes$style, 'width', '40px')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$check('green')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f4 ml2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(desc)
							]))
					]));
		} else {
			var error = result.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tl flex items-center')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '40px'),
								A2($elm$html$Html$Attributes$style, 'width', '40px')
							]),
						_List_fromArray(
							[
								$author$project$Ox$Icon$close('red')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('f4 ml2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(error)
							]))
					]));
		}
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, resultLine, model.J));
};
var $author$project$Page$SetImport$viewError = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-100 h-100 b--black-20 br3 flex flex-column items-center justify-center pointer b--dashed bw2'),
				A2($elm$html$Html$Attributes$style, 'min-height', '300px'),
				$elm$html$Html$Events$onClick($author$project$Page$SetImport$SelectCsvFile)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f3 fw6 tl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Oops! Found a few problems.')
					])),
				$author$project$Page$SetImport$viewResults(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt2'),
						A2($elm$html$Html$Attributes$style, 'width', '60px'),
						A2($elm$html$Html$Attributes$style, 'height', '60px')
					]),
				_List_fromArray(
					[
						$author$project$Ox$Icon$uploadFile('black')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('br2 pa2 mt2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Select file to upload')
					]))
			]));
};
var $author$project$Page$SetImport$Upload = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$SetImport$viewVariantsTable = function (variants) {
	var listVariant = function (_var) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('collapsed w-100 mb2 ba b--black-20')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0'),
									$elm$html$Html$Attributes$colspan(3)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.b$)
								])),
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Rarity Factor')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromFloat(_var.aV))
								])),
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Variant Template')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 bb b--black-20')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.b4.bn)
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br b--black-20')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Image Url')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1'),
									$elm$html$Html$Attributes$colspan(7)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.bo)
								]))
						]))
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f6 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Variants')
					])),
			A2($elm$core$List$map, listVariant, variants)));
};
var $author$project$Page$SetImport$viewItemsTable = function (items) {
	var tableOfItem = function (item) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('collapsed w-100 mb2 ba b--black-20')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Title')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(item.b$)
								])),
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Ratio')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromFloat(item.I))
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$colspan(4)
								]),
							_List_fromArray(
								[
									$author$project$Page$SetImport$viewVariantsTable(item.b5)
								]))
						]))
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Items')
					])),
			A2($elm$core$List$map, tableOfItem, items)));
};
var $author$project$Page$SetImport$viewSetTable = function (set) {
	var tags = A2($elm$core$String$join, ', ', set.ag);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Set')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('collapsed  w-100')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Title')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20'),
										$elm$html$Html$Attributes$colspan(7)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(set.b$)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Description')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20'),
										$elm$html$Html$Attributes$colspan(7)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(set.cl)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Tags')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20'),
										$elm$html$Html$Attributes$colspan(7)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(tags)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Image Url')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20'),
										$elm$html$Html$Attributes$colspan(7)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(set.bo)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Item Count')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(set.cE))
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Batch Size')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(set.aC))
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Item Template')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(set.cI))
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Max Batches')
									])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('pa1 ba b--black-20')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(set.ap))
									]))
							]))
					]))
			]));
};
var $author$project$Page$SetImport$viewVariantSummaryTable = function (variantSummaries) {
	var listVariant = function (_var) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('collapsed w-100 mb2 ba b--black-20')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Title')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0'),
									$elm$html$Html$Attributes$colspan(3)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.b$)
								])),
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Rarity Factor')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromFloat(_var.aV))
								])),
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br bb b--black-20 ma0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Variant Template')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 bb b--black-20')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.b4)
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1 br b--black-20')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Image Url')
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pa1'),
									$elm$html$Html$Attributes$colspan(7)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(_var.bo)
								]))
						]))
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt3')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Variant Summaries')
					])),
			A2($elm$core$List$map, listVariant, variantSummaries)));
};
var $author$project$Page$SetImport$viewParsed = F3(
	function (model, set, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ml2 tl')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(' flex items-center justify-between')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f4 fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Review before upload')
								])),
							A2(
							$author$project$Ox$Button$outlined,
							'Upload Now',
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$SetImport$Upload, set, items))
								]))
						])),
					$author$project$Page$SetImport$viewSetTable(set),
					$author$project$Page$SetImport$viewVariantSummaryTable(set.dp),
					$author$project$Page$SetImport$viewItemsTable(items)
				]));
	});
var $author$project$Page$SetImport$viewSection = function (model) {
	var _v0 = model.av;
	switch (_v0.$) {
		case 0:
			return A2(
				$elm$html$Html$section,
				_List_Nil,
				_List_fromArray(
					[$author$project$Page$SetImport$viewEmpty]));
		case 1:
			var set = _v0.a;
			var items = _v0.b;
			return A3($author$project$Page$SetImport$viewParsed, model, set, items);
		default:
			return $author$project$Page$SetImport$viewError(model);
	}
};
var $author$project$Page$SetImport$viewMain = F2(
	function (model, user) {
		var empty = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							A3($author$project$Page$viewPageTitle, model.b$, model.bP, empty),
							$author$project$Page$SetImport$viewSection(model)
						]))
				]));
	});
var $author$project$Page$SetImport$viewBody = function (model) {
	var screenSize = $author$project$Type$Session$screenSize(model.db);
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$SetImport$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$SetImport$viewMain, model, user),
				$author$project$Page$viewFooter(screenSize)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(screenSize)
			]);
	}
};
var $author$project$Page$SetImport$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.b$),
		$author$project$Page$SetImport$viewBody(model));
};
var $author$project$Page$SetList$PageMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ox$Icon$upload = function (color) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('100%'),
				$elm$svg$Svg$Attributes$height('100%'),
				$elm$svg$Svg$Attributes$viewBox('0 0 24 24'),
				$elm$svg$Svg$Attributes$style('fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color),
						$elm$svg$Svg$Attributes$d('M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z')
					]),
				_List_Nil)
			]));
};
var $author$project$Page$SetList$EditSet = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$CSet$intToEditionTypeName = function (num) {
	switch (num) {
		case 0:
			return 'Standard';
		case 1:
			return 'Limited';
		default:
			return 'Error';
	}
};
var $author$project$Page$SetList$DeleteSet = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$SetList$viewAction = F2(
	function (_v0, set) {
		return (!set.aZ) ? A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Ox$Button$outlined,
					'Delete',
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetList$DeleteSet(set.bn))
						]))
				])) : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Ox$Button$outlined,
					'Edit',
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetList$EditSet(set.bn))
						]))
				]));
	});
var $author$project$Page$SetList$viewDate = F2(
	function (model, set) {
		if (!set.aI) {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('')
					]));
		} else {
			var weekday = A2($author$project$Type$DateTime$intToWeekday, model.bZ, set.aI);
			var time = A2($author$project$Type$DateTime$intToTime, model.bZ, set.aI);
			var date = A2($author$project$Type$DateTime$intToDate, model.bZ, set.aI);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex justify-around')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(weekday)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ph1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(date)
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(time)
							]))
					]));
		}
	});
var $author$project$Page$SetList$viewSet = F3(
	function (model, _v0, set) {
		var thumbnail = (set.bo === '') ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('b--black-20 ba'),
					A2($elm$html$Html$Attributes$style, 'height', '80px'),
					A2($elm$html$Html$Attributes$style, 'width', '80px')
				]),
			_List_Nil) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('br3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(set.bo),
							$elm$html$Html$Attributes$class('br3 ba b--black-10'),
							A2($elm$html$Html$Attributes$style, 'height', '80px'),
							A2($elm$html$Html$Attributes$style, 'width', '80px')
						]),
					_List_Nil)
				]));
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pointer')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetList$EditSet(set.bn)),
							$elm$html$Html$Attributes$class('pointer bb b--black-20 pv2')
						]),
					_List_fromArray(
						[thumbnail])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Page$SetList$EditSet(set.bn)),
							$elm$html$Html$Attributes$class('pointer bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(set.b$)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Type$CSet$intToEditionTypeName(set.aK))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(set.cE))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Type$CSet$intToItemTemplateName(set.cI))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(set.aZ))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$SetList$viewDate, model, set)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bb b--black-20 ph2 pv2')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$SetList$viewAction, model, set)
						]))
				]));
	});
var $author$project$Page$SetList$viewSection = function (model) {
	var tableHeaders = A2(
		$elm$html$Html$tr,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preview')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Title')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Edition Type')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Count')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Type')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Status')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Published')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bb b--black-20  ph2 pv1')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Action')
					]))
			]));
	var sets = $elm$core$Dict$values(model.dc);
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ph1 tl mt3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('w-100 collapse')
					]),
				A2(
					$elm$core$List$cons,
					tableHeaders,
					A2(
						$elm$core$List$indexedMap,
						$author$project$Page$SetList$viewSet(model),
						sets)))
			]));
};
var $author$project$Page$SetList$viewMain = F2(
	function (model, _v0) {
		var addBtn = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mr3 f6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ml1 link dib flex items-center'),
							A2($elm$html$Html$Attributes$style, 'outline', 'none'),
							$elm$html$Html$Attributes$href('/set/import')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Ox$Button$outlinedIcon,
							'Import',
							$author$project$Ox$Icon$upload('black'),
							_List_Nil)
						]))
				]));
		return A2(
			$elm$html$Html$main_,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avenir sans-serif')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(''),
							A2($elm$html$Html$Attributes$style, 'max-width', '1024px'),
							A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewShim(model.bP),
							A3($author$project$Page$viewPageTitle, model.b$, model.bP, addBtn),
							$author$project$Page$SetList$viewSection(model)
						]))
				]));
	});
var $author$project$Page$SetList$viewMobileHeader = function (model) {
	var emptyMenu = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	return A4(
		$author$project$Page$viewMobileHeader,
		model.b$,
		model.bP,
		0,
		_Utils_Tuple2(emptyMenu, emptyMenu));
};
var $author$project$Page$SetList$viewBody = function (model) {
	var _v0 = model.db;
	if (_v0.$ === 3) {
		var user = _v0.b;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Page$SetList$PageMsg,
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$viewHeader, model.c4, model.db)),
				A3($elm$html$Html$Lazy$lazy2, $author$project$Page$SetList$viewMain, model, user),
				$author$project$Page$viewFooter(model.bP),
				$author$project$Page$SetList$viewMobileHeader(model)
			]);
	} else {
		return _List_fromArray(
			[
				$author$project$Page$viewFooter(model.bP)
			]);
	}
};
var $author$project$Page$SetList$view = function (model) {
	return _Utils_Tuple2(
		$author$project$Page$pageTitle(model.b$),
		$author$project$Page$SetList$viewBody(model));
};
var $author$project$Page$viewError = function (code) {
	var message = function () {
		switch (code) {
			case 400:
				return 'Bad Request';
			case 401:
				return 'Unauthorized';
			case 402:
				return 'Payment Required';
			case 403:
				return 'Forbidden';
			case 404:
				return 'Not Found';
			default:
				return 'Oops! Something went wrong!';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pt5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f1 fw6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(code))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]))
			]));
};
var $author$project$Type$Color$maskBgColor = 'rgba(0,0,0,0.8)';
var $author$project$Page$viewSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('fixed w-100 tc'),
			A2($elm$html$Html$Attributes$style, 'color', $author$project$Type$Color$hiliteBlue),
			A2($elm$html$Html$Attributes$style, 'top', '50%'),
			A2($elm$html$Html$Attributes$style, 'transform', 'translateY(-50%)')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fas fa-spinner fa-10x fa-spin')
				]),
			_List_Nil)
		]));
var $author$project$Page$viewLoading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('absolute w-100 h-100'),
			A2($elm$html$Html$Attributes$style, 'top', '0'),
			A2($elm$html$Html$Attributes$style, 'background-color', $author$project$Type$Color$maskBgColor)
		]),
	_List_fromArray(
		[$author$project$Page$viewSpinner]));
var $author$project$View$view = function (model) {
	var viewPage = F3(
		function (viewModel, pageView, toMsg) {
			var alertViews = $author$project$Ox$Alert$view(model.cb);
			var newAlertViews = A2(
				$elm$core$List$map,
				$elm$html$Html$map($author$project$Msg$AlertMsg),
				_List_fromArray(
					[alertViews]));
			var _v1 = pageView(viewModel);
			var title = _v1.a;
			var body = _v1.b;
			var newBody = A2(
				$elm$core$List$map,
				$elm$html$Html$map(toMsg),
				body);
			return {
				aD: _Utils_ap(newBody, newAlertViews),
				b$: title
			};
		});
	var _v0 = _Utils_Tuple2(model.db, model.c3);
	_v0$0:
	while (true) {
		switch (_v0.b.$) {
			case 1:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var getStarted = _v0.b.a;
					return A3(viewPage, getStarted, $author$project$Page$GetStarted$view, $author$project$Msg$GetStartedMsg);
				}
			case 0:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Home$view, $author$project$Msg$HomeMsg);
				}
			case 2:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Profile$view, $author$project$Msg$ProfileMsg);
				}
			case 3:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$Announcement$view, $author$project$Msg$AnnouncementMsg);
				}
			case 4:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$SetList$view, $author$project$Msg$SetListMsg);
				}
			case 5:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$SetEdit$view, $author$project$Msg$SetEditMsg);
				}
			case 6:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$ItemEdit$view, $author$project$Msg$ItemEditMsg);
				}
			case 7:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$PackEdit$view, $author$project$Msg$PackEditMsg);
				}
			case 8:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A3(viewPage, subModel, $author$project$Page$SetImport$view, $author$project$Msg$SetImportMsg);
				}
			default:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					return {
						aD: _List_fromArray(
							[
								$author$project$Page$viewError(404)
							]),
						b$: 'Not Found'
					};
				}
		}
	}
	return {
		aD: _List_fromArray(
			[$author$project$Page$viewLoading]),
		b$: $author$project$Settings$appName
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{cy: $author$project$Init$init, c_: $author$project$Msg$UrlChanged, c$: $author$project$Msg$LinkClicked, dj: $author$project$Subscriptions$subscriptions, dm: $author$project$Update$update, dq: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));