import { rejects } from "assert";

const algoliasearch = require('algoliasearch');

const client = algoliasearch(
    '1PQKUWZ3XN', 
    '854183306c352c12a2a149798a3256a2'
    );

const profileIndex = client.initIndex('dev2_PROFILES');
const userIndex = client.initIndex('dev_USERS');
export default function service(app) {

    // app.ports.searchUsername.subscribe(queryString => {
    //     // var queryString = data.username

    //     search(queryString, profileIndex);
        
    // });

    function search(queryString, index) {
        // with params
        index.search(queryString, {
            // attributesToRetrieve: ['firstname', 'lastname'],
            hitsPerPage: 20,
            }).then(({ hits }) => {
            console.log(hits);
            // return hits;
            })
    }


}

export function facetSearch() {

    profileIndex.searchForFacetValues('appId', 'com.tibles.blank').then(({ facetHits }) => {
        console.log("facetSearch", facetHits);
    });
}

export function search(queryString) {
    const filters = 'username:'+queryString;

    userIndex.search(queryString, {
        attributesToRetrieve: ['username'],
        filters: filters,
        hitsPerPage: 20,
        }).then(({ hits }) => {
        console.log("search hits", filters, hits);
        // return hits;
        })
}