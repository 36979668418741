import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './Js/serviceWorker';
import firebaseService from './Js/firebaseService';
import * as algoliaService from './Js/algoliaService';
import localService from './Js/localService';

var storageKey = "ccstore";
var storage = localStorage.getItem(storageKey);
var appId = "com.tibles.drseuss.dev";

var randomArray = new Uint32Array(1);
window.crypto.getRandomValues(randomArray);

let flags =
  { seed : randomArray[0]
  , appId : appId
  , storage : storage
}

// console.log(flags);

var app =
  Elm.Main.init({
    node: document.getElementById('root'),
    flags: flags
});

localService(app, storageKey);
firebaseService(app, appId);
// algoliaService.search("woody-gm");
serviceWorker.register();
