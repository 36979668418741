import { rejects } from "assert";
import { on } from "process";

// import { read } from "fs";

var firebase = require("firebase/app");

// Add additional services that you want to use
require("firebase/firebase-auth");
require("firebase/firebase-database");
require("firebase/firebase-firestore");
require("firebase/firebase-messaging");
require("firebase/firebase-storage");
require("firebase/functions");

// core
const coreFirebaseConfig = {
    apiKey: "AIzaSyAdY57utxCBYgZGQZwJZLn2URFGB3FHSoA",
    authDomain: "tibles-core-dev.firebaseapp.com",
    projectId: "tibles-core-dev",
    storageBucket: "tibles-core-dev.appspot.com",
    messagingSenderId: "109013846370",
    appId: "1:109013846370:web:649d82c6bd32728afb043b",
    measurementId: "G-W3V0RKHXSH"
  };


// blank
const blankFirebaseConfig = {
    apiKey: "AIzaSyApAtaXTgccb9Ryil9K-c-catJkDU4cVK8",
    authDomain: "blank-b168a.firebaseapp.com",
    projectId: "blank-b168a",
    storageBucket: "blank-b168a.appspot.com",
    messagingSenderId: "467402710266",
    appId: "1:467402710266:web:11b696c53041db95313d43",
    measurementId: "G-F4T4V9FMS4"
  };


// Dr Seuss Dev
const drSeussFirebaseConfig = {
    apiKey: "AIzaSyAughVjMjcyP00B7OtnKsqSiL7S9Ol8dz0",
    authDomain: "tibles-dr-seuss.firebaseapp.com",
    projectId: "tibles-dr-seuss",
    storageBucket: "tibles-dr-seuss.appspot.com",
    messagingSenderId: "1077244414403",
    appId: "1:1077244414403:web:e71093cc7eb1bb32245576",
    measurementId: "G-94MGBP1QPC"
  };


// Valiant Dev
const valiatnDevFirebaseConfig = {
    apiKey: "AIzaSyAQLW37IoUSqOzFzXPdh7K_4DkaejYwp14",
    authDomain: "tibles-valiant-dev.firebaseapp.com",
    projectId: "tibles-valiant-dev",
    storageBucket: "tibles-valiant-dev.appspot.com",
    messagingSenderId: "777822611676",
    appId: "1:777822611676:web:3e28cec0fbb3fa958c2c19",
    measurementId: "G-T13C8G2G0G"
  };

export default function service(app, appId) {

    // Initialize Firebase
    if (appId ==  "com.tibles.drseuss.dev") {
        firebase.initializeApp(drSeussFirebaseConfig);
    } else if (appId ==  "com.tibles.valiant.dev") {
        firebase.initializeApp(valiatnDevFirebaseConfig);
    } else if (appId ==  "com.tibles.blank") {
        firebase.initializeApp(blankFirebaseConfig);
    } else {
        firebase.initializeApp(blankFirebaseConfig);
    }
    
    var coreApp = firebase.initializeApp(coreFirebaseConfig, "core");

    var db = firebase.firestore();
    var functions = firebase.functions();
    var storage = firebase.storage();
    var storageRef = storage.ref();


    function redisSet(){
        let redisSet = functions.httpsCallable('redisSet');

        redisSet({key:"owner", value:"Ernesto"})
        .then( data =>{
            console.log("redis set data", data)
        })
        .catch( (error) => {
            console.log("Error:", "redisSet", error)
        })
    }

    function setUserCustomClaims(userId, claims){
        let setRole = coreApp.functions().httpsCallable('setUserCustomClaims');
        let data = { uid : userId, claims : claims };

        setRole(data).then( result => {
            console.log("setUserCustomClaims result", result);
        })
        .catch( (error) => {
            console.log("Error", "setUserCustomClaims", error)
        })
    }

    function setUserAsAdmin(userId){
        let setRole = coreApp.functions().httpsCallable('setUserAsAdmin');
        let data = { uid : userId, role : "admin" };

        setRole(data).then( result => {
            console.log("setUserAsAdmin result", result);
        })
        .catch( (error) => {
            console.log("Error", "setUserAsAdmin", error)
        })
    }

    

    // setUserCustomClaims("cx2CYEVeI3hB2XCP6YGA5QIhSgl2",  {admin: true}); // woody
    // setUserCustomClaims("xH7rBj9AR5QTurtsS5rRrJGW93p1", {admin: true}); // ern

    // AUTH
    coreApp.auth().onAuthStateChanged( (user) => {
        if (user) {
            // User is signed in.
            console.log("Core signed in")

            let customAppToken = firebase.functions().httpsCallable('customAppToken');

            return coreApp.auth().currentUser.getIdToken()
            .then((idToken) => {
                // console.log('core token', idToken)
                return customAppToken({ token: idToken, appId : appId})
            })
            .then( (result) =>{
                return firebase.auth().signInWithCustomToken(result.data.token)
            })
            .catch(error => {
                app.ports.onAuthStateChanged.send({})
                console.log(error); 
            });

            
        } else {
            console.log("Core not signed in")
        }
    });

    firebase.auth().onAuthStateChanged( (user) => {
        if (user) {
            // User is signed in.
            console.log("App signed in")
            
            let userData = {uid: user.uid};

            return firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                // console.log('app idTokenResult',idTokenResult)
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.admin) {
                    userData.isAdmin = true
                    
                }

                if (!!idTokenResult.claims.editor) {
                    userData.isEditor = true
                } 

                if (!!idTokenResult.claims.moderator) {
                    userData.isModerator = true
                }
                if (!!idTokenResult.claims.email) {
                    userData.email = idTokenResult.claims.email
                }

                return userData;
                // return firebase.auth().currentUser.getIdToken()
            }).then( (userData) =>{
                app.ports.onAuthStateChanged.send(userData);
                // console.log(user)
                onProfileUpdate(userData.uid);
                // onAccountUpdate(userData.uid); -- not needed in CMS
                
                
            })
            .catch(function(error) {
                app.ports.onAuthStateChanged.send({})
                console.log(error);
            });

            
        } else {
            app.ports.onAuthStateChanged.send({disconnected : true});
            console.log("Not signed in")
        }
    });





// USER



    function onProfileUpdate(uid){
        let profileRef = db.collection('profiles').doc(uid);
        let now = firebase.firestore.Timestamp.now();


        profileRef.onSnapshot(function(change){
            
            if (change.exists) {
                let profile = change.data();
                profile.id = change.id;
                if (profile['dateCreated']) {
                    profile['dateCreated'] = Math.floor(profile.dateCreated.toMillis());
                }
                if (profile['dateLastLogIn']) {
                    profile['dateLastLogIn'] = Math.floor(profile.dateLastLogIn.toMillis());
                }
                
                console.log("Profile update");

                app.ports.onProfileChanged.send(profile);
            } else {
                console.log("No profile document! Let's create one.");
                profileRef.set({dateLastLogin : now, dateCreated : now})
            }
        })
    };

    function onAccountUpdate(uid){
        let accountRef = db.collection('accounts').doc(uid);
        let now = firebase.firestore.Timestamp.now();
        
        accountRef.onSnapshot(function(change){
            
            if (change.exists) {
                console.log('account exists')
                let account = change.data();
                account.id = change.id;
                if (account['dateCreated']) {
                    account['dateCreated'] = Math.floor(account.dateCreated.toMillis());
                }
                
                console.log("Account update");

                app.ports.onAccountChanged.send(account);
            } else {
                console.log("No account document! Let's create one.");
                accountRef.set({credits: 0, dateCreated : now})
            }
        })
    };
    
    var unsubSetsUpdate;
    var unsubItemsUpdate;
    var unsubAnnouncementUpdate;
    var unsubPacksUpdate;
    var unsubVariantsUpdate;

    function unsubscribeToListeners() {
        if (unsubSetsUpdate) { unsubSetsUpdate() }
        if (unsubItemsUpdate) { unsubItemsUpdate() }
        if (unsubAnnouncementUpdate) { unsubAnnouncementUpdate() }
        if (unsubVariantsUpdate) { unsubVariantsUpdate() }
        if (unsubPacksUpdate) { unsubPacksUpdate() }
    };

    
    app.ports.initAppData.subscribe( appId => {
        // unsubscribeToListeners();
        unsubSetsUpdate = onSetsUpdate();
        unsubItemsUpdate = onItemsUpdate();
        unsubVariantsUpdate = onVariantsUpdate();
        unsubPacksUpdate = onPacksUpdate();
        unsubAnnouncementUpdate = onAnnouncementUpdate();
        console.log("Signed with proper credentials, listening to data");

    });


    app.ports.fetchSignInMethodsForEmail.subscribe(function(email){
        coreApp.auth()
            .fetchSignInMethodsForEmail(email)
            .then(function(providers){
                app.ports.gotSignInMethods.send({methods : providers});
                // console.log(providers)
            })
    });



    app.ports.signInWithEmailAndPassword.subscribe(function(data){
        var email = data.email
        var password = data.password

        coreApp.auth()
        .signInWithEmailAndPassword(email, password)
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            var now = firebase.firestore.Timestamp.now().toMillis();
            if (errorCode === 'auth/wrong-password') {
                app.ports.onGotAlert.send({ message : errorMessage, kind: 'error', inputField: "password", dateCreated: now});
            } else {
                app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "", dateCreated: now});
            }
            console.log(error);
        });
    });

    app.ports.sendPasswordResetEmail.subscribe(function(email){
        let now = firebase.firestore.Timestamp.now().toMillis();

        coreApp.auth().sendPasswordResetEmail(
            email)
            .then(function() {
                app.ports.onGotAlert.send({message :"Password reset email sent", kind: 'info', dateCreated: now});
              // Password reset email sent.
            })
            .catch(function(error) {
                app.ports.onGotAlert.send({message : error.message, kind: 'error', dateCreated: now});
                console.log(error);
            });
    });
   
    app.ports.createUserWithEmailAndPassword.subscribe(function(data){
        var email = data.email
        var password = data.password
        
        coreApp.auth().createUserWithEmailAndPassword(email, password)
            .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        var now = firebase.firestore.Timestamp.now().toMillis();
        
        if (errorCode === 'auth/weak-password') {
            app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "password", dateCreated: now});
        } else {
            app.ports.onGotAlert.send({ message :errorMessage, kind: 'error', inputField: "", dateCreated: now});
        }
        console.log(error);
        });
    });


    app.ports.signOut.subscribe(function(email) {
        coreApp.auth().signOut().then(function() {
            // localStorage.removeItem(storageKey);
            console.log('Successfully logout of core!')
        })
        firebase.auth().signOut().then(function() {
            console.log('Successfully logout!')
        })
    });
    
    // IMAGES
    // app.ports.getImagesByTag.subscribe( data => {

    //     console.log("getImagesByTag", data);

    //     db.collection("images")
    //         .where("tags", "array-contains", data.tag)
    //         .get()
    //         .then( querySnapshot => {
    //             querySnapshot.forEach( doc => {
    //                 console.log (doc.id, " => ", doc.data());
    //                 let image = doc.data();
    //                 image.id = doc.id;
    //                 if (image['dateCreated']) {
    //                     image['dateCreated'] = Math.floor(image['dateCreated'].toMillis())
    //                 }
    //                 app.ports.onGotImage.send(image)
    //             })
    //         })
    //         .catch(function(error) {
    //             console.log("Error getting documents: ", error);
    //         });
    // });

    app.ports.uploadImage.subscribe( (data) => {
        var storageRef = firebase.storage().ref();

        var imageRef = storageRef.child(data.filePath);

        var metadata = {
            customMetadata: {
              'name': data.fileName
            }
          };


        var uploadTask = imageRef.putString(data.dataUrl, 'data_url', metadata);


        uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            
            app.ports.onFileUpload.send({status : "uploading", imageUrl : "", progress: progress});

            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
            }, function(error) {
            // Handle unsuccessful uploads
                app.ports.onFileUpload.send({status :"error", imageUrl : "", progress: 0});
            }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL()
                    .then(function(downloadURL) {
                        app.ports.onFileUpload.send({status : "success", imageUrl : downloadURL, progress: 100});
                        console.log('File available at', downloadURL);
                        // var image = { 
                        //     imageUrl : downloadURL, 
                        //     fileName : data.fileName,
                        //     tags : data.tags,
                        //     appId : data.appId,
                        //     dateCreated : firebase.firestore.Timestamp.now()
                        // };
                        // db.collection('images').add(image)
                        // .then(function(docRef) {
                        //     console.log("Document written with ID: ", docRef.id);
                        //     image.id = docRef.id;
                        //     image.dateCreated = Math.floor(image['dateCreated'].toMillis())
                        //     image.new = true;
                        //     app.ports.onGotImage.send(image);
                            
                        // })
                        // .catch(function(error) {
                        //     console.error("Error adding document: ", error);
                        // });
                        
            });
        });
        
    });


    // ANNOUNCEMENTS
    
    function onAnnouncementUpdate(){
        console.log("onAnnouncementUpdate");
  
        return db.collection('announcements')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    // console.log(change)
                    let doc = change.doc;
                    if (change.type == "removed") {
                        console.log("Remove announcement", doc.id);
                        app.ports.onAnnouncementRemoved.send(doc.id);
                    } else {
                        doc = change.doc;
                        let announcement = doc.data();
                        announcement['id'] = doc.id;
                        if (announcement['datePublished']) {
                            announcement['datePublished'] = Math.floor(announcement['datePublished'].toMillis());
                        }
                        // console.log(announcement);
                        app.ports.onAnnouncementUpdated.send(announcement);
                    }
                    
                })
            })
    };


    app.ports.updateSetDetails.subscribe( (data) => {
        
        let docId = data.id;
        let itemIds = data.itemIds;

        delete data.id;
        delete data.itemIds;

        let datePublished = firebase.firestore.Timestamp.fromMillis(data.datePublished);
        let status = data.status;

        data.datePublished = datePublished;
        
        let setRef = db.collection("sets").doc(docId);

        var batch = db.batch();
        batch.update(setRef, data)
        itemIds.forEach ( id => {
            let itemRef = setRef.collection("items").doc(id);
            batch.update( itemRef, { datePublished : datePublished, status : status })
        })

        batch.commit().then( () => {
            var now = firebase.firestore.Timestamp.now().toMillis();
            app.ports.onGotAlert.send({ message : "Set saved!", kind: 'info', inputField: "", dateCreated : now});
        }).catch(function(err) {
            app.ports.onGotAlert.send({ message : "Could not save set!", kind: 'error', inputField: "", dateCreated : now});
            console.error(err);
        });

    })

    app.ports.updateItem.subscribe( data => {
        
        let docId = data.id;
        let setId = data.setId;
        let docPath = "sets/" + setId + "/items"

        let variants = data.variants;

        delete data.variants
        delete data.id;
        delete data.setId;

        let itemRef = db.collection(docPath).doc(docId);

        itemRef.update(data)
        .then( () => {
            var now = firebase.firestore.Timestamp.now().toMillis();
            app.ports.onGotAlert.send({ message : "Item saved!", kind: 'info', inputField: "", dateCreated : now});
        }).then( () => {
            console.log("variant", variants)
            var batch = db.batch();
            
            variants.forEach ( variant => {
                let id = variant.id;
                console.log("variantId", id)
                delete variant.id;

                let varRef = itemRef.collection("variants").doc(id);
                
                batch.update( varRef, { itemTitle : data.title })
            })
            return batch.commit()

        }).catch( error => {
            var now = firebase.firestore.Timestamp.now().toMillis();
            app.ports.onGotAlert.send({ message : error, kind: 'error', inputField: "", dateCreated : now});
            console.error("Error updating item: ", error);
        });
    });



    app.ports.updateAnnouncement.subscribe( (data) => {

        let docId = data.id;
        delete data.id
        data.datePublished = firebase.firestore.Timestamp.fromMillis(data.datePublished);
        db.collection("announcements")
            .doc(docId)
            .update(data)
            .then( () => {
                console.log("Announcement updated: ", docId);
            })
            .catch(function(error){
                console.error("Error updating document: ", error);
            });
        
    });

    app.ports.createAnnouncement.subscribe( (data) => {

        delete data.id;
        data.datePublished = firebase.firestore.Timestamp.fromMillis(data.datePublished);
        db.collection("announcements")
            .add(data)
            .then(function(){
                console.log("Announcement created: ", data.title );
            })
            .catch(function(error){
                console.error("Error creating document: ", error);
            });
        
    });

    app.ports.removeAnnouncement.subscribe( (id) => {

        db.collection("announcements")
            .doc(id)
            .delete()
            .then(function(){
                console.log("Announcement deleted: ", id );
            })
            .catch(function(error){
                console.error("Error deleting document: ", error);
            });
    });

    

    app.ports.createSetAndItems.subscribe (data => {
        console.log( 'create Set and Items');
        // console.log( 'set', data.set);
        // console.log( 'items', data.items);
        var now = firebase.firestore.Timestamp.now().toMillis();

        var set = data.set;

        var batch = db.batch();
        var setRef = db.collection("sets").doc();

        console.log("setRef", setRef.id)

        batch.set(setRef, set)

        data.items.forEach( item => {
            let itemRef = setRef.collection("items").doc();
            let variants = item.variants;
            delete item.variants;

            batch.set(itemRef, item)

            variants.forEach( variant => {
                let variantRef = itemRef.collection("variants").doc();
                let variantTemplateRef = db.doc(variant.variantTemplateRef.path)
                variant.variantTemplateRef = variantTemplateRef;
                batch.set(variantRef, variant);

            })
        })


        batch.commit()
        .then( () => {
            console.log("Committed", set.title, setRef.id, );
            app.ports.onGotAlert.send({ message : "New set and items uploaded.", kind: 'success', inputField: "", dateCreated : now});
        }).catch(function(err) {
            app.ports.onGotAlert.send({ message : err, kind: 'error', inputField: "", dateCreated : now});
            // This will be an "population is too big" error.
            console.error(err);
        });
        
    });

    async function printVariants(variants) {

        for (const variant of variants) {
            let itemVarRef = db.doc(variant.variantPath);
            variant.variantPath = itemVarRef;
            
            let createPrints = firebase.functions().httpsCallable('createPrints');
            await createPrints(variant).then( result => {
                // console.log("createPrints result", result);
                return variant.variantPath.update( {status : 2})

            }).catch( (error) => {
                console.log("Error", "create print fail", error)
            })

        }

    }

    app.ports.createPrints.subscribe( data => {
        
        let appId = data.appId;
        let variants = data.variants;

        // printVariants(variants);

        
    })

    // PRODUCTS
    function cleanUpPackForSend( doc, setId){
        let pack = doc.data();
        pack['id'] = doc.id;
        pack['setId'] = setId;
        if (pack['dateCreated']) {
            pack['dateCreated'] = Math.floor(pack['dateCreated'].toMillis())
        }
        return pack;
    };

    function cleanUpPackForUpload( db, pack, setId){
        delete pack.id;
        delete pack.setId;
        if (pack.imageUrl === ""){
            delete pack.imageUrl;
        }

        let newItemRefs = []
        pack.itemRefs.forEach ( item => {
            let itemRef = db.doc(item.path)
            newItemRefs.push(itemRef)
        })
        pack.itemRefs = newItemRefs;

        let newSetRefs = []
        pack.setRefs.forEach ( set => {
            let setRef = db.doc(set.path)
            newSetRefs.push(setRef)
        })
        pack.setRefs = newSetRefs;
        if (pack.dateCreated == 0) {
            pack.dateCreated = firebase.firestore.Timestamp.now()
        } else {
            pack.dateCreated = firebase.firestore.Timestamp.fromMillis(pack.dateCreated);
        }

        return pack;
    };

    app.ports.createPack.subscribe( pack => {
        console.log('Create pack', pack);
        
        let newPack = cleanUpPackForUpload(db, pack, pack.setId);
        
        db.collection("packs")
            .add(newPack)
            .then(docRef => {
                app.ports.onPackCreated.send(docRef.id);
                console.log("Pack added!", docRef.id);
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        
    });
    
    app.ports.updateVariantInSet.subscribe( data => {
        let setId = data.setId;
        let variantSummaries = data.variantSummaries;

        let variantPath = data.variantPath;

        let otherVariantPaths = data.otherVariantPaths;

        let setData = { variantSummaries : variantSummaries };
        
        
        let setRef = db.collection("sets").doc(setId);
        let variantRef = db.doc(variantPath)

        var batch = db.batch();
        batch.update(setRef, setData)
        batch.update(variantRef, data.variant)

        let otherVariant = data.variant;
        delete otherVariant.imageUrl
        
        otherVariantPaths.forEach ( path => {
            let varRef = db.doc(path);
            batch.update( varRef, otherVariant)
        })

        var now = firebase.firestore.Timestamp.now().toMillis();
        batch.commit().then( () => {
            app.ports.onGotAlert.send({ message : "Variants saved!", kind: 'info', inputField: "", dateCreated : now});
            console.log("Variants saved")
        }).catch(function(err) {
            app.ports.onGotAlert.send({ message : "Could not save variants!", kind: 'error', inputField: "", dateCreated : now});
            console.error(err);
        });


    })

    app.ports.updateVariantOnly.subscribe( data => {

        let variantData = data.variant;
        let variantPath = data.variantPath;

        let itemRef = db.doc(variantPath)
        
        let now = firebase.firestore.Timestamp.now().toMillis();


        itemRef.update(variantData)
        .then( () => {
            app.ports.onGotAlert.send({ message : "Variant saved!", kind: 'info', inputField: "", dateCreated : now});
            console.log("Variant saved")
        }).catch(function(err) {
            app.ports.onGotAlert.send({ message : "Could not save variant!", kind: 'error', inputField: "", dateCreated : now});
            console.error(err);
        });


    })

    app.ports.repopProfilesInAlgolia.subscribe( () => {
        let sendProfileToAlgolia = functions.httpsCallable('sendProfileToAlgolia');
        let data = { ping : "pong" };

        sendProfileToAlgolia(data).then( result => {
            console.log("sendProfileToAlgolia result", result);
        })
        .catch( (error) => {
            console.log("Error", "sendProfileToAlgolia", error)
        })
    });



    // app.ports.updatePack.subscribe( (pack) => {
        
    //     let docId = pack.id;

    //     let updatedPack = cleanUpPackForUpload(db, pack, pack.setId);

    //     db.collection("packs")
    //         .doc(docId)
    //         .update(updatedPack)
    //         .then( () => {
    //             var now = firebase.firestore.Timestamp.now().toMillis();
    //             app.ports.onGotAlert.send({ message : "Pack saved", kind: 'info', inputField: "", dateCreated : now});
    //         })
    //         .catch(function(error){
    //             var now = firebase.firestore.Timestamp.now().toMillis();
    //             app.ports.onGotAlert.send({ message : "Could not save pack", kind: 'error', inputField: "", dateCreated : now});
    //             console.error("Error updating item: ", error);
    //         });
    // })


    
    

    function onPacksUpdate(){
        console.log("onPackUpdate");

        return db.collection('packs')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    let doc = change.doc;
                    let pack = doc.data();
                    pack['id'] = doc.id;
                    if (pack['dateCreated']) {
                        pack['dateCreated'] = Math.floor(pack['dateCreated'].toMillis())
                    }
                    app.ports.onGotPack.send(pack);
                })
            });
    };





    function onRoomsUpdate(appId){
        console.log("onGetRooms");

        db.collection('chatRooms')
            .get().then( (querySnapshot) => {
                querySnapshot.forEach( (doc) => {
                    let room = doc.data();
                    room['id'] = doc.id;
                    app.ports.onGotRoom.send(room);
                })
            })
    };
    
    

    function onSetsUpdate( ){
        console.log("onSetsUpdate");
        
        return db.collection('sets')
            .onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {

                    let doc = change.doc;

                    if (change.type == "removed") {
                        console.log("Removed set ", doc.id);
                        app.ports.onSetRemoved.send(doc.id);
                    } else {
                        let set = doc.data();
                        set.id = doc.id;
                        set.path = doc.ref.path;
                        if (set['datePublished']) {
                            set['datePublished'] = Math.floor(set.datePublished.toMillis());
                        }
                        console.log("set updated", set.title)
                        app.ports.onSetChanged.send(set);
                        }
                })
                
            })
    }

    function onItemsUpdate( ){
        console.log("onItemsUpdate");

        let appItems = db.collectionGroup("items")
            
        return appItems.onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    

                    let doc = change.doc;

                    if (change.type == "removed") {
                        console.log("Remove item ", doc.id);
                        app.ports.onItemRemoved.send(doc.id);
                    } else {

                    
                        let item = doc.data();
                        item.id = doc.id;
                        item.path = doc.ref.path;
                        if (item['datePublished']) {
                            item['datePublished'] = Math.floor(item.datePublished.toMillis());
                        }
                        app.ports.onItemUpdated.send(item)

                    }

                })
                
            })
    }

    function onVariantsUpdate( ){
        console.log("onVariantsUpdate");

        let appVariants = db.collectionGroup("variants")
            
        return appVariants.onSnapshot( (querySnapshot) => {
                querySnapshot.docChanges().forEach( (change) => {
                    

                    let doc = change.doc;

                    if (change.type == "removed") {
                        console.log("Remove item ", doc.id);
                        // app.ports.onItemRemoved.send(doc.id);
                    } else {

                    
                        let variant = doc.data();
                        variant.id = doc.id;
                        variant.path = doc.ref.path;
                        if (variant['datePublished']) {
                            variant['datePublished'] = Math.floor(variant.datePublished.toMillis());
                        }
                        // console.log("Variant ", variant.title, variant.path);
                        app.ports.onVariantUpdated.send(variant)
       

                    }


                   
                })
                
            })
    }


    // deletItemPrints('N7n0zTIkEQpeSFGBx1cC');

    // function deletItemPrints(itemId){
    //     var batch = db.batch();
    //     var printRefs = [];
    //     db.collection("prints")
    //         .where("itemId", '==', itemId).get()
    //         .then( querySnap => {
    //             querySnap.forEach ( print => {
    //                 // printRefs.push(print.ref)
    //                 batch.delete(print.ref)
    //             })
    //         }).then( () => {
    //             return batch.commit()
    //         }).then( () => {
    //             console.log("Delete success")
    //         }).catch(error => {
    //                 console.log("Error:", error);
    //         });
    // }



    // updateSetItems("ANsAsxYQOziIldwEAfUt");
    // function updateSetItems(setId) {
    //     var batch = db.batch();
    //     var setRef = db.collection("sets").doc(setId);

    //     setRef.get().then(doc => {
    //         if (doc.exists) {
    //             console.log("Set exists, continue");

    //             return setRef.collection("items").get()
    //                 .then( querySnap => {
    //                     querySnap.forEach ( item => {
    //                     batch.update(item.ref, {appId : "com.tibles.sandbox"})
    //                 })
    //             })

    //         } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such set!");
    //             throw new Error('No such set'); 
    //         }
    //     }).then( () =>{
    //         return batch.commit().then( () => {
    //             console.log("Committed", set.title, setRef.id, );
    //         })

    //     }).catch(function(error) {
    //         console.log("Error getting document:", error);
    //     });

    // }

 
    app.ports.batchDelete.subscribe( data => {
        console.log(data)
        var batch = db.batch();

        data.paths.forEach( path => {
            let docRef = db.doc(path)
            batch.delete(docRef)
        })

        batch.commit().then( () => {
            console.log("Batched deleted complete" );
        }).catch(function(error) {
            console.log("Error:", error);
        });

    });

}